import { Component, ViewChild, NgZone, Inject, APP_ID } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Scroll, RouterEvent } from '@angular/router';
import { NavController } from '@ionic/angular';
import { SwUpdate, VersionDetectedEvent } from "@angular/service-worker";

// error handling
import * as Sentry from '@sentry/browser';

// ionic
import { Platform, MenuController, IonRouterOutlet, ModalController } from '@ionic/angular';
import { Events } from "./helpers/events";
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Keyboard, KeyboardResizeMode } from '@awesome-cordova-plugins/keyboard/ngx';

// rxjs
import { Subject, of } from 'rxjs';
import { debounceTime, take, takeUntil } from "rxjs/operators";
import { startWith } from 'rxjs/operators';
import { interval } from 'rxjs';

// other services
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as moment from "moment";
import { MatomoTracker } from 'ngx-matomo-client';

// components
import { AttendeeDetailComponent } from "./components/attendee-detail/attendee-detail.component";
import { WizardComponent } from "./components/wizard/wizard.component";

// services
import { UserService } from "./services/user.service";
import { EventService } from "./services/event.service";
import { PlatformService } from './services/platform.service';
import { ParticipantService } from './services/participant.service';
import { OverlayService } from "./services/overlay.service";
import { AuthenticationService } from "./services/authentication.service";
import { RouterExtService } from "./services/router-ext.service";
import { StorageService } from "./services/storage.service";

// models
import { User } from "./models/user";
import { Event } from "./models/event";

// helpers
import { hexToRgb, shadeColor } from "./helpers/colors";
import { log } from './helpers/log';

// config
import { environment } from "../environments/environment";

import { getThemeConfig } from './pages/config';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';

declare var cordova: any;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {

    @ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;

    /**
     * login state
     *
     * @type {boolean}
     */
    public loggedIn: boolean = false;

    /**
     * decide if user is on public page
     *
     * @type {boolean}
     */
    public publicPage: boolean = false;

    /**
     * current user
     *
     * @type {User}
     */
    public user: User = new User;

    /**
     * info about last update on users profile
     *
     * @type {Date}
     */
    public lastUpdated: Date;

    /**
     * user language
     *
     * @type { string}
     */
    public userLang: string;

    /**
     * available events for user
     *
     * @type {Array}
     */
    public events: Event[] = [];

    /**
     * network state
     *
     * @type {boolean}
     */
    public networkStatus: boolean = true;

    /**
     * use router animation
     *
     * @type {boolean}
     */
    public animateRoute: boolean = false;

    /**
     * loading state
     *
     * @type {boolean}
     */
    public loading: boolean = false;

    /**
     * use timeslots in actual selected event
     *
     * @type {boolean}
     */
    public use_timeslots: boolean = false;

    /**
     * view event list
     *
     * @type {boolean}
     */
    public viewEventList: boolean = false;

    /**
     * subscription handler for participant
     *
     * @type {Subject<void>}
     */
    public unsubscriParticipant: Subject<void> = new Subject<void>();


    /**
     * should we show iOS install pop up for PWA?
     *
     * @type {boolean}
     */
    public showiOSInstallMessage: boolean = false;

    /**
     * global config
     *
     * @type {Object}
     */
    public env: any = environment;

    public activePage: string = "";

    // TODO!!
    public appPages = [
        {
            title: 'Home',
            url: '/home',
            icon: 'home'
        },
        {
            title: 'Attendees',
            url: '/home/attendees/list',
            icon: 'people'
        }
    ];

    private noBottomMenuPages = [
        'tabsmessagesdirect', 'tabsmessageschannel'
    ];

    public previewUrlStrings: string[] = ['previewinfomatching', 'previewinfomanage-keywords', 'previewattendeeslist', 'previewdetailevent', 'preview-attendee-detail'];
    public isPreview: boolean = false;

    public keyboardVisible: boolean = false;
    public keyboardHeight;

    /**
     * is wizard opened
     *
     * @type {boolean}
     */
    public wizardOpened: boolean = false;

    public organizerPreview: boolean = false;

    /**
     * what to show in menu
     *
     * @type {string}
     */
    public viewType: string = 'event';

    public urlParams: URLSearchParams;

    /**
     * First url used for app init
     *
     * @type {string}
     */
    public startUrl: string;

    /**
     * theme configuration, based on domain
     */
    public themeConfig = getThemeConfig();

    constructor(
        @Inject(APP_ID) private appId: string,
        private updates: SwUpdate,
        private platform: Platform,
        private storage: StorageService,
        private splashScreen: SplashScreen,
        private keyboard: Keyboard,
        private statusBar: StatusBar,
        private router: Router,
        private menu: MenuController,
        private modalController: ModalController,
        private translate: TranslateService,
        private tracker: MatomoTracker,
        public plt: PlatformService,
        public participantService: ParticipantService,
        public appEvents: Events,
        public route: ActivatedRoute,
        public overlayService: OverlayService,
        public auth: AuthenticationService,
        public userService: UserService,
        public eventService: EventService,
        public routerExtService: RouterExtService,
        public ngZone: NgZone,
        public nav: NavController
    ) {

        this.urlParams = new URLSearchParams(window.location.search);

        // if app is configured to run only in iframe and direct
        // open of app will redirect user to predefined link
        if (this.env.noIframeRediret) {
            // check if app is running in iframe
            let iframe = false;
            try {
                iframe = window.self !== window.top;
            } catch (e) {
                iframe = true;
            }
            if (!iframe) {
                window.location.href = this.env.noIframeRediret;
            }
        }

        this.startUrl = window.location.pathname + window.location.search;

        this.initializeApp();
        this.setAppEvents();
        this.subscribeParticipant();
        log('info', 'Storage driver:' + this.storage.driver());
        log('info', 'APP ID', this.appId)
    }

    /**
      * logout user, redirect to login, close menu
      *
      * @return void
      */
    logout() {
        // show login form without animation
        this.animateRoute = false;
        // reset view type for left panel
        this.viewType = 'event';
        // NOTE this was causing bug with again loading actual logouted user, but it is having a nice efect for logout...
        //this.nav.navigateRoot(['/login']);
        // close menu to have already loaded login page
        setTimeout(() => {
            this.closeMenu();
        }, 50);

        // adding delay to have nice animation with closing menu
        setTimeout(() => {
            this.animateRoute = true;
            this.appEvents.publish('loggedout');
            this.appEvents.publish('logout', 'logout');
        }, 300);
    }

    /**
      * close app menu if needed (on mobile...)
      *
      * @return void
      */
    closeMenu() {
        this.menu.close();
    }

    menuOpened() {
        this.plt.tabMenuOpened = true;
        // this.routerExtService.softNavigate();
    }

    menuClosed() {
        this.plt.tabMenuOpened = false;
        // this.routerExtService.softBack();
    }

    /**
     * select active event
     *
     * @param event
     */
    selectEvent(id: number, route?, options?, force: boolean = false) {
        if (!this.loading || force) {

            this.viewEventList = false;
            this.loading = true;
            this.menu.close();

            // check current event and change it only when it is not matching
            this.eventService.getCurrentEvent().pipe(
                take(1)
            ).subscribe(
                (event) => {
                    // for case that eventId was not provided.. keep current event
                    if (!id && event?.id) {
                        id = event.id
                    }
                    if (!event || event.id != id) {

                        if (!force) {
                            this.overlayService.showLoading();
                        }
                        // TODO: this is for stoping requests (with event_id or selected_participant) until the selected event is loaded
                        this.appEvents.publish('event:select');
                        this.plt.eventInChange = true;
                        this.plt.unsubscribeRequests();

                        // reset left menu view
                        this.viewType = 'event'

                        this.userService.setSelectedEvent(this.user.id, id)
                            .subscribe(
                                (user: User) => {

                                    // update token in case that it is refreshed
                                    if (user.token) {
                                        this.auth.updateToken(user.token);
                                    }

                                    // reset subscription
                                    this.unsubscribe();

                                    // set also proper current event
                                    this.plt.eventInChange = false;

                                    // reset notifications and wait for loading for this event
                                    this.plt.resetNotifications();
                                    this.userService.setCurrentUser(new User(user));

                                    // this.initializeApp();
                                    this.setAppEvents();

                                    setTimeout(() => {
                                        this.loading = false;
                                        this.overlayService.hideLoading();
                                    }, 500);

                                    //this.routeService.openPage('participant/dashboard/overview');
                                    if (route) {

                                        // trigger openin of specific conten from link
                                        if (!!options.queryParams) {
                                            this.trigger(options.queryParams);
                                        }
                                        // open main link, without details events, to be sure that it will be in history...
                                        this.nav.navigateRoot([route]);

                                    } else {
                                        if (this.isPreview) {
                                            this.nav.navigateRoot([this.plt.defaultLink + '/preview/info/matching'], options ? options : {});
                                        } else {
                                            this.nav.navigateRoot([this.plt.defaultLink + '/home/info/matching'], options ? options : {});
                                        }
                                    }

                                }, (error) => {
                                    this.events = this.events.filter(item => item.id !== id);
                                    this.loading = false;
                                    setTimeout(() => {
                                        this.loading = false;
                                    }, 500);
                                    this.overlayService.hideLoading();
                                    this.overlayService.showError(error.error.message);
                                    log('error', 'SelectEvent', error);
                                    //Sentry.captureException(error);
                                });
                    } else {

                        this.loading = false;
                        this.overlayService.hideLoading();

                        if (route) {

                            // trigger openin of specific conten from link
                            if (!!options.queryParams) {
                                this.trigger(options.queryParams);
                            }
                            // open main link, without details events, to be sure that it will be in history...
                            this.nav.navigateRoot([route]);

                        } else {
                            if (!force) {
                                // redirect only when not comming from deeplink
                                if (this.isPreview) {
                                    this.nav.navigateRoot([this.plt.defaultLink + '/preview/info/matching'], options);
                                } else {
                                    this.nav.navigateRoot([this.plt.defaultLink + '/home/info/matching'], options);
                                }
                            }
                        }
                    }
                });
        }
    }

    // trigger event to show specific deeplink content
    trigger(queryParams) {

        let attendeeId = queryParams.profile_id;
        if (attendeeId) {
            this.appEvents.publish('attendee:show', attendeeId);
        }

        let marketplaceId = queryParams.marketplace_id;
        let marketplacePostId = queryParams.post_id;
        if (marketplaceId && marketplacePostId) {
            this.appEvents.publish('marketplace:post:show', marketplacePostId, marketplaceId);
        }

        let votingId = queryParams.voting_id;
        if (votingId) {
            this.appEvents.publish('voting:show', votingId);
        }

        let newsfeedId = queryParams.newsfeed_id;
        if (newsfeedId) {
            this.appEvents.publish('newsfeed:show', newsfeedId);
        }
    }

    /**
     * unsubscribe all subscription
     *
     * @return void
     */
    private unsubscribe() {
        this.appEvents.unsubscribe('refresh:messages');
        this.appEvents.unsubscribe('refresh:messages:live');
        this.appEvents.unsubscribe('refresh:appointments');
        this.appEvents.unsubscribe('attendee:changed');
        this.appEvents.unsubscribe('attendee:filter');
        this.appEvents.unsubscribe('messages:refresh:list');
        this.appEvents.unsubscribe('messages:participant');
        this.appEvents.unsubscribe('messages:broadcast');
        this.appEvents.unsubscribe('messages:chatgroup');
        this.appEvents.unsubscribe('messages:chatgroup:add');
        this.appEvents.unsubscribe('messages:empty');
        this.appEvents.unsubscribe('attendee:open:detail');
    }

    /**
      * main method using platform ready
      *
      * @return void
      */
    private initializeApp() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.userLang = event.lang;

            // store language for tracking
            this.tracker.setCustomDimension(1, this.userLang);
        });

        // set default language
        this.translate.setDefaultLang(this.plt.availableLangs[0]);
        this.translate.addLangs(this.plt.availableLangs);

        this.translate.setTranslation('en', {
            MESSAGE_WARNING: 'Attention!',
            MESSAGE_LOGGEDOUT: 'Your session has expired or you are logged in with another instance. Please re-login.',
            CONNECTION_PROBLEMS_TRY_AGAIN: 'The app has currently connection problems. Please try again!',
            BUTTON_OK: 'OK'
        });

        this.translate.setTranslation('de', {
            MESSAGE_WARNING: 'Achtung!',
            MESSAGE_LOGGEDOUT: 'Ihre Sitzung ist abgelaufen oder Sie haben sich mit Ihren Zugansgdaten in einer anderen Instanz angemeldet. Bitte loggen Sie sich erneut ein.',
            CONNECTION_PROBLEMS_TRY_AGAIN: 'Es gibt Verbindungsprobleme. Bitte versuchen Sie es noch einmal!',
            BUTTON_OK: 'OK'
        });

        // reload other lang to have them prepared
        this.plt.availableLangs.forEach((lang) => {
            this.translate.reloadLang(lang);
        });

        // try to detect browser language
        let browserLang = this.translate.getBrowserLang();

        // check url params
        if (this.urlParams.get('forceLocale')) {
            browserLang = this.urlParams.get('forceLocale');
        }

        // disable user tracking
        if (this.urlParams.get('disable-tracking')) {
            localStorage.setItem('disable-tracking', '1');
        }

        // enable user tracking
        if (this.urlParams.get('enable-tracking')) {
            localStorage.removeItem('disable-tracking');
        }

        // disable user tracking
        if (this.urlParams.get('disable-staging')) {
            localStorage.removeItem('enable-staging');
        }

        // enable user tracking
        if (this.urlParams.get('enable-staging')) {
            localStorage.setItem('enable-staging', '1');
        }

        // check access to staging, otherwise show production app
        if (!this.plt.is('cordova') && environment.name == 'staging' && !localStorage.getItem('enable-staging')) {
            window.location.href = environment.prodPwa + location.pathname + location.search;
        }

        // check browser lang, if it is one of supported
        let actualLang = browserLang.match(new RegExp(this.plt.availableLangs.join("|"))) ? browserLang : this.plt.availableLangs[0]
        this.userLang = actualLang;
        this.translate.use(actualLang);

        // when everything is loaded
        this.platform.ready().then(() => {

            this.tracker.setCustomDimension(3, environment.version);
            // check tracking
            this.checkTracking();

            if (this.plt.is('android')) {
                this.registerBackButtonAction();
            }
            if (this.plt.is('cordova')) {

                this.statusBar.styleDefault();
                this.splashScreen.hide();
                this.keyboard.hideFormAccessoryBar(false);
                this.keyboard.setResizeMode(KeyboardResizeMode.Native);
                // attach InApp browser to all window openning
                window.open = cordova.InAppBrowser.open;
                // init branch only when internet connection is available
                if (this.plt.isConnected) {
                    this.plt.branchInit(true);
                    // NOTE[jg] -- added for storage init, to provide more time
                    setTimeout(() => {
                        this.setRootPage();
                    }, 250);
                } else {
                    // show offline login page
                    this.nav.navigateRoot(['/login']);
                    // run this init for root page only once
                    // this is only for app starting in offline mode
                    // NOTE[jg] - attach to some global online subscription
                    window.addEventListener('apponline', () => {
                        this.setRootPage();
                    }, { once: true });
                }

                this.tracker.setCustomDimension(2, 'mobile');

            } else {

                this.tracker.setCustomDimension(2, 'pwa');

                if (this.plt.is('ios')) {

                    let external = this.urlParams.get('external');

                    if (external) {
                        this.plt.hideBottomMenu = true;
                    }

                    // Detects if device is on iOS
                    const isIos = () => {
                        const userAgent = window.navigator.userAgent.toLowerCase();
                        return /iphone|ipad|ipod/.test(userAgent);
                    }
                    // Detects if device is in standalone mode
                    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);

                    // Checks if should display install popup notification:
                    if (this.env.showInstallPrompt && isIos() && !isInStandaloneMode() && !external && !sessionStorage.getItem('ios-install')) {
                        this.showiOSInstallMessage = true
                    }
                }

                // initial status for screen orientation for PWA 
                this.plt.checkOrientationChange();
            }

            // init app if online
            if (this.plt.isConnected) {
                // NOTE[jg] -- added for storage init, to provide more time
                setTimeout(() => {
                    this.setRootPage();
                }, 250);
            } else {
                // show offline login page
                this.nav.navigateRoot(['/login']);
                // this.loading = false;
                // run this init for root page only once after online event
                // this is only for app starting in offline mode
                // NOTE[jg] - attach to some global online subscription
                window.addEventListener('apponline', () => {
                    this.setRootPage();
                }, { once: true });
            }

            // for syncing data between browsers tabs
            const channel = new BroadcastChannel('app-data');
            channel.addEventListener('message', ($event) => {
                // ignore messages withing same tab...
                if ($event.data.event_id) {
                    if (this.appId != $event.data['broadcastSender']) {
                        log('info', 'Tab syncing', this.appId, $event.data['broadcastSender']);
                        // check if event is the same
                        this.eventService.getCurrentEvent().pipe(take(1)).subscribe((event) => {
                            // in case event change, inform other running version of app in different tabs about change
                            if (event && event.id && event.id !== $event.data.event_id) {
                                this.selectEvent($event.data.event_id, null, null, true);
                                this.overlayService.closeModals();
                            }
                        })
                    }
                }
            });

            // check message for opening detail
            const videoChannel = new BroadcastChannel('open-detail');
            videoChannel.addEventListener('message', ($event) => {
                if (this.appId == $event.data['broadcastSender']) {
                    if ($event.data.video) {
                        this.appEvents.publish('video:show', $event.data.video);
                    }
                }
            });

            // check message for opening local links
            const linkChannel = new BroadcastChannel('open-link');
            linkChannel.addEventListener('message', ($event) => {
                if (this.appId == $event.data['broadcastSender']) {
                    if ($event.data.url) {
                        this.router.navigateByUrl($event.data.url);
                    }
                }
            });
        });
    }

    /**
      * load participant data, profile, events
      *
      * @return void
      */
    private subscribeParticipant() {

        //this.loading = true;

        this.userService.getCurrentUser()
            .pipe(
                debounceTime(0)
                // takeUntil(this.unsubscriParticipant)
            ).subscribe(
                (user: User) => {
                    if (this.loggedIn && user.id) {

                        let eventChanged = this.user.selected_participant_id && this.user.selected_participant_id != user.selected_participant_id

                        log('info', 'Current user loaded', user);
                        this.user = user;

                        // try load event specific theme config in case of existing profile
                        if (this.user.selected_participant) {
                            this.themeConfig = getThemeConfig(this.user.selected_participant.event_id);
                        }

                        // close all previous user subscription
                        this.unsubscriParticipant.next();
                        this.unsubscriParticipant.complete();

                        // check last page in history TODO
                        // let history = this.pushPageService.getHistory();
                        // let lastPage = history ? history.pageName : '';

                        // redirect user to TOC form if user hasnt accepted TOC and hes registered via linkedin
                        if (user.toc_needed || user.toc_refresh) {
                            this.router.navigate(['/toc'], { queryParams: { toc_refresh: user.toc_refresh } });
                            this.loading = false;
                            return of([]);
                        }

                        // if user has no event and actual page is not feed list, go to no-event page
                        //  && lastPage !== 'FeedListPage'
                        if (!user.selected_participant) {
                            //this.nav.setRoot('NoEventPage');
                            this.overlayService.hideLoading();
                            this.router.navigate(['/no-event']);
                            return of([]);
                        }

                        // decide if we need timeslot setting for this event
                        this.use_timeslots = user.selected_participant.event.use_timeslots;

                        // use settings locale for global translations
                        user.settings.forEach(setting => {
                            if (setting.locale) {
                                if ((!this.user.selected_participant && setting.is_global)
                                    || (setting.event_id === this.user.selected_participant.event_id)) {

                                    this.translate.use(setting.locale);
                                    this.userLang = this.translate.currentLang;
                                }
                            }
                        });

                        // set current event
                        if (this.user.selected_participant) {
                            this.eventService.setCurrentEvent(new Event(this.user.selected_participant.event, this.user));
                            if (eventChanged) {
                                setTimeout(() => {
                                    // give some time to app to refresh content
                                    this.appEvents.publish('event:changed');
                                    this.routerExtService.resetHistory();
                                    log('info', 'Current event loaded', this.user.selected_participant.event);
                                }, 200);
                            }
                            // TODO check this...
                            //this.appEvents.publish('conversations:unread', this.user.id);
                        }

                        // start new subscription
                        this.unsubscriParticipant = new Subject<void>();

                        // load list of all available events for participant
                        let subscriptionEvents = this.eventService.getAvailableForUser(user.id)
                            .pipe(
                                takeUntil(this.unsubscriParticipant)
                            ).subscribe(
                                (events: Event[]) => {

                                    this.events = [];
                                    this.plt.eventWithCredentialWallet = false;

                                    events.forEach(event => {
                                        event.menu_visible = (this.user.selected_participant.event.id === event.id);
                                        this.events.push(new Event(event, this.user));

                                        if (event.enable_credential_wallet) {
                                            this.plt.eventWithCredentialWallet = true;
                                        }
                                    });

                                    this.plt.eventsCount = this.events.length;

                                    // hide loading and redirect to dashboard
                                    this.loading = false;
                                    this.overlayService.hideLoading();
                                    // if login from token, keep user on current page

                                }, (error) => {

                                    if (!error.error || !error.status) {
                                        this.overlayService.showConnectionProblems(error);

                                        // hide loading and redirect to dashboard
                                        this.loading = false;
                                        this.overlayService.hideLoading();
                                        return;
                                    }

                                });
                        this.plt.activeSubscriptions.push(subscriptionEvents);
                        // TODO[jg] - check with web notifications solution...
                        //emit value in sequence every 30 second to check live state...
                        const source = interval(30000);

                        // reset last live time
                        this.lastUpdated = null;
                        this.viewEventList = false;

                        // setTimeout(() => {
                        let subscriptionLive = source.pipe(
                            startWith(100),
                            takeUntil(this.unsubscriParticipant)
                        ).subscribe((i) => {
                            // this.appEvents.broadcast('conversations:unread', this.user.selected_participant_id);
                            // load data only when connection is available
                            if (this.plt.isConnected && this.loggedIn) {
                                // track every 10min... 30s check interval
                                let subscriptionLiveState = this.userService.getLiveState(this.user.id, i % 20 == 0).subscribe(
                                    (live) => {

                                        let newMessages = false;
                                        let lastCount = this.plt.unreadChatGroups;

                                        this.plt.unreadChatGroups = 0;

                                        // refresh page with messages
                                        if (live.unread.unreadMessages && live.unread.unreadMessages != this.plt.unreadMessages) {
                                            // refrehs component with list
                                            newMessages = true;
                                        }

                                        // store new actual notifications
                                        if (live.dashboardFeeds) {
                                            this.plt.setDashboardFeeds(live.dashboardFeeds);
                                        }

                                        // refresh page with appointments
                                        if (live.unread.unreadAppointments >= 0 && (live.unread.unreadAppointments != this.plt.unreadAppointments)) {
                                            this.appEvents.publish('refresh:appointments');
                                        }

                                        // update unread message count
                                        // TODO
                                        //this.conversationService.setUnreadCount(live.unread);

                                        this.plt.unreadMessages = live.unread.unreadMessages;
                                        this.plt.unreadAppointments = live.unread.unreadAppointments;
                                        this.plt.unreadNotifications = live.unread.unreadNotifications;
                                        this.plt.unreadOther = live.unread.unreadOther;
                                        if (typeof live.unread.unreadOther === 'object' && live.unread.unreadOther !== null) {
                                            this.plt.unreadOtherCount = Object.keys(live.unread.unreadOther).length;
                                        }

                                        // set badge number for app to same value as in app
                                        if (this.plt.is('cordova')) {
                                            cordova.plugins.firebase.messaging.setBadge(this.plt.unreadMessages + this.plt.unreadChatGroups + this.plt.unreadNotifications);
                                        }

                                        // add unread count from chat group messages
                                        live.chatGroups.forEach((chatGroup) => {
                                            if (chatGroup.hasOwnProperty('unread_messages_count')) {
                                                // count only messages from regular chat groups, not stream chats
                                                if (chatGroup.stream_id == null) {
                                                    this.plt.unreadChatGroups += chatGroup.unread_messages_count;
                                                }
                                                // refresh messages only if we are on proper chat group page
                                                let url = window.location.pathname.replace(/\//g, "").split('?')[0];
                                                if ((chatGroup.unread_messages_count > 0) && url == (this.plt.defaultLink.replace(/\//g, "") + 'messageschannel' + chatGroup.id)) {
                                                    // refresh chat
                                                    newMessages = true;
                                                }
                                            }
                                        });


                                        if (lastCount < this.plt.unreadChatGroups) {
                                            newMessages = true;
                                        }

                                        if (newMessages) {
                                            this.appEvents.publish('messages:refresh:list');
                                        }

                                        // check for user updates
                                        let userUpdateAt = moment.utc(live.user_updated_at).toDate();
                                        let participantUpdateAt = moment.utc(live.unread.updated_at).toDate();

                                        if (
                                            (
                                                (this.user && this.user.date_updated_at < userUpdateAt) // user profile
                                                || (this.lastUpdated && this.lastUpdated < participantUpdateAt) // participant profile
                                            )
                                        ) {
                                            this.appEvents.publish('user:current:participant');
                                        }
                                        // TODO[jg] replace with selected_participant.date_updated_at
                                        this.lastUpdated = participantUpdateAt;
                                    },
                                    (error) => {
                                        // problem with internet connection
                                    });

                                this.plt.activeSubscriptions.push(subscriptionLiveState);
                            }
                        });
                        this.plt.activeSubscriptions.push(subscriptionLive);
                        // }, 1000);

                    } else {
                        // close all subscription
                        this.unsubscriParticipant.next();
                        this.unsubscriParticipant.complete();
                    }

                });

        // TODO
        // Poll for new messages
        // Observable.interval(10000)
        //     .subscribe(() => {
        //         if (!this.pause && this.networkStatus && this.polling && this.auth.loggedIn() && this.user) {
        //             this.appEvents.publish('conversations:unread', this.user.id);
        //         }
        // });

        // TODO
        // this.conversationProvider.getUnreadCount()
        //     .subscribe((live_status: any) => {
        //
        //         this.unread = live_status.unread;
        //     });
    }

    /**
     * setup app event listeners
     *
     * @return void
     */
    private setAppEvents() {
        this.appEvents.subscribe('custom:design', ((data) => {
            // reset color theme
            document.getElementById('theme-color').setAttribute('content', document.getElementById('theme-color-orig').getAttribute('content'));

            if (!this.organizerPreview || (data.type && data.type == 'organizer-preview')) {
                let styles = ':root ion-app {';
                if (data.setting && !this.publicPage) {

                    if (data.setting['primary_color'] && data.setting['primary_color'] != '#B9D137') {
                        let primary = hexToRgb(data.setting['primary_color']);
                        styles += '--ion-color-primary: ' + data.setting['primary_color'] + ' !important;';
                        styles += '--ion-color-menu-highlight: ' + data.setting['primary_color'] + ' !important;';
                        styles += '--ion-color-primary-rgb: ' + primary.r + ',' + primary.g + ',' + primary.b + ';';
                        styles += '--ion-color-primary-shade: ' + shadeColor(data.setting['primary_color'], 10) + ' !important;';
                        styles += '--ion-color-primary-tint: ' + shadeColor(data.setting['primary_color'], -10) + ' !important;';
                        document.getElementById('theme-color').setAttribute('content', data.setting['primary_color']);
                    }

                    if (data.setting['secondary_color'] && data.setting['secondary_color'] != '#58B1D7') {
                        let secondary = hexToRgb(data.setting['secondary_color']);
                        styles += '--ion-color-secondary: ' + data.setting['secondary_color'] + ' !important;';
                        styles += '--ion-color-secondary-rgb: ' + secondary.r + ',' + secondary.g + ',' + secondary.b + ';';
                        styles += '--ion-color-secondary-shade: ' + shadeColor(data.setting['secondary_color'], 10) + ' !important;';
                        styles += '--ion-color-secondary-tint: ' + shadeColor(data.setting['secondary_color'], -10) + ' !important;';
                    }

                    if (data.setting['menu_color'] && data.setting['menu_color'] != '#CCCCCC') {
                        styles += '--ion-color-menu: ' + data.setting['menu_color'] + ' !important;';
                        styles += '--ion-color-menu-tint: ' + shadeColor(data.setting['menu_color'], -25) + ' !important;';
                    }

                    if (data.setting['menu_highlight_color'] && data.setting['menu_highlight_color'] != '#B9D137') {
                        styles += '--ion-color-menu-highlight: ' + data.setting['menu_highlight_color'] + ' !important;';
                    }

                    if (data.setting['overlay'] && data.setting['overlay'] != '#525E6A' && !data.setting['hide_overlay']) {
                        let overlay = hexToRgb(data.setting['overlay']);
                        let overlayShade = hexToRgb(shadeColor(data.setting['overlay'], 15));
                        styles += '--ion-color-overlay: ' + data.setting['overlay'] + ' !important;';
                        styles += '--ion-menu-header-overlay: rgba(' + overlay.r + ',' + overlay.g + ',' + overlay.b + ',0.95) !important;';
                        styles += '--ion-menu-switch-header-overlay: rgba(' + overlayShade.r + ',' + overlayShade.g + ',' + overlayShade.b + ',0.3) !important;';
                        //styles += '--ion-menu-overlay: rgba(' + overlay.r + ',' + overlay.g + ',' + overlay.b + ',0.95) !important;';
                        styles += '--ion-menu-overlay: linear-gradient(0deg, rgba(' + overlay.r + ',' + overlay.g + ',' + overlay.b + ',1) 0%, rgba(' + overlay.r + ',' + overlay.g + ',' + overlay.b + ',0.4) 20%, rgba(' + overlay.r + ',' + overlay.g + ',' + overlay.b + ',0.4) 85%, rgba(' + overlay.r + ',' + overlay.g + ',' + overlay.b + ',0.78) 100%) !important;';
                        styles += '--ion-header-overlay: rgba(' + overlay.r + ',' + overlay.g + ',' + overlay.b + ',0.85) !important;';
                        styles += '--ion-color-menu-footer: ' + data.setting['overlay'] + ' !important;';
                    } else if (data.setting['hide_overlay']) {
                        styles += '--ion-color-overlay: transpatent !important;';
                        styles += '--ion-menu-header-overlay: transaprent !important;';
                        styles += '--ion-menu-switch-header-overlay: transparent !important;';
                        //styles += '--ion-menu-overlay: rgba(' + overlay.r + ',' + overlay.g + ',' + overlay.b + ',0.95) !important;';
                        styles += '--ion-menu-overlay: transparent !important;';
                        styles += '--ion-header-overlay: transparent !important;';
                        // add help styles to solve problems with missing overlay color
                        styles += '--ion-color-bottom-menu: ' + data.setting['overlay'] + ' !important;';
                        styles += '--ion-color-keyword-box: ' + data.setting['overlay'] + ' !important;';
                        styles += '--ion-color-menu-footer: ' + data.setting['overlay'] + ' !important;';
                    }

                    if (data.setting['header_background_url'] || (this.plt.sizeSm && data.setting['mobile_header_background_url'])) {
                        styles += '--ion-header-background: url(\'' + this.plt.createSignedLink((this.plt.sizeSm && data.setting['mobile_header_background_url'] ? data.setting['mobile_header_background_url'] : data.setting['header_background_url'])) + '\') no-repeat left top !important;';
                    }

                    if (data.setting['menu_background_url'] || (this.plt.sizeSm && data.setting['mobile_menu_background_url'])) {
                        styles += '--ion-menu-background: ' + data.setting['overlay'] + ' url(\'' + this.plt.createSignedLink((this.plt.sizeSm && data.setting['mobile_menu_background_url'] ? data.setting['mobile_menu_background_url'] : data.setting['menu_background_url'])) + '\') no-repeat left top !important;';
                    }

                    if (data.setting['logo_small']) {
                        styles += '--ion-logo-menu: url(\'' + this.plt.createSignedLink(data.setting['logo_small_url']) + '\') !important';
                    }
                }
                styles += '}';
                document.getElementById('custom-style').innerHTML = styles;
            }

            if (data.type && data.type == 'organizer-preview') {
                this.organizerPreview = true;
            }

            if (this.plt.is('cordova')) {
                // change status bar color document.getElementById('theme-color').getAttribute('content')
                this.statusBar.backgroundColorByHexString(document.getElementById('theme-color').getAttribute('content'));
            }

            // inject participant for preview
            if (data.participant) {
                this.user.selected_participant = data.participant;

                // inject event for preview
                if (data.event) {
                    this.user.selected_participant.event = data.event;
                }
            }

        }));

        this.appEvents.subscribe('attendee:open:detail', ((attendee) => {
            this.showAttendeeDetail({}, attendee);
        }));

        this.appEvents.subscribe('loggedin', ((status) => {
            if (!this.isPreview) {
                this.storage.remove('invitation_token');
                this.loggedIn = true;

                // this.subscribeParticipant();
                if (this.isPublicPage()) {
                    this.publicPage = true;
                } else {
                    this.publicPage = false;
                }

                if (!this.publicPage || !this.env.hideSidebarPublicPages) {
                    this.plt.hideSidebar = false;
                }

                this.routerExtService.resetHistory();

                // show loading in left menu..
                this.loading = true;
                // start loading dashboard if login was not from token
                if (status.type != 'token') {
                    this.routerExtService.backNavigation = true;
                    this.plt.restoreStartUrl();
                }
                // init push notifications
                if (this.platform.is('cordova')) {
                    this.initPushNotification();
                }
            }
        }));

        this.appEvents.subscribe('loggedout', (() => {
            // remove device token when loggin out
            // on mobile devices from native app
            if (this.plt.is('cordova')) {
                this.userService.updateDevice({
                    push_device_token: null,
                    push_type: null
                }).subscribe(() => { }, () => { });
            }
        }));

        this.appEvents.subscribe('logout', ((message?) => {
            if (!this.isPreview) {

                // unset notification badges
                // set badge number for app to same value as in app
                if (this.plt.is('cordova')) {
                    this.plt.resetUnread();
                    cordova.plugins.firebase.messaging.setBadge(0);
                }

                this.overlayService.hideLoading();
                this.menu.close();
                //this.appEvents.publish('event:changed');
                // unsubscribe app events
                this.unsubscribe();
                // reset dashboard init
                this.plt.dashboardInit = false;
                // reset custom styles
                document.getElementById('custom-style').innerText = '';
                document.getElementById('theme-color').setAttribute('content', document.getElementById('theme-color-orig').getAttribute('content'));
                if (this.plt.is('cordova')) {
                    // change status bar color
                    this.statusBar.backgroundColorByHexString(document.getElementById('theme-color-orig').getAttribute('content'));
                }
                if (this.loggedIn) {
                    // logout
                    this.loggedIn = false;
                    this.events = [];
                    this.auth.logout();
                    this.overlayService.waitingCalls = [];
                    this.overlayService.notifications = [];
                    this.routerExtService.backNavigation = true;
                    this.routerExtService.resetHistory();
                    this.ngZone.run(() => {
                        this.nav.navigateRoot(['/login']);
                    });
                    if (message != 'logout') {
                        // add some delay to have time to init translations
                        setTimeout(() => {
                            message = message || this.translate.instant('MESSAGE_LOGGEDOUT');
                            this.overlayService.showConfirmInfo(this.translate.instant('MESSAGE_WARNING'), message, () => {
                                this.routerExtService.backNavigation = true;
                                this.routerExtService.resetHistory();
                                this.nav.navigateRoot(['/login']);
                            });
                        }, 300);
                    }
                } else {
                    this.nav.navigateRoot(['/login']);
                }
            }
        }));

        this.appEvents.subscribe('app:update:force', (() => {
            this.plt.forceUpdate = true;
        }));

        this.appEvents.subscribe('app:update:stop', (() => {
            this.plt.forceUpdate = false;
        }));


        this.appEvents.subscribe('app:update', (() => {
            if (!this.isPreview) {
                if (this.plt.is('cordova')) {
                    if (!sessionStorage.getItem('app-update-show')) {
                        // only show info about new version in store??
                        this.overlayService.showConfirmInfo(
                            this.translate.instant('MESSAGE_INFO'),
                            this.translate.instant('VERSION_CHANGED_MOBILE'),
                            () => this.updateApp()
                        );
                        sessionStorage.setItem('app-update-show', '1');

                        // repeat warning after 20minutes again...
                        setTimeout(() => {
                            sessionStorage.removeItem('app-update-show');
                        }, 1200000);
                    }
                } else {
                    if (this.updates.isEnabled) {
                        this.updates.checkForUpdate();
                    } else {
                        // manual update after 2 minutes
                        setTimeout(() => {
                            this.overlayService.showConfirm(
                                this.translate.instant('MESSAGE_INFO'),
                                this.translate.instant('VERSION_CHANGED'),
                                () => document.location.reload()
                            );
                        }, 120000);
                    }
                }
                //https://angular.io/guide/service-worker-communications

                // setTimeout(() => {
                //     this.overlayService.version(this.translate.instant('VERSION_CHANGED'));
                // }, 3000);
            }

        }));

        this.appEvents.subscribe('change:event', ((eventId: number) => {

            let possibleEvents = this.events.filter(e => e.id != eventId);
            if (possibleEvents.length > 0) {
                this.selectEvent(possibleEvents[0].id);
            } else {
                this.logout();
            }
        }));

        this.appEvents.subscribe('deeplink:open', ((eventId: number, route: string, options: NavigationOptions) => {
            this.selectEvent(eventId, route, options, true);
        }));

        if (this.updates.isEnabled) {
            this.updates.versionUpdates.subscribe(event => {

                if (event.type == 'VERSION_INSTALLATION_FAILED') {
                    log('info', 'App update failed', event);
                    // TODO[jg] - do something about it?
                }

                if (event.type == 'VERSION_DETECTED') {
                    log('info', 'App update available', event);
                }

                if (event.type == 'VERSION_READY') {

                    log('info', 'App update ready', event);

                    this.updates.activateUpdate().then(
                        () => {
                            if (this.plt.forceUpdate) {
                                () => {
                                    // update serwice worker, this was causing bug with POST image
                                    navigator.serviceWorker.getRegistrations().then((worker) => { worker[0].update() });
                                    document.location.reload();
                                }
                            } else {
                                this.overlayService.showConfirm(
                                    this.translate.instant('MESSAGE_INFO'),
                                    this.translate.instant('VERSION_CHANGED'),
                                    () => {
                                        // update serwice worker, this was causing bug with POST image
                                        navigator.serviceWorker.getRegistrations().then((worker) => { worker[0].update() });
                                        document.location.reload()
                                    }
                                );
                            }
                        }
                    );
                }
                //}
            });
        }

        // TODO
        // this.appEvents.subscribe('selected:participant:updated', ((participant: Participant) => {
        //     this.events.forEach((event: Event) => {
        //         if (event.id === participant.event_id) {
        //             event.current_user_participant[0] = participant;
        //         }
        //     });
        // }));

        this.router.events.subscribe(
            (event: any) => {

                // for route event event get route event
                if ((event instanceof Scroll)) {
                    event = <RouterEvent>event.routerEvent;
                }

                // using url from NavigationEnd instance, because route.snapshot was not sometimes updated...
                if (event instanceof RouterEvent && event instanceof NavigationEnd) {

                    // check url type, replace path delimiter and numbers
                    let url = window.location.pathname.replace(/\//g, "").split('?')[0].replace(/[0-9]/g, "");

                    if (this.noBottomMenuPages.indexOf(url) != -1 && this.plt.tabMenu) {
                        this.plt.hideBottomMenu = true;
                    } else {
                        this.plt.hideBottomMenu = false;
                    }

                    if (this.isPublicPage()) {
                        this.publicPage = true;

                        if (this.env.hideSidebarPublicPages) {
                            this.plt.hideSidebar = true;
                        }

                        if (this.env.publicPagesLang) {
                            this.translate.use(this.env.publicPagesLang);
                            this.userLang = this.env.publicPagesLang;
                        }
                    } else {
                        this.publicPage = false;
                        // show sidebar by default
                        this.plt.hideSidebar = false;
                    }

                    // decide which tab should be active evetnt/messages
                    if (url.indexOf("messages") != -1) {
                        //this.switchMenu('messages');
                        this.viewType = 'messages';
                    } else {
                        // show event tab
                        this.viewType = 'event';
                    }
                }
            });

        // attach actions to back button event
        if (!(this.plt.is('cordova') && this.plt.is('android'))) {
            window.addEventListener("popstate", (event) => {
                this.overlayService.closeModals();
                // close modal if there is any opened
                // this.modalController.getTop().then((v) => {
                //     if (v) {
                //         this.modalController.dismiss()
                //     } else {
                //         if (this.routerExtService.canGoBack()) {
                //             this.routerExtService.back();
                //         } else {
                //             // how to exit app?
                //         }
                //     }
                // });
            });
        }

        window.addEventListener('keyboardWillHide', () => {
            this.keyboardVisible = false;
            this.plt.checkResize(false);
            // Describe your logic which will be run each time when keyboard is about to be closed.
        });
        window.addEventListener('keyboardWillShow', () => {
            // Describe your logic which will be run each time when keyboard is about to be shown.
            this.keyboardVisible = true;
            this.plt.checkResize(false);
            //this.keyboardHeight = e.keyboardHeight;
        });

        if (!this.plt.is('cordova') && this.plt.sizeSm) {
            window.addEventListener('orientationchange', () => {
                this.plt.checkOrientationChange();
            });
        }

        this.appEvents.subscribe('custom:profile', (data) => {
            // check if users menu for authenticated is opened
            if ((this.loggedIn && !this.publicPage) && !this.wizardOpened && !this.isPreview) {
                this.wizardOpened = true;
                this.showWizard(data.profile_setting, data.step)
            }
        });
    }


    /**
     * init push notifications
     */
    initPushNotification() {

        // forceShow - When value is true incoming notification is displayed even when app is in foreground.
        cordova.plugins.firebase.messaging.requestPermission({ forceShow: false }).then(
            () => {
                log("info", "Push messaging is allowed");
                cordova.plugins.firebase.messaging.getToken().then((token) => {
                    log("info", "Got device token: ", token);
                    this.userService.updateDevice({
                        push_device_token: token,
                        push_type: 'FCM'
                    }).subscribe(() => { }, () => { });
                });
            }, (error) => {
                // not granted, do nothing
            });

        cordova.plugins.firebase.messaging.onTokenRefresh(() => {
            log("info", "Device token updated");
            cordova.plugins.firebase.messaging.getToken().then((token) => {
                log("info", "Got device token: ", token);
                this.userService.updateDevice({
                    push_device_token: token,
                    push_type: 'FCM'
                }).subscribe(() => { }, () => { });
            });
        });

        cordova.plugins.firebase.messaging.onBackgroundMessage((payload) => {
            log("info", "New background FCM message: ", payload);
            this.processPushNotification(payload, false);
        });

        cordova.plugins.firebase.messaging.onMessage(
            (payload) => {
                log("info", "New foreground FCM message: ", payload);
                this.processPushNotification(payload);
            }
        );

        // TODO[jg] - check this with new firebase messaging plugin...
        // log('info', 'init notification');
        // const options: PushOptions = {
        //     android: {
        //         icon: "noticon",
        //         forceShow: true
        //         // iconColor: '#BED600',
        //     },
        //     ios: {
        //         sound: true,
        //         alert: true,
        //         badge: true,
        //         clearBadge: true
        //     }
        // };

        // pushObject.on('error').subscribe(error => log('error', 'Error with Push plugin', error));

    }

    private processPushNotification(payload, foreground: boolean = true) {
        let notificationData = payload.detailData;

        // check multiple records available for notification data
        if (payload.detailData && payload.detailData[0] && payload.detailData[0]['data']) {
            notificationData = payload.detailData[0]['data'][0];
        }

        // clear notifications
        // cordova.plugins.firebase.messaging.clearNotifications();

        // where to redirect user after
        let pageLink = '';
        // should we show modal with buttons when app is in foreground?
        let showForeground = true;

        //this.dump(details);
        if (payload.hasOwnProperty('type')) {
            // redirect to proper page
            switch (payload.type) {
                case "App\\Notifications\\NewMessage":
                    showForeground = false;
                    if (notificationData.id) {
                        pageLink = this.plt.defaultLink + '/messages/direct/' + notificationData.id;
                    } else {
                        pageLink = this.plt.defaultLink + '/messages';
                    }
                    break;
                case "App\\Notifications\\NewChatGroupMessage":
                    showForeground = false;
                    if (notificationData.hasOwnProperty('chat_group_id')) {
                        pageLink = this.plt.defaultLink + '/messages/channel/' + notificationData.chat_group_id;
                    } else {
                        pageLink = this.plt.defaultLink + '/messages';
                    }
                    break;
                case "App\\Notifications\\NewAppointment":
                    pageLink = this.plt.defaultLink + '/appointments';
                    break;
                case "App\\Notifications\\AcceptedAppointment":
                case "App\\Notifications\\CanceledAppointment":
                    if (notificationData.hasOwnProperty('id')) {
                        pageLink = this.plt.defaultLink + '/messages/direct/' + notificationData.id;
                    } else {
                        pageLink = this.plt.defaultLink + '/messages';
                    }
                    break;
                case "App\\Notifications\\NewKeyword":
                    pageLink = this.plt.defaultLink + '/home/info/manage-keywords';
                    break;
                case "App\\Notifications\\NewsfeedComment":
                case "App\\Notifications\\NewsfeedLike":
                case "App\\Notifications\\Newsfeedconfirmed":
                case "App\\Notifications\\NewsfeedDeclined":
                case "App\\Notifications\\ProfileInterest":
                    pageLink = this.plt.defaultLink + '/home/info/matching';
                    break;
                case "App\\Notifications\\NewBroadcast":
                    pageLink = this.plt.defaultLink + '/messages';
                    break;
                case "App\\Notifications\\KeywordRejected":
                    pageLink = this.plt.defaultLink + '/home/info/manage-keywords';
                    break;
                case "App\\Notifications\\KeywordAccepted":
                    pageLink = this.plt.defaultLink + '/home/info/manage-keywords';
                    break;
                case "App\\Notifications\\ParticipantInvitation":
                    this.appEvents.publish('user:current'); // refresh users feed
                    pageLink = this.plt.defaultLink + '/home/info/manage-keywords';
                    break;
                case "App\\Notifications\\NewVoting":
                    pageLink = this.plt.defaultLink + '/home/info/manage-keywords';
                    break;
            }
        }

        if (foreground) {
            // show only if necessary.. new messages and appointments are visible in bottom menu
            if (showForeground) {
                // if application open, show popup
                this.overlayService.showConfirm(
                    payload.title,
                    payload.message,
                    () => {
                        if (pageLink) {
                            // open modal with newsfeed detail, passing notification data to router is not always working...
                            if (notificationData.hasOwnProperty('newsfeed_id')) {
                                setTimeout(() => {
                                    log('info', 'newsfeed show', notificationData);
                                    this.appEvents.publish('newsfeed:show', notificationData.newsfeed_id);
                                }, 700);
                            }
                            if (payload.eventId && payload.eventId != this.user.selected_participant_id) {
                                // hange event before redirect
                                this.selectEvent(payload.eventId, pageLink, { queryParams: notificationData });

                            } else {
                                this.router.navigate([pageLink], { queryParams: notificationData });
                            }
                        }
                    });
            }

        } else {
            // use delay because of push page service init
            setTimeout(() => {
                // open modal with newsfeed detail, passing notification data to router is not always working...
                if (notificationData.hasOwnProperty('newsfeed_id')) {
                    setTimeout(() => {
                        log('info', 'newsfeed show', notificationData);
                        this.appEvents.publish('newsfeed:show', notificationData.newsfeed_id);
                    }, 700);
                }
                log('info', 'pusblish', notificationData);
                if (payload.eventId && payload.eventId != this.user.selected_participant_id) {
                    // hange event before redirect
                    this.selectEvent(payload.eventId, pageLink, { queryParams: notificationData });
                } else {
                    this.router.navigate([pageLink], { queryParams: notificationData });
                }
            }, 500);
        }
    }

    /**
     * register hardware back button action
     *
     * @return void
     */
    public registerBackButtonAction() {
        //back button handle
        //Registration of push in Android and Windows Phone
        let lastTimeBackPress = 0
        let timePeriodToExit = 2000;

        this.platform.backButton.subscribeWithPriority(0, () => {

            // close menu if opened
            if (this.plt.tabMenu && this.plt.tabMenuOpened) {
                log('info', 'Navigation - close menu');
                this.menu.close();
            } else {

                if (this.routerExtService.canGoBack()) {
                    log('info', 'Navigation - back');
                    this.routerExtService.back();
                } else {
                    log('info', 'Navigation - no previous page');
                    if (this.plt.is('cordova')) {
                        //Double check to exit app
                        if (new Date().getTime() - lastTimeBackPress < timePeriodToExit) {
                            cordova.plugins.exit(); //Exit from app
                        } else {
                            // show message how to close app
                            this.overlayService.showSuccess(this.translate.instant('PRESS_AGAIN_TO_EXIT'));
                            lastTimeBackPress = new Date().getTime();
                        }
                    }
                }

            }
        });
    }

    /**
      * set root page
      *
      * @return void
      */
    private setRootPage() {

        // parse route path from url
        let url = window.location.pathname.replace(/\//g, "");

        url = url.split('?')[0];
        url = url.split('/')[0];

        this.activePage = url;

        this.isPreview = false;

        // check for preview
        if (this.previewUrlStrings.indexOf(url) != -1) {
            this.isPreview = true;
        }

        if (this.isPublicPage()) {
            this.publicPage = true;
        }

        // check if there is token provided in URL
        let urlToken = this.urlParams.get('token');

        if (this.urlParams.get('top-menu')) {
            this.plt.tabMenuOnTop = true;
        }

        if (this.urlParams.get('hide-dashboard-header')) {
            this.plt.hideDashboardHeader = true;
        }

        // allow to override config setting with url query params
        if (this.urlParams.get('hide-setting-password-reset')) {
            this.plt.hideSettingsPasswordReset = true;
        } else {
            this.plt.hideSettingsPasswordReset = this.env.hideSettingsPasswordReset;
        }

        if (this.urlParams.get('hide-logout')) {
            this.plt.hideLogout = true;
        } else {
            this.plt.hideLogout = this.env.hideLogout;
        }

        if (this.urlParams.get('admin-token')) {
            localStorage.setItem('admin-token', this.urlParams.get('admin-token'));
        }

        if (this.urlParams.get('event_id')) {
            this.plt.forceEventId = this.urlParams.get('event_id');
        }

        if (this.urlParams.get('preview_token')) {
            sessionStorage.setItem('preview_token', this.urlParams.get('preview_token'));
        }

        if (localStorage.getItem('admin-token')) {
            this.plt.adminToken = localStorage.getItem('admin-token');
            // now only for translation model
            this.plt.translationMode = true;
        }

        if (this.urlParams.get('api-version')) {
            sessionStorage.setItem('api-version', this.urlParams.get('api-version'));
        }

        // check external token
        if (this.urlParams.get('external_token') && this.urlParams.get('event_id')) {
            this.loginWithExternalToken(this.urlParams.get('external_token'), this.urlParams.get('event_id'));
        } else {
            // check existing token
            //this.storage.ready().then(() =>
            {
                this.storage.get('token').then((token) => {
                    log("info", 'TOKEN IN STORAGE: ', token);
                    // if there is only token in URL, use it, except set password page
                    if (!token && urlToken && url != 'set-password') {
                        token = urlToken;
                        this.storage.set('token', token);
                    }
                    if (token) {
                        // store token to local storage for future using
                        this.auth.updateToken(token);
                        // add delay to have some additional time for app initialization
                        // local storage saving is slow
                        setTimeout(() => {
                            this.auth.loginFromToken(token, url == 'login' ? 'login' : 'token', this.plt.forceEventId);
                            // reset force event id
                            this.plt.forceEventId = null;
                        }, 250);
                        // TODO[jg] deepLinkRoute  with object with eventId to redirect to proper event
                        // this.pushPageService.init(this.deepLinkRoute);
                    } else {
                        // redirect only if there is no deeplink
                        // if (!this.deepLinkRoute) { // TODO
                        //   this.pushPageService.setRoot(EntryPage, null, true);
                        // }
                        if (!this.publicPage) {
                            this.plt.saveStartUrl(this.startUrl);
                        }
                        // check for authetntication only if needed
                        if (!this.isPreview && !this.isPublicPage() && url.indexOf('register') == -1 && url.indexOf('login') == -1) {
                            this.nav.navigateRoot(['/login']);
                        }
                    }
                });
            }
            //);
        }
    }

    /**
      * use external token for login
      *
      * @return void
      */
    private loginWithExternalToken(token, eventId) {
        this.overlayService.showLoading();
        this.auth.loginWithExternalToken(token, eventId)
            .subscribe(
                (success) => {
                    this.overlayService.hideLoading();
                    // login successful if there's a jwt token in the response
                    const token = success.token as string;

                    if (token) {

                        this.auth.updateToken(token);

                        // add delay to have some additional time for app initialization
                        // local storage saving is slow
                        setTimeout(() => {
                            this.auth.loginFromToken(token);
                        }, 250);
                    } else {
                        // redirect to login page
                        this.nav.navigateRoot(['/login']);
                    }
                },
                (error) => {
                    this.overlayService.hideLoading();
                    this.nav.navigateRoot(['/login']);
                });
    }

    /**
     * update PWA resources
     *
     * @return void
     */
    updatePwa() {
        if (this.updates.isEnabled) {
            this.overlayService.showLoading();
            this.updates.checkForUpdate();
            // fallback to reload page after 30s
            setTimeout(() => {
                // update serwice worker, this was causing bug with POST image
                navigator.serviceWorker.getRegistrations().then((worker) => { worker[0].update() });
                document.location.reload();
            }, 30000);
        } else {
            document.location.reload();
        }
    }

    /**
     * update mobile app - show store details
     *
     * @return void
     */
    updateApp() {
        if (this.plt.is('android')) {
            window.open(this.env.playStoreLink, '_system');
        } else {
            window.open(this.env.appStoreLink, '_system');
        }
    }

    /**
     * go to participants detail page
     *
     * @param participant
     *
     * @return void
     */
    public async showAttendeeDetail(detail, attendee?) {

        this.routerExtService.softNavigate();

        const modal = await this.modalController.create({
            component: AttendeeDetailComponent,
            cssClass: 'attendee-modal',
            componentProps: {
                attendee: attendee || this.user.selected_participant,
                detail: attendee ? null : detail
            }
        });

        modal.onWillDismiss().then((data) => {
            if (data.data?.action == 'close' || data.role == 'backdrop') {
                this.routerExtService.softBack();
            }
        });

        return await modal.present();
    }

    /**
     * switch menu view
     *
     * @param type
     *
     * @return void
     */
    switchMenu(type: string) {

        // dont switch menu if it is preview
        if (this.isPreview) {
            return false;
        }

        this.viewType = type;

        switch (type) {
            case 'event': {
                this.nav.navigateRoot([this.plt.defaultLink + '/home/info/matching']);
            }
                break;
            case 'messages': {
                this.nav.navigateRoot([this.plt.defaultLink + '/messages'],);
            }
                break;
        }
    }

    /**
     * disable invisible profile
     *
     * @param status
     *
     * @return void
     */
    public hideProfile(status: boolean = false) {
        // set request to API to store actual data
        this.participantService.hide(this.user.selected_participant.id, status).subscribe(
            (success) => {

                this.user.selected_participant.invisible = status;

                // show succes message if profile was saved
                this.overlayService.showSuccess(success.message);

                // reload user after updating participant
                this.appEvents.publish('user:current');

            },
            (error) => {
                const data = error.error

                this.overlayService.showError(data.message);
            });
    }


    /**
     * open participatn profile wizard
     *
     * @param profile_setting
     * @param step
     *
     * @return void
     */
    public async showWizard(profile_setting, step) {

        const modal = await this.modalController.create({
            component: WizardComponent,
            cssClass: 'wizard-modal',
            backdropDismiss: false,
            keyboardClose: false,
            componentProps: {
                profile_setting: profile_setting,
                step: step
            }
        });

        modal.onWillDismiss().then((data) => {
            this.wizardOpened = false;
        });

        return await modal.present();
    }

    public isPublicPage() {
        let prefix = window.location.pathname.split('?')[0].split('/')[1];
        return this.plt.noTabsPages.indexOf(prefix) != -1;
    }

    // start checking context to enable user tracking only when needed
    public checkTracking() {

        // before starting tracking for logged in users, check if event is allowed to be tracked...
        if (localStorage.getItem('token')) {
            this.tracker.optUserOut();
            // DONE in setting
            // this.tracker.enableLinkTracking(false);
        }

        // for tracking public sites, we can use matomo without cookies... maybe..
        // https://matomo.org/faq/general/faq_156/
        // this.tracker.disableCookies();

        this.eventService.getCurrentEvent().subscribe(
            (event) => {

                // check spec by hostname, for mobile app use assets url
                let hostname = this.plt.is('cordova') ? environment.assets.replace('https://', '') : location.hostname;

                log('info', 'Domain tracking', hostname);

                if (event?.id && environment.trackingWhitelist.hasOwnProperty(hostname)) {

                    let trackingConfig = environment.trackingWhitelist[hostname];

                    log('info', 'check tracking', trackingConfig.communityWhitelist.hasOwnProperty("" + event.id), event.id);
                    if (
                        !trackingConfig.hasOwnProperty('communityWhitelist')
                        || trackingConfig.communityWhitelist.hasOwnProperty("" + event.id)
                    ) {
                        if (
                            trackingConfig.hasOwnProperty('communityWhitelist')
                            && trackingConfig.communityWhitelist.hasOwnProperty(event.id)
                        ) {
                            this.tracker.setSiteId(trackingConfig.communityWhitelist[event.id]);
                        } else {
                            this.tracker.setSiteId(trackingConfig.siteId);
                        }

                        // enable tracking only for real users
                        // skillsclub team is disabling tracking for them self
                        if (!localStorage.getItem('disable-tracking')) {
                            // wait with tracking, to be sure that context is switched properly
                            setTimeout(() => {
                                this.tracker.forgetUserOptOut();
                                this.tracker.enableLinkTracking(true);
                            }, 500);
                        }

                        // ingore client preference
                        // this.tracker.setDoNotTrack(false);
                    } else {
                        // disable tracking for oterh communities
                        this.tracker.optUserOut();
                        this.tracker.enableLinkTracking(false);

                        // apply client preference
                        // this.tracker.setDoNotTrack(false);
                    }
                }
            });
    }
    closeiOSInstall() {
        this.showiOSInstallMessage = false
        sessionStorage.setItem('ios-install', '1');
    }
}