import { NgModule, Injector, Injectable, APP_INITIALIZER, inject } from '@angular/core';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
//import { NgModule, Injector, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule } from '@ionic/angular';
//import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { Push } from "@awesome-cordova-plugins/push/ngx";
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Calendar } from "@awesome-cordova-plugins/calendar/ngx";
import { Insomnia } from "@awesome-cordova-plugins/insomnia/ngx";
import { ImagePicker } from "@awesome-cordova-plugins/image-picker/ngx";
import { FileTransfer } from "@awesome-cordova-plugins/file-transfer/ngx";
import { File } from '@awesome-cordova-plugins/file/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';

// setting
import { CustomRouteReuseStrategy } from './settings/routing.strategy';

// modules
import { SharedModule } from "./modules/shared.module";

// pages modules
import { LoginPageModule } from "./pages/login/login.module";
import { LostPasswordPageModule } from "./pages/lost-password/lost-password.module";
import { SetPasswordPageModule } from "./pages/set-password/set-password.module";
import { RegistrationPageModule } from "./pages/registration/registration.module";
import { TocPageModule } from "./pages/toc/toc.module";

// ionic modules
import { Drivers, Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

// other modules
import { OAuthModule } from 'angular-oauth2-oidc';
import { OauthCordova } from "ng2-cordova-oauth/platform/cordova";
import { AngularSvgIconModule } from 'angular-svg-icon';

// Http
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { JwtModule } from "@auth0/angular-jwt";

// translator
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// services
import { RequestInterceptService } from "./services/request-intercept.service";
import { BeforeTrackingInterceptor, MyAsyncTrackingInterceptor } from "./services/matomo-intercept.service";
import { ServiceLocatorService } from "./services/service-locator.service";
import { PlatformService } from "./services/platform.service";

// pipes
import { SafePipe } from './pipes/string/safe';

import { log } from "./helpers/log";

// config
import { environment } from '../environments/environment';

// sentry
import * as Sentry from '@sentry/browser';
import { ErrorHandler } from '@angular/core';
import { provideMatomo, withRouterInterceptors } from 'ngx-matomo-client';
import { withRouter, withRouteData } from 'ngx-matomo-client';

var ignoreErrors = [
    'overlay does not exist',
    'QuotaExceededError',
    'No JWT present or has expired'
];

// secure that mobile app will have proper hostname for configs
var hostname = location.hostname.includes('localhost') ? environment.assets.replace(/(http:\/\/|https:\/\/)/, '') : location.hostname

if (environment.sentry.enabled) {
    Sentry.init({
        release: "congreet.pwa@" + environment.version,
        dsn: environment.sentry.dsn,
        ignoreErrors: ignoreErrors,
        beforeBreadcrumb(breadcrumb, hint) {
            if (breadcrumb.category === 'ui.click') {
                let el;
                if (hint.event instanceof HTMLElement) {
                    el = hint.event;
                }
                if (hint.event instanceof MouseEvent) {
                    el = hint.event.target;

                    breadcrumb.message += ' EVENT: MouseClick';
                }

                if (el) {
                    //const { target } = hint.event;
                    if (el.getRootNode && el.getRootNode() && el.getRootNode().host) {
                        if (el.getRootNode().host.id) {
                            breadcrumb.message += ' ID: ' + el.getRootNode().host.id;
                        }
                        if (el.getRootNode().host.innerText) {
                            breadcrumb.message += ' TEXT: ' + el.getRootNode().host.innerText;
                        }
                    }
                    if (el.id) {
                        breadcrumb.message += ' ID: ' + el.id;
                    }
                    if (el.innerText) {
                        breadcrumb.message += ' TEXT: ' + el.innerText;
                    }
                }
            }
            return breadcrumb;
        },
    });

    // https://www.npmjs.com/package/@sentry/browser
    Sentry.configureScope(scope => {
        scope.setExtra('environment', environment.name);
        //scope.setTag('user_mode', 'admin');
        //scope.setUser({ id: '4711' });
    });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {
        if (environment.sentry.dialog) {
            let newcontent = document.createElement('div');
            newcontent.innerHTML = ' <style>#reports{position:fixed;top:0;left:0;width:100vw;margin:0;background:red;color:white;}#reports li{padding:8px;}</style><ul id="reports"></ul>';
            document.body.appendChild(newcontent);
        }
    }
    handleError(error) {
        // Sentry.captureException(error.originalError || error);
        // throw error;
        if ((error.hasOwnProperty('_body') && error.hasOwnProperty('headers') && error.hasOwnProperty('status')) || (error.hasOwnProperty('status') && error.status == 400)) {
            // API error, so don't create error report
        } else {
            if (environment.sentry.enabled) {
                let realError = error.originalError || error;
                const eventId = Sentry.captureException(realError);
                if (environment.sentry.dialog) {
                    let ignored = false;

                    ignoreErrors.forEach((ignoreError) => {
                        if (realError.message.search(ignoreError) != -1) {
                            ignored = true;
                        }
                    })

                    if (!ignored) {
                        //Sentry.showReportDialog({ eventId });
                        let newcontent = document.createElement('li');
                        newcontent.innerHTML = "There was an Javascript Error. SentryId: " + eventId;
                        document.getElementById('reports').appendChild(newcontent);
                    }
                }
            }
            if (environment.debug || !environment.sentry.enabled) {
                log('error', error.originalError || error);
            }
        }
    }
}

export function createTranslateLoader(
    http: HttpClient
) {
    if (environment.liveTranslation && navigator.onLine) {
        return new TranslateHttpLoader(http, environment.api + '/translation/file/');
    } else {
        return new TranslateHttpLoader(http, '/assets/i18n/', '.json?version=' + environment.version);
    }

}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            mode: 'ios'
        }),
        AppRoutingModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
        IonicStorageModule.forRoot({
            name: '__congreet',
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                headerName: "Authorization",
                authScheme: "Bearer ",
                tokenGetter: () => {
                    return localStorage.getItem('token');
                },
                allowedDomains: [
                    "assets.congreet.com",
                    "server.congreet.com",
                    "assets.coolgreet.de",
                    "api.coolgreet.de",
                    "api.dev.coolgreet.de",
                    "server.congreet.loc",
                    "app.skillsclub.com",
                    "skillsclub.coolgreet.de",
                    "community.online-print-symposium.de",
                    "localhost",
                    "10.0.2.2"],
                disallowedRoutes: [/\/api\/v2\/translation.*/]
            },
        }),
        // Ng2UiAuthModule.forRoot({
        //     providers: {
        //         linkedin: {
        //             clientId: environment.linkedIn.clientId
        //         }
        //     }
        // }),
        // Ng2UiAuthModule.forRoot(myAuthConfig),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        AngularSvgIconModule.forRoot(),
        SharedModule,
        LoginPageModule,
        LostPasswordPageModule,
        SetPasswordPageModule,
        RegistrationPageModule,
        TocPageModule,
        OAuthModule.forRoot()
    ],
    providers: [
        Camera,
        Calendar,
        Insomnia,
        ImagePicker,
        FileTransfer,
        File,
        StatusBar,
        Keyboard,
        SplashScreen,
        PlatformService,
        SignInWithApple,
        Push,
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        //{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptService,
            multi: true
        },
        //{ provide: LOCALE_ID, useValue: 'de-DE'}
        OauthCordova,
        SafePipe,
        provideMatomo(
            // https://github.com/EmmanuelRoux/ngx-matomo/blob/main/docs/configuration-reference.md
            {
                trackerUrl: environment.trackingWhitelist.hasOwnProperty(hostname) && environment.trackingWhitelist[hostname].tracker ? environment.trackingWhitelist[hostname].tracker : 'https://matomo.congreet.com',
                siteId: environment.trackingWhitelist.hasOwnProperty(hostname) ? environment.trackingWhitelist[hostname].siteId : '1',
                disabled: !environment.trackingWhitelist.hasOwnProperty(hostname),
                enableLinkTracking: false,
                // should we disable tracking fot those who dont approve it?
                // acceptDoNotTrack: true
            }, withRouter({
                delay: 500,
                exclude: environment.trackingWhitelist.hasOwnProperty(hostname) && environment.trackingWhitelist[hostname].hasOwnProperty('exclude') ?
                    environment.trackingWhitelist[hostname].exclude
                    : [
                        'lost-password', 'set-password', 'register', 'login', 'toc', 'credentials/', 'credential/', 'mail-setting', 'deeplink', 'unsubscribe'
                    ],
            }),
            // cant be used as we have dynamic routes with variables..
            // withRouteData(),
            // Add interceptors here:
            withRouterInterceptors([
                BeforeTrackingInterceptor,
                // MyAsyncTrackingInterceptor
            ])),
            // LinkedIn Auth Login, loaded in login page...
            // { provide: AuthConfig, useValue: linkedInAuthConfig },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    /**
     * constructor
     *
     * @return void
     */
    public constructor(private injector: Injector) {
        ServiceLocatorService.injector = this.injector;

        // set froala license
        localStorage.setItem('FEK', '6Ud1QBRVCDLPAZMBQ==');
    }
}
