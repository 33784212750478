export class BroadcastTranslation {
    id: number;
    locale: string;
    sender_name: string;
    subject: string;
    message: string;
    headerImage

    /**
     * constructor
     *
     * @param {BroadcastTranslation} init
     */
    public constructor(init?: BroadcastTranslation) {
        if (!!init) {
            Object.assign(this, init);

            // parse and provide header image
            let matches = this.message.match(/\[HeaderImage\](.*)\[\/HeaderImage\]/i);
            if (matches && matches[1]) {
                let image = matches[1].match(/src="([^"]*)"/i);
                if (image[1]) {
                    this.headerImage = image[1];
                }
            }
        }
    }
}
