import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { MenuController } from '@ionic/angular';

// ionic native
import { Calendar } from "@awesome-cordova-plugins/calendar/ngx";
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
// import { File } from '@awesome-cordova-plugins/file/ngx';
// import { FileTransfer, FileTransferObject } from "@awesome-cordova-plugins/file-transfer/ngx";

import { ConnectionService, ConnectionState, ConnectionServiceOptions } from 'ng-connection-service';
import { TranslateService } from "@ngx-translate/core";

// rxjs
import { tap } from 'rxjs/operators';

// providers
import { AuthenticationService } from "./authentication.service";
import { OverlayService } from "./overlay.service";
import { StorageService } from "./storage.service";

// models
import { Feed } from "../models/feed";

// config
import { environment } from "../../environments/environment";

import { log } from '../helpers/log';
import { Events } from '../helpers/events';
import { isValidUrl } from './helper';

// TODO
// import { PushPageService } from "./push-page-service";

declare var cordova: any;
declare var device: any;
declare var Connection: any;
declare var navigator: any;
declare var screen: any;

@Injectable({
    providedIn: 'root'
})
export class PlatformService {

    /**
     * pause state
     *
     * @return void
     */
    public pause: boolean = false;

    /**
     * reloadable page state
     *
     * @return void
     */
    public reloadable: boolean = true;

    /**
     * variable with size status
     *
     * @return void
     */
    public sizeXl: boolean = false;
    public sizeLg: boolean = false;
    public sizeMd: boolean = false;
    public sizeMs: boolean = false;
    public sizeSm: boolean = false;

    /**
     * default link for navigation... empty or '/tabs'
     *
     * @type {string}
     */
    public defaultLink: string = '';

    /**
     * default link for navigation... empty or '/tabs'
     *
     * @type {boolean}
     */
    public tabMenu: boolean = false;

    /**
     * check if side menu is opened
     *
     * @type {boolean}
     */
    public tabMenuOpened: boolean = false;

    /**
     * counter of unread messages
     *
     * @type {number}
     */
    public unreadMessages: number = 0;

    /**
     * counter of unread chat group messages
     *
     * @type {number}
     */
    public unreadChatGroups: number = 0;

    /**
     * dashboard feeds
     *
     * @type {Feed[]}
     */
    public dashboardFeeds: Feed[] = [];

    /**
     * dashboard feeds visited
     *
     * @type {Feed[]}
     */
    public dashboardFeedsVisited: Feed[] = [];

    /**
     * dashboard feeds not visited
     *
     * @type {Feed[]}
     */
    public dashboardFeedsNotVisited: Feed[] = [];

    /**
     * counter of unread appointments
     *
     * @type {number}
     */
    public unreadAppointments: number = 0;

    /**
     * counter of unread notifications
     *
     * @type {number}
     */
    public unreadNotifications: number = 0;

    /**
     * counter of unread appointments
     *
     * @type {number}
     */
    public unreadOtherCount: number = 0;

    /**
     * object with attributes as event id with numbe of unread notifications
     *
     * @type {Object}
     */
    public unreadOther: {} = {};

    /**
     * actual app height
     *
     * @type {number}
     */
    public appHeight: number = 0;

    /**
     * actual app height
     *
     * @type {number}
     */
    public appWidth: number = 0;

    /**
     * Network status
     *
     * @return void
     */
    public status: string = 'ONLINE';
    public isConnected: boolean = false;

    /**
     * if we should show bottom menu
     *
     * @type {boolean}
     */
    public hideBottomMenu: boolean = false;

    /**
     * if we should show tab menu on the top and always visible
     *
     * @type {boolean}
     */
    public tabMenuOnTop: boolean = false;

    /**
     * if we should hide header on dashboard page
     *
     * @type {boolean}
     */
    public hideDashboardHeader: boolean = false;

    /**
     * global setting for change password form visibility
     *
     * @type {boolean}
     */
    public hideSettingsPasswordReset: boolean = false;

    /**
     * global setting for logout button visibility
     *
     * @type {boolean}
     */
    public hideLogout: boolean = false;

    /**
     * if we should show sidebar
     *
     * @type {boolean}
     */
    public hideSidebar: boolean = false;

    /**
     * if we should show bottom menu
     *
     * @type {number}
     */
    public eventsCount: number = 0;

    /**
    * is there some event with credentials wallet?
    *
    * @type {boolean}
    */
    public eventWithCredentialWallet: boolean = false;

    public timezoneOffset: string = '+02:00';

    public inAppBrowserRef;

    /**
     * should be app disabled and visible only info about app update
     *
     * @type {boolean}
     */
    public forceUpdate = false;

    /**
     * count of attendees boxes per line in list
     *
     * @type {number}
     */
    public attendeesPerLine: number = 5;

    /**
     * hide layer with app (used for some background cordova plugins)
     *
     * @type {boolean}
     */
    public hideApp: boolean = false;

    /**
     * don't refresh data on background
     *
     * @type {boolean}
     */
    public disableBackgroundRefresh: boolean = false;

    /**
     * version of current operating system (for iOS 13,14...)
     *
     * @type {any}
     */
    public systemVersion;

    /**
     * JWT token for admin (now used for translation module)
     *
     * @type {string}
     */
    public adminToken: string;

    /**
     * First url used for app init
     *
     * @type {string}
     */
    public startUrl: string;

    /**
     * Force specific event ID
     *
     * @type {number}
     */
    public forceEventId;

    /**
     * Is modal with attendee detail opened
     *
     * @type {boolean}
     */
    public attendeeDetailOpened: boolean = false;

    /**
     * Is modal with marketplace post opened
     *
     * @type {boolean}
     */
    public marketplacePostOpened: boolean = false;

    /**
     * Is modal with voting opened
     *
     * @type {boolean}
     */
    public votingOpened: boolean = false;

    /**
     * Is modal with newsfeeed opened
     *
     * @type {boolean}
     */
    public newsfeedOpened: boolean = false;

    /**
    * Is modal with video opened
    *
    * @type {boolean}
    */
    public videoOpened: boolean = false;

    /**
     * app available languages
     *
     * @type {string<>}
     */
    public availableLangs: string[] = environment.availableLangs;

    /**
     * is translation tool visible
     *
     * @type {boolean}
     */
    public translationMode: boolean = false;

    public eventInChange: boolean = false;

    // TODO: this is for stoping pending requests (with event_id or selected_participant)
    public activeSubscriptions: any[] = [];

    /**
     * limit for open-search database records
     *
     * @type {number}
     */
    public openSearchLimit: number = 1000;

    /**
     * url prefix for page links (images/files)
     */
    public baseLinkPath = environment.assets;

    /**
     * api prefix path
     */
    public apiPrefix = '/api/v2';

    /**
     * The wakeLock PWA promise
     *
     * @type WakeLock
     */
    public wakeLock = null;

    public chatInit: boolean = false;

    public dashboardInit: boolean = false;

    public noTabsPages = [
        'lost-password', 'set-password', 'register', 'login', 'toc', 'credentials', 'credential', 'mail-setting', 'deeplink', 'unsubscribe'
    ];

    public APP_ROUTES = [
        '',
        'set-password',
        'register',
        'login',
        'mail-setting',
        'home/info/matching',
        'home/marketplace/list',
        'home/attendees/list',
        'appointments',
        'messages',
        'messages/broadcast',
        'credentials-wallet',
        'keywords/matching'
    ];

    /**
     * constructor
     *
     * @param calendar
     * @param platform
     * @param menu
     * @param router
     * @param translate
     * @param connectionService
     * @param overlayService
     * @param auth
     * @param plt
     * @param storage
     */
    constructor(
        private ngZone: NgZone,
        private calendar: Calendar,
        private insomnia: Insomnia,
        // private transfer: FileTransfer,
        // private fs: File,
        private platform: Platform,
        private menu: MenuController,
        public router: Router,
        public translate: TranslateService,
        public connectionService: ConnectionService,
        public overlayService: OverlayService,
        public auth: AuthenticationService,
        public storage: StorageService,
        public appEvents: Events
    ) {

        // read available langs from config

        this.platform.ready().then(() => {
            if (this.platform.is('cordova')) {
                this.systemVersion = parseInt(device.version);
                // this.fileTransfer = this.transfer.create();
                // using cordova plugin cordova-plugin-network-information
                this.isConnected = navigator.connection.type != Connection.NONE;
                // directly trigger app online event
                if (this.isConnected) {
                    window.dispatchEvent(new Event('apponline'));
                }
                window.addEventListener('online', () => {
                    this.isConnected = true;
                    this.ngZone.run(() => {
                        this.status = "ONLINE";
                        // trigger global event
                        window.dispatchEvent(new Event('apponline'));
                    });
                    // init branch in case app was started in offline mode
                    this.branchInit();
                });
                window.addEventListener('offline', () => {
                    // redirect user only when user is logged in and data have been already loaded
                    if (this.isConnected && localStorage.getItem('token')) {
                        this.router.navigate([this.defaultLink + '/home/detail/event']);
                    }
                    this.isConnected = false;
                    this.ngZone.run(() => {
                        this.status = "OFFLINE";
                        // trigger global event
                        window.dispatchEvent(new Event('appoffline'));
                    });
                });

                this.setupScreenSize();

            } else {

                // NOTE[jg] use https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
                const options: ConnectionServiceOptions = {
                    enableHeartbeat: false,
                    // heartbeatUrl: 'https://localhost:4000',
                    // heartbeatInterval: 2000
                }

                this.connectionService.monitor(options).pipe(
                    tap((newState: ConnectionState) => {
                        this.isConnected = newState.hasNetworkConnection;
                        if (this.isConnected) {
                            this.ngZone.run(() => {
                                this.status = "ONLINE";
                                // trigger global event
                                window.dispatchEvent(new Event('apponline'));
                            });
                        }
                        else {
                            this.ngZone.run(() => {
                                this.status = "OFFLINE";
                                // trigger global event
                                window.dispatchEvent(new Event('appoffline'));
                            });
                            // redirect user only when user is logged in
                            if (localStorage.getItem('token')) {
                                this.router.navigate([this.defaultLink + '/home/detail/event']);
                            }
                        }
                        log('info', 'Network:', newState);
                    })
                ).subscribe(() => {

                    // wait for connection status before setting screen params and redirecting pages
                    this.setupScreenSize();
                });
            }
        });

        // on pause
        this.platform.pause.subscribe(() => {
            this.pause = true;
            log('info', 'pause');
        });

        // on resume
        this.platform.resume.subscribe(() => {
            this.pause = false;

            log('info', 'resume');

            if (this.reloadable) {

                log('info', 'reload');
                if (this.is('cordova')) {
                    this.branchInit();
                }
                // TODO
                //this.pushPageService.reload();
            }
        });

        // this VisualViewport is now working properly only on iOS devices for onresize...
        if (window['VisualViewport'] && this.platform.is('ios')) {
            // TODO[jg] v5 - check resizing on iOS
            window['VisualViewport']['onresize'] = () => {
                this.updateScreenSize();
            };
        } else {
            // on resize detect and change resolution
            window.onresize = () => {
                this.updateScreenSize();
            };
        }

        if (screen?.orientation) {
            screen.orientation.addEventListener("change", (event) => {
                this.updateScreenSize();
            });
        } else {
            window.addEventListener("orientationchange", (event) => {
                this.updateScreenSize();
            });
        }

        if (environment.sameSiteAssets) {
            if (this.is('cordova')) {
                this.baseLinkPath = environment.pwa;
            } else {
                this.baseLinkPath = location.origin;
            }
        }
    }

    updateScreenSize() {
        // put there some time to have better results on mobile devices
        setTimeout(() => {
            this.setupScreenSize(false);
        }, 200);
        // add delay to wait until keyword is shown
        // check it every 50ms for next 2000ms to be sure about final size
        for (let i = 1; i <= 40; i++) {
            let delay = i * 50;
            setTimeout(() => {
                this.refreshAppHeight();
            }, delay);
        }
    }

    /*
     * in case there is no automatic onresize method for platform,
     * we trigger it manually after focus/blur on input element
     *
     */
    checkResize(scroll: boolean = false, $event = null, autoScroll: string = 'nearest') {
        // solving bug with not properly showing modal on iOS which allow scroll
        if (this.platform.is('ios') && !this.platform.is('cordova')) {
            scroll = true;
            window.scrollTo(0, 0)
            document.body.scrollTop = 0;
        }
        // add delay to wait until keyword is shown
        // check it every 50ms for next 700ms to be sure about final size
        for (let i = 1; i <= 40; i++) {
            let delay = i * 50;
            setTimeout(() => {
                this.refreshAppHeight(scroll);
            }, delay);
        }

        // call interaction for input to scroll him into visible area
        // scroll only on small screens
        if ($event && this.sizeSm) {
            setTimeout(() => {
                $event.target.scrollIntoView({ behavior: "smooth", block: autoScroll });
            }, 250);
            setTimeout(() => {
                $event.target.scrollIntoView({ behavior: "smooth", block: autoScroll });
            }, 500);
        }
    }

    /*
     * refresh app height variable by actual state of screen
     * used for keywboard changes on mobile
     *
     */
    refreshAppHeight(scroll = true) {
        let height = window.innerHeight;
        let width = window.document.body.clientWidth;

        if (window['visualViewport'] && window['visualViewport'].height) {
            height = window['visualViewport'].height;
        }

        if (window['visualViewport'] && window['visualViewport'].width) {
            width = window['visualViewport'].width;
        }

        let bodyHeight = parseInt(document.querySelector('body').style.height);

        // if there is some hegiht stored in body, use it if it is lower then window size...
        if (bodyHeight > 0 && bodyHeight < height) {
            height = bodyHeight;
        }

        let bodyWidth = parseInt(document.querySelector('body').style.width);

        // if there is some width stored in body, use it if it is lower then window size...
        if (bodyWidth > 0 && bodyWidth < width) {
            width = bodyWidth;
        }

        const doc = document.documentElement;

        // make changes only if they are needed
        if (this.appHeight != height) {

            this.appHeight = height;

            doc.style.setProperty('--app-height', `calc(${height}px - var(--ion-safe-area-top,0px) - var(--ion-safe-area-bottom,0px))`)
        }

        if (this.appWidth != width) {

            this.appWidth = width;

            doc.style.setProperty('--app-width', `calc(${width}px)`)
        }

        // scroll main window to top
        if (scroll) {
            setTimeout(() => {
                window.scrollTo(0, 0)
                document.body.scrollTop = 0;
            }, 20);
        }
    }

    /*
     *  Setup screen size properties
     *
     */
    setupScreenSize(first = true, size = null) {
        let width = this.platform.width();
        if (size) {
            width = size;
        }
        this.sizeXl = false;
        this.sizeLg = false;
        this.sizeMd = false;
        this.sizeMs = false;
        this.sizeSm = false;
        this.tabMenu = false;
        let linkChanged = false;
        this.attendeesPerLine = 5;

        if (width >= 1366) {
            this.sizeXl = true;
        }

        if (width < 1366) {
            // if we are switching to tabs, we need to change route
            if ((this.defaultLink == '' && !first) || !this.isUrl('tabs', true)) {
                linkChanged = true;
            }
            this.sizeLg = true;
            this.defaultLink = '/tabs';
            this.tabMenu = true;
        } else {
            // if we are switching to desktop view, we need to change route
            if ((this.defaultLink == '/tabs' && !first) || this.isUrl('tabs', true)) {
                linkChanged = true;
            }
            this.defaultLink = '';
        }

        if (width <= 1024) {
            this.sizeMd = true;
        }

        if (width < 1024) {
            this.attendeesPerLine = 4;
        }

        if (width <= 768) {
            this.sizeMs = true;
        }

        if (width <= 736) {
            this.sizeSm = true;
        }

        if (width < 736) {
            this.attendeesPerLine = 3;
        }

        if (width < 620) {
            this.attendeesPerLine = 2;
        }

        // refresh css variable
        // check it every 50ms for next 700ms to be sure about final size
        for (let i = 1; i <= 40; i++) {
            let delay = i * 50;
            setTimeout(() => {
                this.refreshAppHeight(false);
            }, delay);
        }

        // if we switch from desktop to tab view, we need to change current route
        if (linkChanged && localStorage.getItem('token')) {
            if (this.isConnected) {
                // keep same site
                let tabRoutes = [
                    '/messages',
                    // '/messages/broadcast',
                    // '/messages/channels',
                    // '/messages/direct/',
                    '/home/',
                    '/appointments',
                    '/ask-the-speaker',
                    '/edit-profile',
                    '/setting',
                    'event-key',
                    'access-key',
                    '/keywords/',
                    '/credentials-wallet'
                ];

                let tabSite = false;
                let path = window.location.pathname;

                let urlParams = new URLSearchParams(window.location.search);

                let queryParams = [...urlParams].reduce((o, i) => ({ ...o, [i[0]]: i[1] }), {});

                let softRefresh: boolean = false;

                tabRoutes.forEach((tabRoute) => {
                    if (path.indexOf(tabRoute) != -1) {
                        tabSite = true;
                    }
                });

                // check if site allows redirect to tab version...
                let noRedirectPage = [
                    'credential/'
                ].filter((route) => {
                    return path.indexOf(route)
                }).length > 0;

                if (window.location.search) {
                    if (
                        urlParams.get('call')
                        || urlParams.get('video_link')
                        || urlParams.get('profile_id')
                        || urlParams.get('newsfeed_id')
                        || urlParams.get('post_id')
                        || urlParams.get('voting_id')
                    ) {
                        softRefresh = true;
                    }
                }

                if (tabSite) {
                    if (!softRefresh) {
                        this.router.navigate([this.defaultLink + path.replace('/tabs', '')], {
                            queryParams: queryParams
                        });
                    }
                } else {
                    if (!noRedirectPage) {
                        this.router.navigate([this.defaultLink + '/home/info/matching']);
                    }
                }
            } else {
                this.router.navigate([this.defaultLink + '/home/detail/event']);
            }
        }
    }

    /**
      * show side menu with events, this is on left side
      */
    openEventMenu() {
        this.menu.open('start');
    }

    /**
      * mark appointments notifications as readed
      *
      * @param count
      */
    readAppointmentNotification(count: number = 1) {
        this.unreadAppointments -= count;
        if (this.unreadAppointments < 0) {
            this.unreadAppointments = 0;
        }
    }

    /**
      * mark notifications as readed
      *
      * @param count
      */
    readMessageNotification(count: number) {
        this.unreadMessages -= count;
        if (this.unreadMessages < 0) {
            this.unreadMessages = 0;
        }
    }

    /**
      * test if it is required platform
      *
      * @param platform
      */
    resetNotifications() {
        this.unreadMessages = 0;
        this.unreadAppointments = 0;
        this.unreadOtherCount = 0;
        this.unreadOther = {};
    }

    readChatGroupMessageNotification(count: number) {
        this.unreadChatGroups -= count;
        if (this.unreadChatGroups < 0) {
            this.unreadChatGroups = 0;
        }
    }

    /**
      * test if it is required platform
      *
      * @param platform
      */
    is(platform): boolean {
        return this.platform.is(platform);
    }

    isUrl(urlToCheck: string, prefix: boolean = false, path: string = null) {

        if (!path) {
            path = window.location.pathname;
        }

        // remove slashs
        urlToCheck = urlToCheck.replace(/\//g, "");
        path = path.replace(/\//g, "");

        path = path.split('?')[0];
        // path = path.split('/')[0];

        if (urlToCheck == path || (prefix && path.startsWith(urlToCheck))) {
            return true;
        } else {
            return false;
        }
    }

    public navigate(link: string, skipSameLink: boolean = true, path: string = null) {
        if (!path) {
            path = window.location.pathname;
        }
        if (link != path || !skipSameLink) {
            this.router.navigate([link]);
        }
    }

    // Branch initialization
    public branchInit(init: boolean = false) {

        const Branch = window["Branch"];

        log('info', 'Branch:', Branch.sessionInitialized);

        // only on devices
        if (!this.platform.is("cordova")) {
            return;
        }

        // store info about branch init, otherwise it will fail next time for reinitin seesion
        // Warning. Session initialization already happened. To force a new session, set intent extra, "branch_force_new_session"
        // sessionStorage.setItem('branchInitialized', '1');

        Branch.initSession().then(
            (data) => {
                // read deep link data on click
                log('info', 'Deep Link Data: ' + JSON.stringify(data));
                localStorage.setItem('Deeplink', 'Deep Link Data: ' + JSON.stringify(data));

                // change translation by force locale attribute
                if (data.hasOwnProperty('forceLocale')) {
                    this.translate.use(data['forceLocale']);
                }

                // store invitation token for registration
                if (data.hasOwnProperty('invitation_token')) {
                    this.storage.set('invitation_token', data['invitation_token']);
                }

                // store password reset token
                if (data.hasOwnProperty('token')) {
                    sessionStorage.setItem('password_token', data['token']);
                    sessionStorage.setItem('password_email', data['email']);
                }

                if (data.hasOwnProperty('activate_token')) {
                    this.auth.registerConfirmation(data['activate_token'], 'participant')
                        .subscribe(
                            success => {
                                this.storage.remove('invitation_token');
                                let toast = this.overlayService.showInfo(success.message);;
                                this.router.navigate(['/login'], { queryParams: { email: success.email } });
                            },
                            error => {
                                this.overlayService.showWarning(error.error.message);
                            });
                } else {

                    let link = data['+non_branch_link'] || data['~referring_link'];
                    if (link) {
                        // process deeplinks without branch.io, remove deeplink url
                        let queryParams = '?' + link.split("?")[1];
                        setTimeout(() => {
                            this.applyDeepLink(queryParams);
                        }, init ? 2000 : 0);
                    } else {
                        if (data.hasOwnProperty('r_id') && this.APP_ROUTES[data['r_id']]) {
                            this.router.navigate(['/' + this.APP_ROUTES[data['r_id']]])
                        } else {
                            if (data.hasOwnProperty('r')) {
                                this.router.navigate(['/' + data['r']]);
                            } else {
                                // fallback for old links...
                                if (data.hasOwnProperty('route')) {
                                    this.router.navigate(['/' + data['route']]);
                                }
                            }
                        }
                    }
                }

                // not needed as all deeplinks will be handled via method applyDeepLink
                // if (data["+clicked_branch_link"]) {

                //     // redirect with deep link
                //     if (data.hasOwnProperty('route')) {
                //         let redirect = data['route'];
                //         switch (redirect) {
                //             case 'register':
                //                 setTimeout(() => {
                //                     this.router.navigate(['/register']);
                //                 }, 500);
                //                 break;
                //             case 'set-password':
                //                 // just to be sure setRootPage will not overide redirect...
                //                 setTimeout(() => {
                //                     this.router.navigate(['/set-password']);
                //                 }, 500);
                //                 break;
                //         }
                //     } else {
                //         if (data.hasOwnProperty('invitation_token')) {
                //             setTimeout(() => {
                //                 this.router.navigate(['/login'], { queryParams: { invitation_token: data['invitation_token'] } });
                //             }, 500);
                //         }
                //     }
                // }
            }).catch(function error(err) {
                // NOTE[jg]
                log('error', err);
            });
    }

    public applyDeepLink(url) {
        // redirect to proper page
        let urlParams = new URLSearchParams(url);

        let route = '';

        // based on query param with name route or r_id (avoid anonymizing link problems)
        if (urlParams.get('r_id')) {
            // console.info(urlParams.get('r_id'));
            route = this.APP_ROUTES[urlParams.get('r_id')];

        } else {
            route = urlParams.get('r');
        }

        // fallback for old links with attribute route
        if (!route) {
            route = urlParams.get('route');
        }
        let eventId;

        let params = {};
        // transfer all other query params to redirect to keep link working properly
        // show specific event, post...
        for (const [key, value] of urlParams.entries()) {
            if (key !== 'r' && key !== 'route' && key !== 'r_id') {
                params[key] = value;
            }

            if (key == 'event_id') {
                eventId = params[key];
            }
        }

        // check if page is withing tabs, for mobile view to add profile prefix
        let path = (this.noTabsPages.indexOf(route) == -1 ? this.defaultLink : '') + '/' + route;

        // this.router.navigate([path], {
        //     replaceUrl: false,
        //     queryParams: params
        // });

        if (!this.is('cordova')) {
            document.location.href = path + '?' + urlParams.toString();
            // this.router.navigateByUrl(path + '?' + urlParams.toString(), {skipLocationChange: true});
        } else {
            if (localStorage.getItem('token')) {
                this.appEvents.publish('deeplink:open', eventId, path, { queryParams: params });
            } else {
                // in case of public page (reset password, activation page...), make redirect with params
                if (this.noTabsPages.indexOf(path.replace('/', '')) != -1) {
                    this.router.navigateByUrl(path + '?' + urlParams.toString());
                } else {
                    // otherwise wait for login and then make a redirect
                    this.saveStartUrl(path + '?' + urlParams.toString());
                }
            }
        }
    }

    /**
      * open pdf
      *
      * @param url
      */
    openPDFviaGoogleDocs(url) {
        window.open("https://docs.google.com/viewer?url=" + url, '_system');
    }

    /**
     * open external HTML page
     *
     * @param customLabel
     */
    public showExternalLink(block, userLang, defaultLang?) {

        this.platform.ready().then(() => {
            let url = block.customLabel.translate(userLang, defaultLang).external_link;

            // use google docs viewer if some document
            if (url.match(/^.*\.(doc|docx|xls|xlsx|pdf|txt)$/)) {
                this.openPDFviaGoogleDocs(url);
            } else {
                if (this.platform.is('cordova')) {
                    // otherwise use new browser window
                    let name = '';

                    if (block.customLabel.translate(userLang).name) {
                        name = block.customLabel.translate(userLang).name;
                    } else {
                        name = this.translate.instant(block.title);
                    }

                    this.openInAppBrowser(url, name, true);
                } else {
                    window.open(url, '_blank');
                }
            }
        });
    }

    /**
     * open external HTML page in InAppBrowser
     *
     * @param url
     * @param name
     */
    openInAppBrowser(url, name = '', showHeader: boolean = false, showBack: boolean = true, target = "_blank") {

        // NOTE[jg] can be used if we want to have app loading...
        // show loading before everyting is ready
        // this.overlayService.showLoading();

        // let target = "_blank";

        let options =
            'location=no,hidden=no,clearcache=yes,clearcache=yes,clearsessioncache=yes'
            + ',zoom=no,hardwareback=yes,mediaPlaybackRequiresUserAction=no,footer=' + (showBack ? 'yes' : 'no') +
            + 'shouldPauseOnSuspend=no,disallowoverscroll=no,toolbar=' + (showBack ? 'yes' : 'no') + ','
            + 'enableViewportScale=no,allowInlineMediaPlayback=no,presentationstyle=pagesheet'
            + 'fullscreen=yes' + (showBack ? (',closebuttoncaption=' + this.translate.instant('EXTERNAL_HTML_BACK_BUTTON')) : '')
            ;

        let scriptAfterLoading = "\
              theParent = document.querySelector(\"body\");\
              exits = document.getElementById(\"congreet-header\");\
              if (exits == null) {\
              theKid = document.createElement(\"button\");\
              theKid.setAttribute(\"id\", \"congreet-header\");\
              theKid.innerHTML = '" + name + "';\
              theKid.style.width = \"100vw\";\
              theKid.style.minHeight = \"60px\";\
              theKid.style.margin = \"0px\";\
              theKid.style.fontSize = \"18px\";\
              theKid.style.background = \"#F8F8F8\";\
              theKid.style.color = \"#000000\";\
              theKid.style.top = \"0\";\
              theKid.style.left = \"0\";\
              theKid.style.left = \"0\";\
              theKid.style.border = \"none\";\
              theKid.style.borderBottom = \"1px solid #e7e7e7\";\
              theParent.style.padding=\"0\";\
              theKid.onclick = () => {window.location.href=\"mobile/close\";};\
              theParent.appendChild(theKid);\
              theParent.insertBefore(theKid, theParent.firstChild);\
              }\
              ";

        this.platform.ready().then(() => {
            this.inAppBrowserRef = cordova.InAppBrowser.open(url, target, options);

            this.inAppBrowserRef.addEventListener('loadstart', (event) => {
                if (event.url.match("mobile/close")) {
                    this.inAppBrowserRef.close();
                }
                //console.info('loadstop', event);
            });

            this.inAppBrowserRef.addEventListener('loadstop', (event) => {
                //browser.insertCSS({ code: "body{color: red;" });
                if (showHeader) {
                    this.inAppBrowserRef.executeScript({ code: scriptAfterLoading });
                }

                // NOTE[jg] can be used if we want to have app loading...
                // give there some time to finish page init
                // setTimeout(() => {
                //     this.overlayService.hideLoading();
                //     this.inAppBrowserRef.show();
                // }, 500);

            });

            this.inAppBrowserRef.addEventListener('loaderror', (event) => {
                //console.info('loaderror', event);
                //this.inAppBrowserRef.close();
                this.overlayService.hideLoading();
            });

            this.inAppBrowserRef.addEventListener('message', (event) => {
                //console.info('message', event);
            });
        });
    }

    /**
     * store appointment to system calendar for Android/iOS
     *
     * @param {Appointment} appointment
     * @param {number} participantId
     */
    public addToCalendar(appointment, participantId) {
        // check permission to add events into calendar
        this.calendar.hasReadWritePermission().then(access => {
            console.info('Calendar permission: ' + access);
            // if access to calendar is allowed
            if (!access) {
                this.overlayService.showError(this.translate.instant("MESSAGE_PLEASE_ALLOW_CALENDAR"));
                // we need to try this again, because when app will do relad, this will be lost...
                this.calendar.requestReadWritePermission().then((success) => {
                    if (success) {
                        this.createEvent(appointment, participantId);
                    } else {
                        this.overlayService.showError(this.translate.instant("MESSAGE_PLEASE_ALLOW_CALENDAR"));
                    }
                }).catch(e => {
                    console.warn(e);
                    this.overlayService.showError(e);
                });
            } else {
                this.createEvent(appointment, participantId);
            }
        });
    }

    /**
     * generate appointment to system calendar for Android/iOS
     *
     * @param {Appointment} appointment
     * @param {number} participantId
     */
    public createEvent(appointment, participantId) {

        let notes = '';
        if (appointment.participants) {
            let conversationParticipant = appointment.participants.filter(participant => {
                return participant.id !== participantId;
            })[0];

            let notes = conversationParticipant.firstname + ' ' + conversationParticipant.lastname;//eventDetail.name;
            if (conversationParticipant.email) {
                notes = notes + ' - ' + conversationParticipant.email;
            }
        }

        if (appointment.status == 4) {
            notes = this.translate.instant('APPOINTMENTS_PERSONAL_APPOINTMENT');
        }

        const title = appointment.description;
        const location = appointment.location;
        // TODO[jg] + '+0200' - for timezone offset in germany, this should be in database, or everything needs to be in UTC and convert to users local time..
        const startDate = new Date(appointment.starts_at.replace(' ', 'T') + this.timezoneOffset); //new Date(2017, 11, 30, 13, 0, 0);
        const endDate = new Date(appointment.starts_at.replace(' ', 'T') + this.timezoneOffset); // NOTE[jg] it is optional, but should match end_date in ics...
        // TODO[jg] - sync this setting with backend somehow - app config about meeting delay...
        endDate.setMinutes(endDate.getMinutes() + 30);

        this.calendar.createEventInteractively(title, location, notes, startDate, endDate).then((result) => {
            console.info('Adding into calendar:')
            console.info(result);
        }).catch(e => {
            console.warn(e);
            this.overlayService.showError(e);
        });
    }

    /**
     * set notifications for system
     *
     * @param {Feed[]} feeds
     */
    setDashboardFeeds(feeds: Feed[]) {
        this.dashboardFeeds = feeds.map(feed => new Feed(feed));
        this.dashboardFeedsNotVisited = this.dashboardFeeds.filter((item) => { return !item.visited_at }).slice(0, 3);
        this.dashboardFeedsVisited = this.dashboardFeeds.filter((item) => { return item.visited_at }).slice(0, 3 - this.dashboardFeedsNotVisited.length);

    }

    // unsubscribe pending subscriptions
    public unsubscribeRequests() {
        this.activeSubscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        this.activeSubscriptions = [];
    }

    /**
     * sign link with signature for cloudfront assets
     *
     * @param {string} link
     * @return {string}
     */
    public createSignedLink(link: string, original: boolean = false, clearSigning: boolean = false): string {


        if (!isValidUrl(link)) {
            return '';
        }

        let url = new URL(link);

        if (clearSigning) {
            // remove signing attributes from links
            url.searchParams.delete('Key-Pair-Id');
            url.searchParams.delete('Expires');
            url.searchParams.delete('Signature');
            link = link.split('?')[0] + '?' + url.searchParams.toString();
        }

        // add support for custom same site assets links
        if (!this.is('cordova') && link && environment.sameSiteAssets && !original) {


            // replace domain origin in link with current domain
            link = link.replace(url.origin, window.location.origin);

            // use same site sign attributes, so remove original signing
            // to be able to include proper signing, because we changed URL
            // and original siging is not valid
            if (link.search('Key-Pair-Id') != -1) {
                link = link.split('?')[0];
            }

            return link;
        }

        if (this.is('cordova') && link && link.search('Key-Pair-Id') == -1) {
            // sign only links in native app..

            // get proper asset link, replace asset domain when not using correct one
            // for signature


            // replace domain in link with current domain
            link = link.replace(url.origin, this.auth.signedUrlDomain ? this.auth.signedUrlDomain : environment.assets);

            return link + '?' + this.auth.signedUrlParams;
        } else {
            return link;
        }
    }

    /**
     * remove cloudfront signing attributes from link
     *
     * @param {string} link
     * @return {string}
     */
    public extractLinkFromSigned(link: string): string {

        // add support for custom same site assets links
        if (link) {
            if (link.search('Key-Pair-Id') != -1) {
                link = link.split('?')[0];
            }
        }

        return link
    }

    /**
     * store init URL of app
     *
     * @param {string} url
     */
    public saveStartUrl(url: string, force: boolean = false): void {
        // add default link as redirect links are desktop links
        if (force) {
            localStorage.setItem('start-url', url);
        } else {
            localStorage.setItem('start-url', (!url.startsWith(this.defaultLink) ? this.defaultLink : '') + url);
        }
    }

    /**
     * store init URL of app
     *
     */
    public restoreStartUrl(): void {
        let startUrl = localStorage.getItem('start-url');
        if (startUrl) {
            localStorage.removeItem('start-url');
            this.router.navigateByUrl(startUrl);
        } else {
            this.router.navigate([this.defaultLink + '/home/info/matching'], { replaceUrl: true });
        }
    }

    /**
     * copay string to users clipboard
     *
     */
    copyToClipboard(content: string): void {
        const el = document.createElement('textarea');
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    /**
     * fucntion to open modal with download dialog
     */
    public async downloadFile(file: string, name: string) {

        if (this.is('cordova')) {
            try {
                let response = await fetch(file);
                let blob = await response.blob();
                let uri = await cordova.plugins.saveDialog.saveFile(blob, name);
                console.info("The file has been successfully saved to", uri);
            } catch (e) {
                console.error(e);
            }
        } else {
            // Create a link and set the URL using `createObjectURL` in case of blob file
            // otherwise just file link
            const link = document.createElement("a");
            link.style.display = "none";
            // now it is ngsw-bypass part of signed link from backend
            if (file.includes('ngsw-bypass')) {
                link.href = file;
            } else {
                link.href = file + (file.includes('?') ? '&' : '?') + 'ngsw-bypass=true';
            }

            link.target = '_blank';
            link.download = name;

            // It needs to be added to the DOM so it can be clicked
            document.body.appendChild(link);
            link.click();

            // To make this work on Firefox we need to wait
            // a little while before removing it.
            setTimeout(() => {
                URL.revokeObjectURL(link.href);
                link.parentNode.removeChild(link);
            }, 0);
        }
        // donwload file to app folder...
        // this.fileTransfer.download(file, this.fs.documentsDirectory + name).then(
        //     (entry) => {
        //         log('info', 'download complete: ' + entry.toURL());
        //         this.overlayService.showInfo(this.translate.instant('MESSAGES_DOWNLOAD_COMPLETED'));
        //     }, (error) => {
        //         // handle error
        //         console.warn(error)
        //         this.overlayService.showError(this.translate.instant('MESSAGES_DOWNLOAD_ERROR'));
        //     });
        // + https://www.npmjs.com/package/cordova-plugin-file-opener2 ??
    }


    /**
     * fucntion to download file
     * 
     * Dynamically create a File
     * const myFile = new File([`${new Date()}: Meow!`], "my-cat.txt");
     * 
     * Download it using our function
     * downloadFile(myFile);
     */
    public downloadFileBlob(file) {
        // Create a link and set the URL using `createObjectURL`
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = URL.createObjectURL(file);
        link.download = file.name;

        // It needs to be added to the DOM so it can be clicked
        document.body.appendChild(link);
        link.click();

        // To make this work on Firefox we need to wait
        // a little while before removing it.
        setTimeout(() => {
            URL.revokeObjectURL(link.href);
            link.parentNode.removeChild(link);
        }, 0);
    }

    /**
     * fucntion to keep screen enabled to prevent device sleep
     */
    public async keepAwake() {
        if (this.is('cordova')) {
            this.insomnia.keepAwake();
        } else {
            if ('wakeLock' in navigator) {
                // create an async function to request a wake lock
                try {
                    this.wakeLock = await navigator.wakeLock.request('screen');
                } catch (err) {
                    // The Wake Lock request has failed - usually system related, such as battery.
                }
            }
        }
    }

    /**
     * fucntion to allow device to put device to sleep
     */
    public async allowSleepAgain() {
        if (this.is('cordova')) {
            this.insomnia.allowSleepAgain();
        } else {
            if (this.wakeLock) {
                this.wakeLock.release()
                    .then(() => {
                        this.wakeLock = null;
                    });
            }
        }
    }

    /**
     * function to check device orientation and screen mode
     */
    public checkOrientationChange(force: boolean = false, lock: boolean = true) {
        // on small mobile devices
        if (this.sizeSm) {
            // NOTE[jg]  force solution works only after users action for security reaseon
            // so needed to connect some button click event and so on...
            if (force) {
                let screenOrientation = window.orientation;
                switch (screenOrientation) {
                    case 0: log('info', 'you are in portrait-primary mode');
                        // remove fullscreen if in browser and not installed PWA
                        if (!('standalone' in window.navigator) && !(window.navigator['standalone'])) {
                            if (!!document.fullscreenElement) {
                                document
                                    .exitFullscreen()
                                    .then(() => log('info', 'Document Exited from Full screen mode'))
                                    .catch((err) => log('error', err));
                            }
                        }
                        break;
                    case 90: this.goFullScreen();
                        break;
                    case 180: this.goFullScreen();
                        break;
                    case 270: this.goFullScreen();
                        break;
                    default: log('info', 'implementation of screen orientation');
                }
            } else {
                // on mobile devices
                if (lock) {
                    // only lock for fullscreen, otherwise it is not working
                    if (!!document.fullscreenElement) {
                        this.lockScreenOrientation();
                    }
                }
            }
        }
    }

    /**
     * function to request full screen of device browser
     */
    public goFullScreen() {
        var elem = document.documentElement;
        if (!!elem['requestFullscreen']) {
            elem.requestFullscreen().then(data => {
                this.lockScreenOrientation();
            }, err => {
                log('info', 'no full screen');
            });
        } else if (!!elem['mozRequestFullScreen']) { /* Firefox */
            elem['mozRequestFullScreen']().then(data => {
                this.lockScreenOrientation();
            }, err => {
                log('info', 'Full Screen request failed');
            });
        } else if (!!elem['webkitRequestFullscreen']) { /* Chrome, Safari & Opera */
            elem['webkitRequestFullscreen']().then(data => {
                this.lockScreenOrientation();
            }, err => {
                log('info', 'Full Screen request failed');
            });
        } else if (!!elem['msRequestFullscreen']) { /* IE/Edge */
            elem['msRequestFullscreen']().then(data => {
                this.lockScreenOrientation();
            }, err => {
                log('info', 'Full Screen request failed');
            });
        }
    }

    /**
     * function to lock the screen. in this case the screen will be locked in portrait-primary mode.
     */
    public lockScreenOrientation() {
        try {
            screen.orientation.lock('portrait');
        } catch (err) {
            // not supported...
        }
        // screen.lockOrientationUniversal = screen.lockOrientation || screen.mozLockOrientation || screen.msLockOrientation;

        // if (screen.lockOrientationUniversal("landscape-primary")) {
        //     // Orientation was locked
        // } else {
        //     // Orientation lock failed
        // }
    }

    /**
     * share URL via social network
     */
    public shareLink(type: string, share: { link: string, subject: string, text: string }) {

        // copy original link
        let link = share.link;

        // encode attribures for usage as query parameters in URL
        share.link = encodeURIComponent(share.link);
        share.subject = encodeURIComponent(share.subject);
        share.text = encodeURIComponent(share.text);

        let windowSetting = 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=500';
        let target = 'targetWindow';

        if (this.sizeMd) {
            windowSetting = 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes';
        }

        if (this.is('cordova')) {
            target = '_system';
            windowSetting = 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes';
        }

        switch (type) {
            case 'facebook':
                window.open('https://www.facebook.com/sharer/sharer.php?u=' + share.link, target, windowSetting);
                break;
            case 'twitter':
                window.open('https://twitter.com/intent/tweet?url=' + share.link + '&text=' + share.text, target, windowSetting);
                break;
            case 'linkedin':
                window.open('https://www.linkedin.com/shareArticle/?url=' + share.link + '&title=' + share.subject + '&summary=' + share.text, target, windowSetting);
                break;
            case 'google':
                window.open('https://plus.google.com/share?url=' + share.link, target, windowSetting);
                break;
            case 'whatsapp':
                window.open('https://api.whatsapp.com/send?phone=&text=' + share.text + '%0d%0a' + share.link + '&source=&data=', target, windowSetting);
                break;
            case 'xing':
                window.open('https://www.xing.com/spi/shares/new?url=' + share.link, target, windowSetting);
                break;
            case 'email':
                if (this.is('android')) {
                    // for cordova use system on android devices
                    if (this.is('cordova')) {
                        window.open('mailto:?subject=' + share.subject + '&body=' + share.text + encodeURI('\n\n') + share.link, '_system');
                    } else {
                        window.open('mailto:?subject=' + share.subject + '&body=' + share.text + encodeURI('\n\n') + share.link, '_top');
                    }
                } else {
                    window.open('mailto:?subject=' + share.subject + '&body=' + share.text + encodeURI('\n\n') + share.link, '_system');
                }
                break;
            case 'copy':
                this.copyToClipboard(link);
                this.overlayService.showSuccess(this.translate.instant('LINK_COPIED'));
                break;
        }
    }

    resetUnread() {
        this.unreadMessages = 0;
        this.unreadChatGroups = 0;
        this.unreadNotifications = 0;
        this.unreadAppointments = 0;
        this.unreadOtherCount = 0;
    }
}
