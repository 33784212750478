import { MarketplaceContainerTranslation } from "./marketplace-container-translation";
import { MarketplaceKeyword } from "./marketplace-keyword";
import * as moment from "moment";
import { Translatable } from "./translatable.interface";

export class MarketplaceContainer implements Translatable {
    id: number;
    marketplace_id: number;
    updated_at: string;
    date_updated_at: Date = new Date();
    created_at: string;
    date_created_at: Date = new Date();

    keywords: MarketplaceKeyword[] = [];
    translations: MarketplaceContainerTranslation[] = [];

    tempId: number = parseInt((Math.random() + 1).toString().replace('.', ''), 0);
    duplicatedKeyword = false;

    // version validation
    new_values: any;

    // it container open when in collapsible mode
    open: boolean = false;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: MarketplaceContainer) {
        if (!!init) {
            Object.assign(this, init);
            this.translations = this.translations.map(t => new MarketplaceContainerTranslation(t));

            if (this.updated_at) this.date_updated_at = moment.utc(this.updated_at).toDate();
            if (this.created_at) this.date_created_at = moment.utc(this.created_at).toDate();
        }

        // initiate containers
        this.keywords = this.keywords.map(keyword => new MarketplaceKeyword(keyword));
    }

    /**
     * get translated attributes
     *
     * @param locale
     *
     * @return {MarketplaceContainerTranslation}
     */
    public translateOrNew(locale: string) {
        let currentTranslation = this.translations.filter(translation => translation.locale === locale)[0];

        if (!currentTranslation) {
            currentTranslation = new MarketplaceContainerTranslation;
            currentTranslation.locale = locale;

            this.translations.push(currentTranslation);
        }

        return currentTranslation;
    }

    /**
     * translate
     *
     * @param locale
     * @param defaultLocale
     * @return {MarketplaceContainerTranslation}
     */
    public translate(locale: string, defaultLocale?: string) {
        return this.translations.filter(translation => { return translation.locale === locale; })[0] ||
            this.translations.filter(translation => { return translation.locale === defaultLocale; })[0] ||
            this.translations.filter(translation => { return translation.locale === 'de'; })[0] ||
            new MarketplaceContainerTranslation();
    }

    /**
     * Get the id or a temporary id as fallback, if no id is present
     *
     * @param fallback
     */
    public getId(fallback: boolean = false) {
        return this.id || (fallback ? this.tempId : this.id);
    }

    /**
     * Add keyword (only if it doesn't exists yet)
     *
     * @param value
     * @param editLang
     */
    public addKeyword(value: string, editLang: string, langs) {
        this.duplicatedKeyword = false;
        if (value.trim() !== '') {
            // check, if the keyword already exists
            for (let k = 0; k < this.keywords.length; k++) {
                if (this.keywords[k].translate(editLang).name === value.trim()) {
                    this.duplicatedKeyword = true;
                }
            }
            if (!this.duplicatedKeyword) {
                const keyword = new MarketplaceKeyword;

                keyword.translateOrNew(editLang).name = value.trim();

                langs.forEach((lang) => {
                    if (editLang != lang && !keyword.translate(lang).name) {
                        keyword.translateOrNew(lang).name = value.trim();
                    }
                });

                keyword.unsaved = true;
                this.keywords.push(keyword);
                this.orderKeywordsByName(editLang);
            }
        }
    }

    /**
     * Delete keyword
     *
     * @param id
     */
    public deleteKeyword(id: number) {
        for (let i = 0; i < this.keywords.length; i++) {
            if (this.keywords[i].getId(true) === id) {
                this.keywords.splice(i, 1);
            }
        }
    }

    /**
     * Order keywords by name
     */
    public orderKeywordsByName(lang) {
        this.keywords = this.keywords.sort(function (a, b) {
            const nameA = (a.translate(lang).name || '').toUpperCase(); // ignore upper and lowercase
            const nameB = (b.translate(lang).name || '').toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    /**
     * Removes a translation (to get not submitted if deactivated)
     *
     * @param locale
     */
    public removeTranslation(locale: string) {
        this.translations.forEach((translation, index) => {
            if (translation.locale === locale) {
                this.translations.splice(index, 1);
            }
        });

        this.keywords.forEach((keyword) => {
            keyword.removeTranslation(locale);
        });
    }

    /**
     * Check, if the container has at least one valid (in all available languages translated) keyword
     *
     * @param locales
     * @returns {boolean}
     */
    public hasTranslatedKeywordsInLocales(locales) {
        for (let i = 0; i < this.keywords.length; i++) {
            if (this.keywords[i].hasTranslationsInLocales(locales)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Check, if the container has suggested but unapproved keywords
     *
     * @returns {boolean}
     */
    public hasSuggestedUnapprovedKeywords() {
        for (let i = 0; i < this.keywords.length; i++) {
            if (this.keywords[i].participant_id && !this.keywords[i].approved_at) {
                return true;
            }
        }
        return false;
    }
}
