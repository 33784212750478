import { NgModule, Component, OnDestroy, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd, Scroll, RouterEvent } from '@angular/router';
import { Events } from '../../helpers/events';

// ionic
import { AlertController, ModalController } from '@ionic/angular';
import { PopoverController, Platform } from '@ionic/angular';

// other libraries
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

// rxjs
import { Subject, Subscription } from "rxjs";
import { take, takeUntil, debounceTime } from "rxjs/operators";

// components
import { SuggestKeywordComponent } from "../../components/suggest-keyword/suggest-keyword.component";

// providers
import { EventService } from "../../services/event.service";
import { UserService } from "../../services/user.service";
import { OverlayService } from "../../services/overlay.service";
import { PlatformService } from "../../services/platform.service";
import { ParticipantService } from "../../services/participant.service";
import { RouterExtService } from "../../services/router-ext.service";

// models
import { Event } from "../../models/event";
import { Keyword } from "../../models/keyword";
import { User } from "../../models/user";
import { Container } from "../../models/container";
import { Participant } from "../../models/participant";

import { log } from "../../helpers/log";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'keyword-settings',
    templateUrl: './keyword-settings.component.html',
    styleUrls: ['./keyword-settings.component.scss']
})
export class KeywordSettingsComponent implements OnInit, OnDestroy {

    /**
      * Is form used as part of profile wizard?
      *
      * @type boolean
      */
    @Input() wizard: boolean = false;

    /**
     * Load event data
     *
     * @type boolean
     */
    @Input() loadEvent: boolean = false;

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();


    /**
    * subscription for route changes subject
    *
    * @type {Subscription}
    */
    private subscription: Subscription;

    /**
     * current event
     *
     * @type {User}
     */
    public user: User;

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;

    /**
     * event
     *
     * @type {Event}
     */
    public event: Event;

    /**
     * Suggested keywords
     *
     * @type Keyword[]
     */
    public suggestedKeywords: Keyword[] = [];

    /**
     * Suggested keywords by others
     *
     * @type Keyword[]
     */
    public suggestedKeywordsByOthers: Keyword[] = [];

    /**
     * Existing keywords
     *
     * @type Keyword[]
     */
    public existingKeywords: Keyword[] = [];

    /**
     * selected view
     *
     * @type {String}
     */
    @Input() view: string = 'matching';

    /**
     * object with block list
     *
     * @type {}
     */
    public blocks: any[];

    /**
     * participant has some keyword setting
     *
     * @type {boolean}
     */
    @Input() savedKeywords: boolean = false;

    /**
     * participant has some keyword setting
     *
     * @type {boolean}
     */
    public visibleAllKeywords: boolean = false;

    public containers: Container[] = [];

    /**
     * event most matching participants
     *
     * @type Participant
     */
    public matchingAttendees: Participant[] = [];

    /**
     * disable preview behaviour
     *
     * @type {boolean}
     */
    public keywordsEmpty: boolean = true;

    @Output() onChangeLoading: EventEmitter<boolean> = new EventEmitter();
    @Output() onChangeDataLoading: EventEmitter<boolean> = new EventEmitter();
    @Output() onShowAttendeeList: EventEmitter<void> = new EventEmitter();
    @Output() onShowInterests: EventEmitter<void> = new EventEmitter();
    @Output() onShowManageKeywords: EventEmitter<void> = new EventEmitter();
    @Output() onShowAttendeeDetail: EventEmitter<Participant> = new EventEmitter();

    /**
     * info for user about live calculation
     *
     * @type {number}
     */
    public calculatingCalls: number = 0;

    /**
     * info for user about live calculation
     *
     * @type {boolean}
     */
    public keywordChanged: boolean = false;

    public dataLoaded = false;

    // handlers for events
    keywordsChangeHandler;
    attendeeDetailHandler;

    public env = environment;

    /**
     * is limited view for member list
     *
     * @type boolean
     */
    public limitedMemberList: boolean = false;

    /**
     * constructor
     *
     * @param eventService
     * @param appEvents
     */
    constructor(
        public router: Router,
        public translate: TranslateService,
        public eventService: EventService,
        public userService: UserService,
        public popoverController: PopoverController,
        public alertCtrl: AlertController,
        public appEvents: Events,
        public modalController: ModalController,
        public plt: PlatformService,
        public participantService: ParticipantService,
        public overlayService: OverlayService,
        public routerExtService: RouterExtService
    ) {
        this.userLang = this.translate.currentLang;
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() {

        this.keywordsChangeHandler = () => {
            if (!!this.user.selected_participant.keywords.length && !this.wizard && this.view == 'matching') {
                this.loadMostMatching();
            }
        }

        this.attendeeDetailHandler = (attendee) => {
            // find attendee in actual list and change his data
            this.matchingAttendees.forEach((item) => {
                if (item.id == attendee.id) {
                    // update data for attendee
                    item.liked = attendee.liked;
                    item.bookmarked = attendee.bookmarked;
                }
            });
        }

        // attach hooks on event change
        this.appEvents.subscribe('keywords:changed', this.keywordsChangeHandler);
        // detect changes on attendee, like bookmark/like in popup and sycn state
        this.appEvents.subscribe('attendee:changed', this.attendeeDetailHandler);

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.userLang = event.lang;
        });

        if (this.loadEvent) {
            this.refresh();
        }

        setTimeout(() => {
            // check routing, for mobile tabs, init is called only first time
            this.router.events.pipe(
                takeUntil(this.ngUnsubscribe),
                debounceTime(0)
            ).subscribe((event: any) => {

                // for route event event get route event
                if ((event instanceof Scroll)) {
                    event = <RouterEvent>event.routerEvent;
                }

                if (this.env.limitedMemberList[this.env.hostname]?.communities.includes(this.event.id)) {
                    this.limitedMemberList = true;
                }

                // using url from NavigationEnd instance, because route.snapshot was not sometimes updated...
                if (event instanceof RouterEvent && event instanceof NavigationEnd) {
                    // check when mobile tab is used and trigger refresh
                    if (event.url == this.plt.defaultLink + '/keywords/matching') {
                        this.refresh();
                    }
                }
            });
        }, 500);
    }

    /**
     * Watch changes on Inputs and refresh component if needed
     *
     * @param participant
     */
    ngOnChanges(changes: SimpleChanges) {

        if (
            changes.hasOwnProperty('view')
            && !this.loadEvent
        ) {
            this.refresh();
        }
    }

    /**
     * refresh data
     * @return void
     */
    private refresh($event?) {

        this.userService.getCurrentUser().pipe(
            take(1),
            debounceTime(0)
        ).subscribe((user) => {
            if (user.id) {

                this.user = user;

                if (this.user.selected_participant) {

                    if (this.loadEvent) {
                        let subscription = this.eventService.getById(this.user.selected_participant.event_id).subscribe(
                            (event: Event) => {

                                this.processEvent(event, $event);

                            },
                            (error) => {
                                // TODO
                                log('error', 'refreshDashboard', error);
                                // Sentry.captureException(error);
                                // this.showError(error);
                                // hide loading
                                if ($event) {
                                    $event.target.complete();
                                } else {
                                    this.onChangeLoading.emit(false);
                                    this.onChangeDataLoading.emit(false);
                                }
                            });

                        this.plt.activeSubscriptions.push(subscription);
                    } else {
                        this.eventService.getCurrentEvent().pipe(
                            take(1)
                        ).subscribe(
                            (event) => {
                                this.processEvent(event, $event);
                            });
                    }
                } else {
                    this.matchingAttendees = [];
                    // hide loading
                    if ($event) {
                        $event.target.complete();
                    } else {
                        this.onChangeLoading.emit(false);
                        this.onChangeDataLoading.emit(false);
                    }
                }
            } else {
                this.user = null;
            }
        });
    }

    /**
     * process data loading based on event
     *
     * @param {Event} event
     */
    processEvent(event, $event) {
        this.setupEvent(event);

        // load most matching participants
        if (this.user) {
            if (!!this.user.selected_participant.keywords.length && this.view == 'matching') {
                this.loadMostMatching($event);
            } else {
                this.matchingAttendees = [];
                if (this.view != 'manage-keywords') {
                    this.showManageKeywords();
                }

                setTimeout(() => {
                    // hide loading
                    if ($event) {
                        $event.target.complete();
                    } else {
                        this.onChangeLoading.emit(false);
                        this.onChangeDataLoading.emit(false);
                    }
                }, 100);
            }
        } else {
            // hide loading
            if ($event) {
                $event.target.complete();
            } else {
                this.onChangeLoading.emit(false);
                this.onChangeDataLoading.emit(false);
            }
        }
    }

    /**
     * setup event
     *
     * @param {Event} event
     */
    private setupEvent(event: Event) {
        this.event = new Event(event, this.user);
        // this.eventService.setCurrentEvent(this.event);

        // containers (and keywords)
        if (this.user && this.event.containers) {

            this.setupContainers();
        }
    }

    private setupContainers() {
        this.suggestedKeywords = [];
        this.containers = [];
        this.keywordsEmpty = true;

        for (let c = 0; c < this.event.containers.length; c++) {
            const container = new Container(this.event.containers[c]);
            this.event.containers[c] = container;
            let keywordIncluded = false;
            // keywords
            if (container.keywords) {
                for (let k = 0; k < container.keywords.length; k++) {
                    const keyword = container.keywords[k];
                    this.keywordsEmpty = false;

                    if (this.user.selected_participant.keywords.length
                        && this.user.selected_participant.keywords.find((item) => { return keyword.id == item.id })) {
                        keywordIncluded = true;
                    }

                    // suggested keywords
                    if (keyword.participant_id === this.user.selected_participant_id && !keyword.approved_at) {
                        this.suggestedKeywords.push(keyword);
                    }
                    // suggested keywords by others
                    else if (keyword.participant_id !== this.user.selected_participant_id && !keyword.approved_at) {
                        this.suggestedKeywordsByOthers.push(keyword);
                    }
                    // existing keywords
                    else {
                        this.existingKeywords.push(keyword);
                    }
                }
            }

            // order keywords
            container.orderKeywordsByName(this.userLang, this.event.default_locale);

            // check if some keyword is checked, then include it in visible list
            if (keywordIncluded) {
                this.containers.push(container);
            }
        }
    }

    private loadMostMatching($event = null, scroll = false) {
        if (this.user) {

            let subscription = this.participantService.getFilteredListByEvent(
                this.user.selected_participant.event_id,
                12,
                0,
                null,
                {
                    jobs: true,
                    hideNotVisibleMatching: true
                }
            ).subscribe((response) => {
                this.matchingAttendees = [];
                response['participants'].forEach(participant => {
                    this.matchingAttendees.push(new Participant(participant));
                });

                // maximum try 10 times to load most matching with calculation
                if (response['live_status']['calculating'] && response['live_status']['calculating'] > 0 && this.calculatingCalls <= 20) {
                    // show calculating loading
                    this.calculatingCalls++;
                    setTimeout(() => {
                        this.loadMostMatching();
                    }, this.calculatingCalls * 1000); // put longer delay each next try
                } else {
                    this.calculatingCalls = 0;
                }

                // TODO: ????
                // if (scroll && this.plt.sizeSm && this.scrollTop) {
                //     setTimeout(() => {
                //         this.content.scrollToPoint(0, this.scrollTop, 0);
                //     }, 150);
                // }

                setTimeout(() => {
                    // hide loading
                    if ($event) {
                        $event.target.complete();
                    } else {
                        this.onChangeLoading.emit(false);
                        this.onChangeDataLoading.emit(false);
                    }
                }, 100);
            },
                (error) => {
                    console.info(error);
                })

            this.plt.activeSubscriptions.push(subscription);
        }
    }

    /**
     * Toggle keyword
     *
     * @param column
     * @param keyword
     */
    public toggleKeyword(column: number, keyword: Keyword) {

        if (!keyword.loading) {

            this.keywordChanged = true;

            keyword.loading = column;
            this.participantService.toggleKeyword(this.user.selected_participant.id, column, keyword.id, this.wizard).subscribe(
                (success) => {
                    keyword.loading = 0;
                    this.overlayService.showSuccess(success.message);
                    if (success.exists) {
                        this.enableKeyword(column, keyword);
                    } else {
                        this.disableKeyword(column, keyword);
                    }
                    // TODO[jg] - check this with method setupContainers
                    // update containers list to have only visible conainters with keywords
                    this.containers = [];

                    for (let c = 0; c < this.event.containers.length; c++) {
                        const container = this.event.containers[c];
                        let keywordIncluded = false;
                        // keywords
                        if (container.keywords) {
                            for (let k = 0; k < container.keywords.length; k++) {
                                const keyword = container.keywords[k];

                                if (this.user.selected_participant.keywords.length
                                    && this.user.selected_participant.keywords.find((item) => { return keyword.id == item.id })) {
                                    keywordIncluded = true;
                                }
                            }
                        }

                        // check if some keyword is checked, then include it in visible list
                        if (keywordIncluded) {
                            this.containers.push(container);
                        }
                    }

                    this.appEvents.publish('keywords:changed');
                },
                (error) => {
                    keyword.loading = 0;
                    this.overlayService.showError(error.error.message);
                });
        }
    }

    /**
     * Enable keyword for a specific column
     *
     * @param column
     * @param keyword
     */
    public enableKeyword(column: number, keyword: Keyword) {
        const k = new Keyword(keyword);
        k.pivot = {
            column: column
        };
        this.user.selected_participant.keywords.push(k);
        if (this.wizard) {
            this.userService.setCurrentUser(this.user);
        }
    }

    /**
     * Disable keyword for a specific column
     *
     * @param column
     * @param keyword
     */
    public disableKeyword(column: number, keyword: Keyword) {
        this.user.selected_participant.keywords.forEach((item, index) => {
            if (item.id === keyword.id && column === item.pivot.column) {
                this.user.selected_participant.keywords.splice(index, 1);
                if (this.wizard) {
                    this.userService.setCurrentUser(this.user);
                }
            }
        });
    }

    // TODO[jg] - remove those duplicit codes for isEnabled....
    /**
     * Check, if the user has activated a keyword for a specific column
     *
     * @param column
     * @param keyword
     * @returns {boolean}
     */
    public isEnabled(column: number, keyword: Keyword): boolean {
        if (this.user && this.user.selected_participant) {
            for (let k = 0; k < this.user.selected_participant.keywords.length; k++) {
                const item = this.user.selected_participant.keywords[k];
                if (keyword.id === item.id && column === item.pivot.column) {
                    return true;
                }
            }
        }

        return false;
    }

    async presentSuggestKeyword(ev: any, container) {
        this.routerExtService.softNavigate();

        const popover = await this.popoverController.create({
            component: SuggestKeywordComponent,
            event: ev,
            componentProps: { container: container },
            cssClass: 'edit-note'
            //translucent: true
        });

        popover.onWillDismiss().then((data) => {
            if (data.data) {
                this.suggestKeyword(data.data.keyword, data.data.container.id);
            }
            if (data.data && data.data.action && data.data.action == 'close') {
                this.routerExtService.softBack();
            }
        });

        return await popover.present();
    }

    /**
     * Add keyword (only if it doesn't exists yet)
     *
     * @param value
     * @param containerId
     */
    public suggestKeyword(value: string, containerId: number) {
        let lang = this.userLang;
        if (this.event.available_locales.indexOf(this.userLang) === -1) {
            lang = this.event.default_locale;
        }

        let container = new Container();
        for (let i = 0; i < this.event.containers.length; i++) {
            if (this.event.containers[i].id === +containerId) {
                container = this.event.containers[i];
            }
        }
        let duplicatedKeyword = false;
        if (value.trim() !== '') {
            // check, if the keyword already exists in the suggestions of others - alert
            for (let k = 0; k < this.suggestedKeywordsByOthers.length; k++) {
                if (this.suggestedKeywordsByOthers[k].translateOrNew(lang).name
                    && this.suggestedKeywordsByOthers[k].translateOrNew(lang).name.toLowerCase() === value.trim().toLowerCase()
                    && this.suggestedKeywordsByOthers[k].container_id === container.id) {
                    duplicatedKeyword = true;
                    this.overlayService.showError(this.translate.instant('KEYWORD_SUGGESTION_ALREADY_EXISTS_BY_OTHERS', { name: value.trim() }));
                    break;
                }
            }
            // check, if the keyword already exists in own suggestions - do nothing
            for (let k = 0; k < this.suggestedKeywords.length; k++) {
                if (this.suggestedKeywords[k].translateOrNew(lang).name
                    && this.suggestedKeywords[k].translateOrNew(lang).name.toLowerCase() === value.trim().toLowerCase()
                    && this.suggestedKeywords[k].container_id === container.id) {
                    duplicatedKeyword = true;
                    this.onChangeLoading.emit(false);
                    this.onChangeDataLoading.emit(false);
                    this.overlayService.showError(this.translate.instant('KEYWORD_SUGGESTION_ALREADY_EXISTS_BY_USER', { name: value.trim() }));
                }
            }
            // check, if the keyword already exists - alert
            for (let k = 0; k < this.existingKeywords.length; k++) {
                if (this.existingKeywords[k].translateOrNew(lang).name
                    && this.existingKeywords[k].translateOrNew(lang).name.toLowerCase() === value.trim().toLowerCase()
                    && this.existingKeywords[k].container_id === container.id) {
                    duplicatedKeyword = true;
                    this.onChangeLoading.emit(false);
                    this.onChangeDataLoading.emit(false);
                    this.overlayService.showError(this.translate.instant('KEYWORD_ALREADY_EXISTS', { name: value.trim() }));
                    break;
                }
            }
            // insert kyword suggestion if there is no duplicate keyword
            if (!duplicatedKeyword) {
                const keyword = new Keyword;
                keyword.container_id = container.id;
                keyword.containerName = container.translate(lang, this.event.default_locale).name;
                keyword.translateOrNew(lang).name = value.trim();

                this.participantService.suggestKeyword(this.user.selected_participant.id, keyword).subscribe(
                    (success) => {
                        // update keyword with connection to other objects and id
                        keyword.id = success.id;
                        keyword.participant_id = this.user.selected_participant.id;

                        // insert keyword into proper list of keywords
                        for (let c = 0; c < this.event.containers.length; c++) {
                            if (this.event.containers[c].id == keyword.container_id) {
                                this.event.containers[c].keywords.push(keyword);
                                this.event.containers[c].orderKeywordsByName(this.userLang, this.event.default_locale);
                                break;
                            }
                        };

                        // put keyword into my suggested keywords
                        this.suggestedKeywords.push(keyword);

                        this.overlayService.showSuccess(success.message);
                    },
                    (error) => {
                        this.suggestedKeywords = this.suggestedKeywords.filter(kw => {
                            return kw.translateOrNew(lang).name && kw.translateOrNew(lang).name.toLowerCase() !== value.trim().toLowerCase();
                        });
                        // catch keyword max length errors
                        if (!!error.error.fields) {
                            for (const i in error.error.fields) {
                                if (error.error.fields.hasOwnProperty(i)) {
                                    if (i.indexOf('keyword') !== -1) {
                                        this.overlayService.showError(error.error.fields[i]);
                                        return;
                                    }
                                }
                            }
                        }
                        this.onChangeLoading.emit(false);
                        this.onChangeDataLoading.emit(false);
                        this.overlayService.showError(error.error.message);
                    });
            }
        }
    }

    /**
     * Delete keyword
     *
     * @param keyword
     */
    public deleteSuggestedKeyword(keyword: Keyword) {
        this.onChangeLoading.emit(false);
        this.deleteKeywordFromServer(keyword);
        //this.suggestedKeywords = this.suggestedKeywords.filter(item => item.getId(true) !== keyword.getId(true));
    }

    public async openDeleteSuggest(keyword: Keyword) {
        let alert = await this.alertCtrl.create({
            cssClass: 'confirm',
            header: this.translate.instant('KEYWORD_DELETE'),
            message: this.translate.instant('KEYWORD_DELETE_HINT', { name: keyword.translate(this.userLang, this.event.default_locale).name }),
            buttons: [{
                text: this.translate.instant('BUTTON_CANCEL'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => { }
            }, {
                text: this.translate.instant('KEYWORD_DELETE_KEYWORD_PERMANENTLY'),
                role: '',
                cssClass: 'primary',
                handler: () => { this.deleteKeywordFromServer(keyword) }
            }]
        });
        await alert.present();
    }

    /**
     * Deletes an object from the server
     *
     * @param keyword
     */
    public deleteKeywordFromServer(keyword: Keyword) {
        this.eventService.deleteKeyword(keyword.id).subscribe(
            (success) => {
                // trigger keyword change to refresh matching list properly
                this.keywordChanged = true;
                this.refresh();
                this.overlayService.showSuccess(success.message);
            },
            (error) => {
                this.onChangeLoading.emit(false);
                this.onChangeDataLoading.emit(false);
                this.overlayService.showError(error.error.message);
            });
    }

    public showAttendeeList() {
        this.onShowAttendeeList.emit();
    }

    public showInterests() {
        this.onShowInterests.emit();

        if (this.keywordChanged && this.loadEvent) {
            this.keywordChanged = false;
            this.loadMostMatching();
        }

        // we need to have router navigation actions firred
        // NOTE[jg] those should be move to parent component and managed over onShowIntersts event
        if (!this.event.is_homepage_content_matching) {
            this.router.navigate([this.plt.defaultLink + '/keywords/matching']);
        } else {
            this.router.navigate([this.plt.defaultLink + '/home/info/matching']);
        }
    }

    public showManageKeywords() {
        this.view = 'manage-keywords';
        this.onShowManageKeywords.emit();
    }

    /**
     * go to participants detail page
     *
     * @param participant
     *
     * @return void
     */
    public async showAttendeeDetail(attendee: Participant) {
        this.onShowAttendeeDetail.emit(attendee);
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {

        if (this.keywordsChangeHandler) {
            this.appEvents.unsubscribe('keywords:changed', this.keywordsChangeHandler);
        }

        if (this.attendeeDetailHandler) {
            this.appEvents.unsubscribe('attendee:changed', this.attendeeDetailHandler);
        }

        // this.overlayService.closeModals();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
