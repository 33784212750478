import { Translatable } from "./translatable.interface";
import { DatexPipe } from "../pipes/datex/datex";
import * as moment from "moment";
import { BroadcastDetail } from "./broadcast-detail";
import { BroadcastTranslation } from "./broadcast-translation";
import { UploadedFile } from "./uploaded-file";

export class Broadcast implements Translatable {
    id: number;
    event_id: number;
    send_at: string;
    date_send_at: Date = new Date();
    sent_at: string;
    date_sent_at: Date = new Date();
    translations: BroadcastTranslation[] = [];
    available_locales = [];
    logo_url: string;
    pivot: { unread: number };
    mark_read = 0;
    firstNew: boolean = false;

    details: BroadcastDetail[] = [];

    groupedDetails = [];
    groupedDetailsDeleted = [];

    // now only PDF and images for broadcast
    detailTypes = [
        {
            name: 'image',
            icon: 'image',
            group: [
                'image'
            ]
        }
    ];

    files: UploadedFile[] = [];

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: Broadcast) {
        if (!!init) {
            Object.assign(this, init);
            this.translations = this.translations.map(t => new BroadcastTranslation(t));

            this.date_send_at = moment.utc(this.send_at).toDate();
            if (!!this.sent_at) {
                this.date_sent_at = moment.utc(this.sent_at).toDate();
            }

            // load details
            if (this.details) {
                this.details.forEach((detail) => {
                    if (!this.groupedDetails.hasOwnProperty(detail.group_index)) {
                        // create default options object in case, if it is missing
                        if (!detail.options) {
                            detail.options = {};
                        }
                        this.groupedDetails[detail.group_index] = {
                            deleted: false,
                            type: detail.group_type,
                            line: detail.options.line ? true : false,
                            menu: detail.options.menu ? true : false,
                            details: []
                        };
                    }
                    this.groupedDetails[detail.group_index].details.push(new BroadcastDetail(detail));
                    // fill array with attachments of broadcast
                    if (detail.type == 'image') {
                        let file = new UploadedFile();
                        file.id = detail.id;
                        file.file_url = detail.value;
                        file.name = detail.options.orig_name;
                        if (detail.options.name) {
                            file.name = detail.options.name;
                        }
                        file.size = detail.options.size;
                        if (detail.options.type == 'pdf') {
                            file.preview = detail.options.preview;
                            // add pdf extension if not already presented in name
                            if (file.name && file.name.indexOf('.pdf') == -1) {
                                file.name += '.pdf';
                            }
                        }
                        file.custom = '/broadcast/attachments/';
                        this.files.push(file)
                    }
                });
            }
        }
        else {
            const datePipe = new DatexPipe();

            this.send_at = datePipe.transform(this.date_send_at, 'YYYY-MM-DD HH:mm:ss');
            // may NOT be initialized by default - only if loaded from server
            // this.sent_at = datePipe.transform(this.date_sent_at, 'YYYY-MM-DD HH:mm:ss');
        }
    }

    /**
     * get translated attributes
     *
     * @param locale
     *
     * @return {BroadcastTranslation}
     */
    public translateOrNew(locale: string) {
        let currentTranslation = this.translations.filter(translation => translation.locale === locale)[0];

        if (!currentTranslation) {
            currentTranslation = new BroadcastTranslation;
            currentTranslation.locale = locale;

            this.translations.push(currentTranslation);
        }

        return currentTranslation;
    }

    /**
     * translate
     *
     * @param locale
     * @param defaultLocale
     * @return {BroadcastTranslation}
     */
    public translate(locale: string, defaultLocale?: string) {
        return this.translations.filter(translation => {
            return translation.locale === locale;
        })[0] ||
            this.translations.filter(translation => {
                return translation.locale === defaultLocale;
            })[0] ||
            new BroadcastTranslation();
    }

}
