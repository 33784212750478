import { VotingAnswerTranslation } from "./voting-answer-translation";
import { Translatable } from "./translatable.interface";

export class VotingAnswer implements Translatable {
    id: number;
    voting_id: number;
    response_count: number;
    translations: VotingAnswerTranslation[] = [];
    selected: boolean = false;
    answer: string;
    free_text: boolean = false;
    free_text_value: string = '';

    /**
     * constructor
     *
     * @param {VotingAnswer} init
     */
    public constructor(init?: VotingAnswer) {
        if (!!init) {
            Object.assign(this, init);
            this.translations = this.translations.map(t => new VotingAnswerTranslation(t));
        }
    }

    /**
     * get translated attributes
     *
     * @param locale
     *
     * @return {VotingAnswerTranslation}
     */
    public translateOrNew(locale: string) {
        let currentTranslation = this.translations.filter(translation => translation.locale === locale)[0];

        if (!currentTranslation) {
            currentTranslation = new VotingAnswerTranslation;
            currentTranslation.locale = locale;

            this.translations.push(currentTranslation);
        }

        return currentTranslation;
    }

    /**
     * translate
     *
     * @param locale
     * @param defaultLocale
     * @return {VotingAnswerTranslation}
     */
    public translate(locale: string, defaultLocale?: string) {
        return this.translations.filter(translation => { return translation.locale === locale; })[0] ||
            this.translations.filter(translation => { return translation.locale === defaultLocale; })[0] ||
            new VotingAnswerTranslation();
    }
}
