import { MarketplaceCategoryTranslation } from "./marketplace-category-translation";
import * as moment from "moment";
import { Translatable } from "./translatable.interface";

export class MarketplaceCategory implements Translatable {
    id: number;
    marketplace_id: number;
    show_price: boolean = true;

    translations: MarketplaceCategoryTranslation[] = [];

    removed: boolean = false;
    index: number;
    // version validation
    new_title: any;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: MarketplaceCategory) {
        if (!!init) {
            Object.assign(this, init);
            this.translations = this.translations.map(t => new MarketplaceCategoryTranslation(t));
        }
    }

    /**
     * get translated attributes
     *
     * @param locale
     *
     * @return {MarketplaceCategoryTranslation}
     */
    public translateOrNew(locale: string) {
        let currentTranslation = this.translations.filter(translation => translation.locale === locale)[0];

        if (!currentTranslation) {
            currentTranslation = new MarketplaceCategoryTranslation;
            currentTranslation.locale = locale;

            this.translations.push(currentTranslation);
        }

        return currentTranslation;
    }

    /**
     * translate
     *
     * @param locale
     * @param defaultLocale
     * @return {MarketplaceCategoryTranslation}
     */
    public translate(locale: string, defaultLocale?: string) {
        return (
            this.translations.filter(translation => { return translation.locale === locale; })[0] ||
            this.translations.filter(translation => { return translation.locale === defaultLocale; })[0] ||
            this.translations.filter(translation => { return translation.locale === 'de'; })[0]
        );
    }
}
