import { Event } from "./event";
import { ChatGroupAdmin } from "./chat-group-admin";
import { ChatGroupTranslation } from "./chat-group-translation";
import { Appointment } from "./appointment";
import { Stream } from "./stream";

import { Translatable } from "./translatable.interface";

import * as moment from "moment";
import { DatePipe } from "@angular/common";

export class ChatGroup implements Translatable {
    id: number;
    name: string;
    description: string;
    event_id: number;
    event_name: string;

    user_id: number;
    translations: ChatGroupTranslation[] = [];
    status: number = 0;
    number_attendees: number = 0;
    unread_messages_count: number = 0;

    available_locales = ['de', 'en'];
    default_locale = 'de';

    created_at: string;
    date_created_at: Date = new Date();

    read_at: string;
    date_read_at: Date;

    event: Event;

    admins: ChatGroupAdmin[] = [];
    participantIds: Number[] = [];

    pivot_notifications_muted: boolean = false;

    adminsToRemove: ChatGroupAdmin[] = [];

    user_is_owner: boolean = false;
    user_is_moderator: boolean = false;

    appointment_id: number;
    appointment: Appointment;

    // should be feature for video calls for group available?
    allow_call: boolean = false;

    stream_id: number;
    stream: Stream;

    hidden: boolean = false;

    /**
     * constructor
     *
     * @param {ChatGroup} init
     */
    public constructor(init?: ChatGroup) {
        if (!!init) {
            Object.assign(this, init);
            this.translations = this.translations.map(t => new ChatGroupTranslation(t));

            if (this.appointment) {
                this.appointment = new Appointment(this.appointment);
            }

            if (this.stream) {
                this.stream = new Stream(this.stream);
            }

            this.date_created_at = moment.utc(this.created_at).toDate();
            if (this.read_at) {
                this.date_read_at = moment.utc(this.read_at).toDate();
            }

            // init translations
            this.translations = [];
            if (init.translations) {
                for (let index = 0; index < init.translations.length; index++) {
                    this.translations.push(new ChatGroupTranslation(init.translations[index]));
                }
            }
        }
    }

    /**
     * get translated attributes
     *
     * @param locale
     *
     * @return {ChatGroupTranslation}
     */
    public translateOrNew(locale: string) {
        let currentTranslation = this.translations.filter(translation => translation.locale === locale)[0];

        if (!currentTranslation) {
            currentTranslation = new ChatGroupTranslation;
            currentTranslation.locale = locale;

            this.translations.push(currentTranslation);
        }

        return currentTranslation;
    }

    /**
     * translate
     *
     * @param locale
     * @param defaultLocale
     * @return {ChatGroupTranslation}
     */
    public translate(locale: string, defaultLocale?: string) {
        return this.translations.filter(translation => { return translation.locale === locale; })[0] ||
            this.translations.filter(translation => { return translation.locale === defaultLocale; })[0] ||
            new ChatGroupTranslation();
    }

    /**
     * check if video call is allowed
     *
     * @return {boolean}
     */
    public callIsAllowed(): boolean {
        return this.allow_call || !!this.appointment_id;
    }

}
