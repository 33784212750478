<div class="modal-header">
    <ion-grid class="ion-no-padding">
        <ion-row class="ion-no-padding">
            <ion-col size="10"
                     class="ion-no-padding">
                {{ 'QR_CODE_READER_HEADER' | translate }}
            </ion-col>
            <ion-col size="2"
                     class="ion-text-right ion-no-padding">
                <a (click)="close()">
                    <svg-icon [applyClass]="true"
                              src="/assets/icons/ico-close.svg"
                              class="icon fill-dark">
                    </svg-icon>
                </a>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>

<ngx-scanner-qrcode *ngIf="!plt.is('cordova')"
                    [config]="scannerConfig"
                    #action="scanner">
</ngx-scanner-qrcode>

<div *ngIf="!qrScan"
     class="placeholder">
    {{ 'QR_CODE_PLEASE_ALLOW_ACCESS_TO_CAMERA' | translate }}<br />
    <ion-button (click)="startStreaming()"
                [disabled]="loading">
        <ng-container *ngIf="!loading">OK</ng-container>
        <ion-spinner *ngIf="loading"
                     name="dots">
        </ion-spinner>
    </ion-button>

</div>
<div *ngIf="qrScan"
     class="custom-scanner">
</div>

<ion-footer>
    <ion-button (click)="close()">
        {{ 'QR_CODE_READER_CLOSE' | translate }}
    </ion-button>
</ion-footer>