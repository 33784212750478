import * as moment from "moment/moment";

import { environment } from "../../environments/environment";
import { isExternalLink } from "../helpers/url";

export class ParticipantDetail {
    id: number;
    participant_id: number;
    type: string;
    error: string;
    value: string | Date;
    string_value: string;
    position: number;
    group_index: number;
    group_type: string = '';
    deleted: boolean = false;
    options: {
        type?: string,
        name?: string,
        line?: boolean,
        menu?: boolean,
        linkInNewTab?: boolean,
        filename?: string
        target?: string,
        error?: string,
        values?: Array<string>,
        fixed?: boolean
    } = {};

    /**
     * constructor
     *
     * @param {ParticipantDetail} init
     */
    public constructor(init?: ParticipantDetail) {
        if (!!init) {
            Object.assign(this, init);

            if (!this.options) {
                this.options = {};
            }

            if ([
                'from',
                'to'
            ].indexOf(this.type) != -1) {
                if (this.value) {
                    this.value = moment(this.value).toDate();
                    this.string_value = this.value.toISOString();
                }
            }

            if ([
                'link',
            ].indexOf(this.type) != -1) {
                if (this.value) {
                    this.options.linkInNewTab = isExternalLink(this.value.toString());
                }
            }
        }
    }
}
