<ion-content class="ion-padding">
    <div class="content-centered-column">
        <div class="content-centered-row">
            <form *ngIf="plt.isConnected"
                  (ngSubmit)="register()"
                  #f="ngForm"
                  novalidate>
                <ion-list class="form">
                    <ion-item class="title ion-no-padding ion-no-lines">
                        <h1>{{ 'REGISTRATION_TITLE' | trns}}</h1>
                    </ion-item>
                    <ion-item class="hint">
                        <p>{{ 'REGISTRATION_HINT' | trns }}</p>
                    </ion-item>

                    <ion-item class="ion-no-lines">
                        <ion-label position="stacked">{{ 'REGISTRATION_LABEL_LANGUAGE' | trns }}</ion-label>
                        <ion-select [interface]="selectType"
                                    [interfaceOptions]="{cssClass: 'not-overflow'}"
                                    [cancelText]="'BUTTON_CANCEL' |  translate"
                                    class="ion-padding"
                                    name="locale"
                                    #locale="ngModel"
                                    [(ngModel)]="userLang"
                                    (ionChange)="switchLanguage($event.target.value);">
                            <ion-select-option *ngFor="let lang of langs"
                                               [value]="lang">
                                {{ lang | uppercase | trns }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>

                    <ion-item class="ion-no-lines">
                        <ion-label position="stacked">{{ 'REGISTARTION_LABEL_SALUTATION' | trns }}</ion-label>
                        <ion-select [interface]="selectType"
                                    [interfaceOptions]="{cssClass: 'not-overflow'}"
                                    [cancelText]="'BUTTON_CANCEL' |  translate"
                                    class="ion-padding"
                                    name="salutation"
                                    #salutation="ngModel"
                                    [(ngModel)]="model.salutation"
                                    required>

                            <ng-container *ngIf="userLang=='en'">
                                <ion-select-option value="">
                                    {{ 'PLACEHOLDER_PLEASE_SELECT' | translate }}
                                </ion-select-option>
                                <ion-select-option value=" ">
                                    {{ 'LETTER_SALUTATION_DIVERSE' | translate }}
                                </ion-select-option>
                                <ion-select-option value="{{ 'LETTER_SALUTATION_VALUE_MRS' | translate }}">
                                    {{ 'LETTER_SALUTATION_ID_MRS' | translate }}
                                </ion-select-option>
                                <ion-select-option value="{{ 'LETTER_SALUTATION_VALUE_MS' | translate }}">
                                    {{ 'LETTER_SALUTATION_ID_MS' | translate }}
                                </ion-select-option>
                                <ion-select-option value="{{ 'LETTER_SALUTATION_VALUE_MR' | translate }}">
                                    {{ 'LETTER_SALUTATION_ID_MR' | translate }}
                                </ion-select-option>
                            </ng-container>
                            <ng-container *ngIf="userLang!='en'">
                                <ion-select-option value="">
                                    {{ 'PLACEHOLDER_PLEASE_SELECT' | translate }}
                                </ion-select-option>
                                <ion-select-option value=" ">
                                    {{ 'LETTER_SALUTATION_DIVERSE' | translate }}
                                </ion-select-option>
                                <ion-select-option value="{{ 'LETTER_SALUTATION_VALUE_MS' | translate }}">
                                    {{ 'LETTER_SALUTATION_ID_MS' | translate }}
                                </ion-select-option>
                                <ion-select-option value="{{ 'LETTER_SALUTATION_VALUE_MR' | translate }}">
                                    {{ 'LETTER_SALUTATION_ID_MR' | translate }}
                                </ion-select-option>
                            </ng-container>

                        </ion-select>
                    </ion-item>
                    <span *ngIf="salutation.touched && !salutation.valid"
                          class="error-message">
                        {{ 'VALIDATION_REQUIRED' | trns }}
                    </span>

                    <ion-item class="ion-no-lines"
                              [ngClass]="{ 'has-error': firstname.touched && !firstname.valid }">
                        <ion-label position="stacked">{{ 'REGISTRATION_LABEL_FIRSTNAME' | trns }}</ion-label>
                        <ion-input type="text"
                                   placeholder="{{ 'REGISTRATION_PLACEHOLDER_FIRSTNAME' | translate }}"
                                   name="firstname"
                                   [autocomplete]="!!model.firstname ? 'off' : 'on'"
                                   #firstname="ngModel"
                                   [(ngModel)]="model.firstname"
                                   [disabled]="invited"
                                   required>
                        </ion-input>
                    </ion-item>
                    <span *ngIf="firstname.touched && !firstname.valid"
                          class="error-message">
                        {{ 'VALIDATION_REQUIRED' | trns }}
                    </span>

                    <ion-item class="ion-no-lines"
                              [ngClass]="{ 'has-error': lastname.touched && !lastname.valid }">
                        <ion-label position="stacked">{{ 'REGISTRATION_LABEL_LASTNAME' | trns }}</ion-label>
                        <ion-input type="text"
                                   placeholder="{{ 'REGISTRATION_PLACEHOLDER_LASTNAME' | translate }}"
                                   name="lastname"
                                   [autocomplete]="!!model.lastname ? 'off' : 'on'"
                                   #lastname="ngModel"
                                   [(ngModel)]="model.lastname"
                                   [disabled]="invited"
                                   required>
                        </ion-input>
                    </ion-item>
                    <span *ngIf="lastname.touched && !lastname.valid"
                          class="error-message">
                        {{ 'VALIDATION_REQUIRED' | trns }}
                    </span>

                    <ion-item class="ion-no-lines"
                              [ngClass]="{ 'has-error': email.touched && !email.valid }">
                        <ion-label position="stacked">{{ 'REGISTRATION_LABEL_EMAIL' | trns }}</ion-label>
                        <ion-input type="email"
                                   placeholder="{{ 'REGISTRATION_PLACEHOLDER_EMAIL' | translate }}"
                                   name="email"
                                   inputmode="email"
                                   spellcheck="false"
                                   autocapitalize="off"
                                   [autocomplete]="!!model.email ? 'off' : 'on'"
                                   #email="ngModel"
                                   [(ngModel)]="model.email"
                                   [disabled]="invited"
                                   required
                                   pattern='^(([^<>\(\)\[\]\\.,;:\s@"]+(\.[^<>\(\)\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'>
                        </ion-input>
                    </ion-item>
                    <span *ngIf="email.touched && !email.valid"
                          class="error-message">
                        {{ 'VALIDATION_REQUIRED' | trns }}
                    </span>

                    <ion-item class="ion-no-lines"
                              [ngClass]="{ 'has-error': (password.touched && !password.valid) || (validate && validationErrors.password) }">
                        <ion-label position="stacked">{{ 'REGISTRATION_LABEL_PASSWORD' | trns }}</ion-label>
                        <ion-input type="password"
                                   placeholder="{{ 'REGISTRATION_PLACEHOLDER_PASSWORD' | translate }}"
                                   name="password"
                                   [autocomplete]="'new-password'"
                                   #password="ngModel"
                                   [(ngModel)]="model.password"
                                   required>
                        </ion-input>
                    </ion-item>
                    <span *ngIf="validate && validationErrors.password"
                          class="error-message">
                        {{ validationErrors.password }}
                    </span>
                    <span *ngIf="password.touched && !password.valid"
                          class="error-message">
                        {{ 'VALIDATION_REQUIRED' | trns }}
                    </span>

                    <ion-item class="ion-no-lines"
                              [ngClass]="{ 'has-error': (password_confirmation.touched && !password_confirmation.valid) || (validate && validationErrors.password_confirmation) }">
                        <ion-label position="stacked">{{ 'REGISTRATION_LABEL_PASSWORD_CONFIRMATION' | trns }}</ion-label>
                        <ion-input type="password"
                                   placeholder="{{ 'REGISTRATION_PLACEHOLDER_PASSWORD_CONFIRMATION' | translate }}"
                                   name="password_confirmation"
                                   [autocomplete]="'new-password'"
                                   #password_confirmation="ngModel"
                                   [(ngModel)]="model.password_confirmation"
                                   required>
                        </ion-input>
                    </ion-item>
                    <span *ngIf="validate && validationErrors.password_confirmation"
                          class="error-message">
                        {{ validationErrors.password_confirmation }}
                    </span>
                    <span *ngIf="password_confirmation.touched && !password_confirmation.valid"
                          class="error-message">
                        {{ 'VALIDATION_REQUIRED' | trns }}
                    </span>

                    <ng-container *ngIf="!invited">
                        <ion-item class="ion-no-lines"
                                  [ngClass]="{ 'has-error': (event_key.touched && !event_key.valid) || (validate && validationErrors.event_key) }">
                            <ion-label position="stacked">{{ 'REGISTRATION_LABEL_EVENT_KEY' | trns }}</ion-label>
                            <ion-input type="text"
                                       name="event_key"
                                       [autocomplete]="!!model.event_key ? 'off' : 'on'"
                                       placeholder="{{ 'REGISTER_EVENT_KEY_PLACEHOLDER' | translate }}"
                                       #event_key="ngModel"
                                       (ionBlur)="verifyEventKey()"
                                       [(ngModel)]="model.event_key"
                                       required>
                            </ion-input>
                            <span *ngIf="plt.is('ios') || plt.is('android')"
                                  class="qr"
                                  [ngClass]="{'ios': plt.is('ios')}"
                                  (click)="showQRCodeReader()">
                                <svg-icon [applyClass]="true"
                                          src="/assets/icons/qr-code.svg">
                                </svg-icon>
                            </span>
                        </ion-item>
                        <span *ngIf="validate && validationErrors.event_key"
                              class="error-message">
                            {{ validationErrors.event_key }}
                        </span>
                        <span *ngIf="event_key.touched && !event_key.valid"
                              class="error-message">
                            {{ 'VALIDATION_REQUIRED' | trns }}
                        </span>
                    </ng-container>

                    <ion-item class="global ion-no-padding"
                              [ngClass]="{ 'has-error': agb_accepted.touched && !agb_accepted.valid }">
                        <ion-checkbox slot="start"
                                      class="required"
                                      name="agb_accepted"
                                      #agb_accepted="ngModel"
                                      [(ngModel)]="model.agb_accepted"
                                      required></ion-checkbox>
                        <ion-label [innerHtml]="'REGISTRATION_LABEL_AGB' | trns">
                        </ion-label>
                    </ion-item>
                    <span *ngIf="agb_accepted.touched && !agb_accepted.valid"
                          class="error-message">
                        {{ 'VALIDATION_REQUIRED' | trns }}
                    </span>

                    <ion-item class="global ion-no-padding"
                              [ngClass]="{ 'has-error': data_processing_accepted.touched && !data_processing_accepted.valid }">
                        <ion-checkbox slot="start"
                                      class="required"
                                      name="data_processing_accepted"
                                      #data_processing_accepted="ngModel"
                                      [(ngModel)]="model.data_processing_accepted"
                                      required></ion-checkbox>
                        <ion-label [innerHtml]="'REGISTRATION_LABEL_DATA_PROCESSING' | trns"></ion-label>
                    </ion-item>
                    <span *ngIf="data_processing_accepted.touched && !data_processing_accepted.valid"
                          class="error-message">
                        {{ 'VALIDATION_REQUIRED' | trns }}
                    </span>

                    <ion-item *ngIf="communityTransfer"
                              class="global ion-no-padding">
                        <ion-checkbox slot="start"
                                      class="required"
                                      name="transfer_accepted"
                                      #transfer_accepted="ngModel"
                                      [(ngModel)]="model.transfer_accepted"></ion-checkbox>
                        <ion-label [innerHtml]="'REGISTRATION_LABEL_TRANSFER_ACCEPTED' | trns:{'eventName': eventName}"></ion-label>
                    </ion-item>

                    <ion-button expand="block"
                                color="primary"
                                type="submit"
                                [disabled]="!f.form.valid">
                        {{ 'REGISTRATION_BUTTON_REGISTER' | trns }}
                    </ion-button>
                    <ion-item class="ion-no-padding ion-no-lines">
                        <a class="link pointer"
                           (click)="goToLogin()">
                            {{ 'REGISTRATION_LINK_LOGIN' | trns }}
                        </a>
                    </ion-item>

                    <hr>

                    <div class="or-divider">{{ 'LOGIN_OR' | trns }}</div>

                    <!-- Sign with Apple is available from iOS > 13 -->
                    <ion-button *ngIf="plt.is('ios') && plt.is('cordova') && plt.systemVersion >= 13"
                                expand="block"
                                class="apple"
                                type="button"
                                (click)="appleLogin()">
                        <ion-icon name="logo-apple"></ion-icon>
                        {{ 'REGISTRATION_APPLE' | trns }}
                    </ion-button>

                    <ion-button expand="block"
                                class="linkedin"
                                type="button"
                                [ngClass]="{'disabled': linkedInSubmit}"
                                (click)="socialRegisterWeb('linkedin'); linkedInSubmit = true">
                        <img src="/assets/icons/linkedin-logo.svg"
                             [alt]="'REGISTRATION_LINKEDIN' | trns" />
                        {{ 'REGISTRATION_LINKEDIN' | trns }}
                    </ion-button>

                </ion-list>
            </form>
            <div *ngIf="!plt.isConnected">
                {{ 'LOGIN_APP_IS_OFFLINE' | trns }}
            </div>
        </div>
    </div>

    <div *ngIf="!plt.sizeXl"
         class="footer-links">
        <ng-container *ngIf="userLang=='en'">
            <a href="https://www.congreet.com/en/imprint/"
               target="_blank">{{ 'CUSTOM_IMPRESSUM' | trns }}</a> |
            <a href="https://www.congreet.com/en/privacy/"
               target="_blank">{{ 'CUSTOM_DATENSCHUTZ' | trns }}</a>
        </ng-container>
        <ng-container *ngIf="userLang=='de'">
            <a href="https://www.congreet.com/de/impressum/"
               target="_blank">{{ 'CUSTOM_IMPRESSUM' | trns }}</a> |
            <a href="https://www.congreet.com/de/datenschutz/"
               target="_blank">{{ 'CUSTOM_DATENSCHUTZ' | trns }}</a>
        </ng-container>
    </div>
</ion-content>