import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// ionic
import { MenuController, Platform } from '@ionic/angular';

// ionic native
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';

// social logins
import { linkedInAuthConfig } from '../../settings/linkedin.settings';
import { OAuthService } from 'angular-oauth2-oidc';
import { OauthCordova } from "ng2-cordova-oauth/platform/cordova";
import { LinkedIn } from "ng2-cordova-oauth/core";
import { LangChangeEvent } from "@ngx-translate/core";

// extends
import { BasePage } from "../base";

// models
import { User } from "../../models/user";
import { Participant } from "../../models/participant";

// services
//import { PushPageService } from "../../services/push-page.service"; TODO
import { AuthenticationService } from "../../services/authentication.service";
import { ParticipantService } from "../../services/participant.service";
import { StorageService } from "../../services/storage.service";

// components
import { QrCodeReaderComponent } from '../../components/qr-code-reader/qr-code-reader.component';

// config
import { environment } from "../../../environments/environment";

@Component({
    selector: 'app-registration',
    templateUrl: './registration.page.html',
    styleUrls: ['./registration.page.scss'],
})
export class RegistrationPage extends BasePage implements OnInit {

    /**
     * user model
     *
     * @type User
     */
    public model: User = new User;

    /**
     * loaded state
     *
     * @type {boolean}
     */
    public loaded: boolean = false;

    /**
     * env configuration
     */
    public env = environment;

    /**
     * oauth service
     *
     * @type any
     */
    private oAuthServiceMobile: any;

    /**
     * available languages
     *
     * @type {any[]}
     */
    public langs = [];

    /**
     * which select type should be used.. alert/popover/action-sheet
     *
     * @type {string}
     */
    public selectType: string = 'popover';

    /**
     * invited status
     *
     * @type {boolean}
     */
    public invited: boolean = false;

    /**
     * linkedin provider
     *
     * @type {LinkedIn}
     */
    private linkedinProvider: LinkedIn = new LinkedIn({
        clientId: environment.linkedIn.clientId,
        appScope: ['email', 'profile', 'openid']
    });

    /**
     * if we should show community transfer checkbox
     *
     * @type {boolean}
     */
    public communityTransfer: boolean = false;

    /**
     * name of the event from event key
     *
     * @type {string}
     */
    public eventName: string = '';

    /**
     * validate state
     *
     * @type {boolean}
     */
    public validate: boolean = false;

    /**
     * Validation errors
     *
     * @type {Object}
     */
    public validationErrors: any = {};

    /**
     * Optional participant profile details
     *
     * @type {Object}
     */
    public details = [];

    /**
     * constructor
     *
     * @param signInWithApple
     * @param auth
     * @param menuCtrl
     * @param platform
     * @param AuthService
     * @param storage
     * @param ParticipantService
     * @param angulartics2
     * @param oAuthCordova
     */
    constructor(
        private signInWithApple: SignInWithApple,
        public route: ActivatedRoute,
        public auth: AuthenticationService,
        public menuCtrl: MenuController,
        public platform: Platform,
        public oauthService: OAuthService,
        public storage: StorageService,
        public participantService: ParticipantService,
        public oAuthCordova: OauthCordova
    ) {
        super();
        this.langs = this.plt.availableLangs;

        if (this.plt.sizeSm) {
            this.selectType = 'action-sheet';
        }

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.userLang = event.lang;
            this.model.salutation = null;
        });

        if (this.env.name == 'local') {
            this.model.password = 'test1234';
            this.model.password_confirmation = 'test1234';
        }
    }

    public canSubmit() {
        return true;
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() { // vs ionViewDidLoad?
        this.plt.hideSidebar = this.hideSidebar;
        this.menuCtrl.enable(false, 'authenticated');

        // get event key from url
        if (this.route.snapshot.params['event-key']) {
            this.model.event_key = decodeURIComponent(this.route.snapshot.params['event-key']);
        }

        // check if there is request for different start url
        if (this.route.snapshot.queryParams['redirect']) {
            this.plt.saveStartUrl(decodeURIComponent(this.route.snapshot.queryParams['redirect']));
        }

        // put some delay, because of logout action with animation on menu [app.component.ts]
        // TODO, maybe refactor
        setTimeout(() => {
            this.storage.get('token').then((token) => {
                if (token) {
                    this.auth.loginFromToken(token, 'token', this.plt.forceEventId);
                    // reset force event id
                    this.plt.forceEventId = null;
                    // redirect to dashboard if user is logged in
                    this.router.navigate([this.plt.defaultLink + '/home/info/matching']);
                }
            });
        }, 300);

        // NOTE[jg] - now solved in app.component
        // const forceLocale = this.route.snapshot.queryParams['forceLocale'];
        // if (!!forceLocale && this.langs.indexOf(forceLocale) !== -1) {
        //     this.userLang = forceLocale;
        //     // wont work without timeout
        //     setTimeout(() => {
        //         this.translate.use(forceLocale).subscribe(() => {
        //             this.init();
        //         });
        //     }, 250);
        // } else {
        this.init();
        // }

        this.loaded = true;
    }

    public initSetting() {
        // abstract method - will be set in ancestors
    }

    /**
     * init
     */
    public init() {
        // follow invitation - token must be added to every possible request from here
        const invitation_token = this.route.snapshot.queryParams['invitation_token'];
        if (!!invitation_token) {
            this.participantService.getByInvitationToken(invitation_token).subscribe((participant: Participant) => {
                this.model.copyDataFromParticipant(participant);
                if (participant.id) {
                    this.invited = true;
                    this.storage.set('invitation_token', invitation_token);
                    // we get info that this participant has transfer option if variable is boolean and not nulll
                    if (participant.transfer_accepted == false) {
                        this.communityTransfer = true;
                    }
                } else {
                    // invitation token is not valid
                    this.showError(this.translate.instant('ERROR_INVALID_INVITATION_TOKEN'));
                    this.router.navigate(['/login']);
                }
            });
        } else {
            this.storage.get('invitation_token').then((invitation_token) => {
                if (!!invitation_token) {
                    this.participantService.getByInvitationToken(invitation_token).subscribe((participant: Participant) => {
                        this.model.copyDataFromParticipant(participant);
                        if (participant.id) {
                            this.invited = true;
                            // we get info that this participant has transfer option if variable is boolean and not nulll
                            if (participant.transfer_accepted == false) {
                                this.communityTransfer = true;
                            }
                        } else {
                            // invitation token is not valid
                            this.storage.remove('invitation_token');
                            this.showError(this.translate.instant('ERROR_INVALID_INVITATION_TOKEN'));
                            this.router.navigate(['/login']);
                        }
                    });
                }
            });
        }

        // to be use later
        this.initSetting();
    }

    /**
     * lost password
     *
     * @return void
     */
    public goToLogin() {
        //this.nav.push('LostPasswordPage');
        this.router.navigate(['/login']);
    }

    /**
     * register
     *
     * @return void
     */
    public verifyEventKey() {
        if (this.model.event_key) {
            this.showLoading();

            this.auth.verifyEventKey(this.model.event_key).subscribe(
                success => {
                    this.hideLoading();
                    if (success.transfer_accepted == false) {
                        this.communityTransfer = true;
                        this.eventName = success.event_name;
                    } else {
                        this.communityTransfer = false;
                    }
                },
                error => {
                    this.overlayService.showError(error.error.message);
                });
        }
    }

    public beforeRegister() { }

    /**
     * register
     *
     * @return void
     */
    public register() {

        if (this.canSubmit()) {

            this.beforeRegister();
            this.showLoading();

            this.storage.get('invitation_token').then(
                (invitation_token) => {

                    this.validate = true;
                    let data = { ...this.model };
                    if (data.salutation == " ") {
                        delete data.salutation;
                    }

                    // src for tracking
                    let src = this.route.snapshot.queryParams['s'] || this.route.snapshot.queryParams['src'];
                    let srcId = this.route.snapshot.queryParams['sId'] || this.route.snapshot.queryParams['srcId'] || null;

                    this.auth.register(new User(<User>data), invitation_token, this.plt.is('cordova') ? 'mobile' : 'pwa', src, srcId, this.details).subscribe(
                        (success) => {

                            this.validate = false;
                            this.storage.remove('invitation_token');
                            // NOTE[jg] tracking for GA.. now disabled
                            //this.angulartics2.eventTrack.next({ action: 'Klick', properties: { category: 'Registrierung', label: 'Teilnehmer' }});
                            this.overlayService.showSuccess(success.message, true);
                            // do direct login for fully created accounts directly after registration
                            if (!!success.direct_registration) {
                                this.auth.login(this.model.email, this.model.password, '', this.plt.is('cordova') ? 'mobile' : 'pwa')
                                    .subscribe(
                                        (success) => {
                                            // login successful if there's a jwt token in the response
                                            const token = success.token as string;

                                            if (token) {
                                                this.auth.loginFromToken(token, 'login', this.plt.forceEventId);
                                                // reset force event id
                                                this.plt.forceEventId = null;
                                                this.storage.remove('invitation_token');
                                            }
                                        },
                                        (error) => {
                                            this.hideLoading();
                                            if (!error.error || !error.status) {
                                                this.overlayService.showConnectionProblems(error);
                                                return;
                                            }
                                        });
                            } else {
                                this.router.navigate(['/']);
                            }
                        },
                        (error) => {

                            this.validationErrors = {};
                            const data = error.error;

                            if (data.already_registered) {
                                this.overlayService.showConfirm(
                                    this.translate.instant('REGISTER_ALREADY_EXISTING'),
                                    this.translate.instant('REGISTER_ALREADY_EXISTING_HINT'),
                                    () => {
                                        if (this.model.event_key) {
                                            // redirect with event key
                                            this.router.navigate(['/login/access-key/' + encodeURIComponent(this.model.event_key)]);
                                        } else {
                                            this.router.navigate(['/login']);
                                        }
                                    },
                                    null,
                                    this.translate.instant('REGISTER_ALREADY_EXISTING_BUTTON')
                                );
                            } else {

                                if (data.fields) {
                                    for (const field in data.fields) {
                                        if (data.fields.hasOwnProperty(field)) {
                                            this.validationErrors[field] = data.fields[field].join(' ');
                                        }
                                    }
                                } else {
                                    this.overlayService.showError(data.message, !!data.static, 'public');
                                }
                            }

                            this.hideLoading();
                        });
                });
        }
    }

    /**
     * applo sign in
     *
     * @return void
     */
    public appleLogin() {
        this.signInWithApple.signin({
            requestedScopes: [
                ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
                ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
            ]
        })
            .then((res: AppleSignInResponse) => {
                // https://developer.apple.com/documentation/signinwithapplerestapi/verifying_a_user
                this.storage.get('invitation_token').then(
                    (invitation_token) => {
                        this.auth.appleLogin(res, invitation_token).subscribe(
                            () => {
                            },
                            (error) => {
                                this.hideLoading();
                                try {
                                    this.showError(error.error.message);
                                }
                                catch (e) {
                                    this.showError();
                                }
                            }
                        );
                    });
            })
            .catch((error: AppleSignInErrorResponse) => {
                // alert(error.code + ' ' + error.localizedDescription);
                console.error(error);
                this.showWarning(this.translate.instant('ERROR_AUTH_CANCELED'));
            });
    }

    /**
     * Register via linkedin
     *
     * @return void
     */
    public socialRegisterWeb(type: string) {

        // this.showLoading();

        if (this.platform.is('cordova')) {
            this.oAuthCordova.logInVia(this.linkedinProvider).then(
                (success) => {
                    this.storage.get('invitation_token').then(
                        (invitation_token) => {
                            let payload = { ...success, redirectUri: environment.redirectUri };
                            this.auth.socialLogin(payload, type, invitation_token).subscribe(
                                (success) => {
                                    // login successful if there's a jwt token in the response
                                    const token = success.token as string;

                                    if (token) {
                                        this.auth.loginFromToken(token, 'login', this.plt.forceEventId);
                                        // reset force event id
                                        this.plt.forceEventId = null;
                                        this.storage.remove('invitation_token');
                                    }
                                },
                                (error) => {
                                    this.hideLoading();
                                    try {
                                        this.showError(error.error.message);
                                    }
                                    catch (e) {
                                        this.showError();
                                    }
                                }
                            );
                        });
                }, () => {
                    this.showWarning(this.translate.instant('ERROR_AUTH_CANCELED'));
                });
        } else {
            // trigger linkedin login
            let payload = linkedInAuthConfig;
            payload.redirectUri += '&forceLocale=' + this.translate.currentLang;
            this.oauthService.configure(payload);
            this.oauthService.initLoginFlow();
        }
    }

    /**
     * open event detail popup
     *
     * @return void
     */
    public async showQRCodeReader() {

        this.routerExtService.softNavigate();

        const modal = await this.modalController.create({
            component: QrCodeReaderComponent,
            cssClass: 'qr-scan',
            componentProps: {

            }
        });

        modal.onWillDismiss().then((data) => {
            if (data.data) {
                if (data.data.action && data.data.action == 'close') {
                    this.routerExtService.softBack();
                }

                if (data.data.text) {
                    this.model.event_key = data.data.text;
                }
            }

        });

        return await modal.present();

    }
}
