import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';

// ionic
import { ModalController } from '@ionic/angular';

// other libraries
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

// rxjs
import { Subject, Subscription, interval } from "rxjs";
import { debounceTime, startWith } from 'rxjs/operators';
import { takeUntil } from "rxjs/operators";

// providers
import { OverlayService } from "../../services/overlay.service";
import { UserService } from "../../services/user.service";
import { VotingService } from "../../services/voting.service";
import { EventService } from "../../services/event.service";
import { PlatformService } from "../../services/platform.service";

// models
import { User } from "../../models/user";
import { Voting } from "../../models/voting";
import { Event } from "../../models/event";

@Component({
    selector: 'app-live-voting',
    templateUrl: './live-voting.component.html',
    styleUrls: ['./live-voting.component.scss'],
})
export class LiveVotingComponent implements OnInit, OnDestroy {

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
      * subscription for event changes subject
      *
      * @type {Subscription}
      */
    private subscriptionEvent: Subscription;

    /**
     * event
     *
     * @type {Event}
     */
    public event: Event;

    /**
     * current user
     *
     * @type {User}
     */
    public user: User;

    /**
     * current voting
     *
     * @type {Voting}
     */
    public voting: Voting;

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;

    /**
     * loading status
     *
     * @type {boolean}
     */
    public loading: boolean = false;

    /**
     * info about last update on users profile
     *
     * @type {Date}
     */
    public lastUpdated: Date;

    /**
     * Validation errors
     *
     * @type {{}}
     */
    public validationErrors = {};

    /**
     * show more description
     *
     * @type {boolean}
     */
    public showMore: boolean = false;

    /**
     * show more description
     *
     * @type {boolean}
     */
    public twoColumnImage: boolean = false;

    /**
     * if we want to display postpone option
     * @type {boolean}
     */
    @Input() showPostpone: boolean = false;

    /**
     * if we want to define which voting we are using
     * @type {number}
     */
    @Input() votingId: number;

    /**
     * Some answer is preselected
     * @type {boolean}
     */
    public selectedAnswer: boolean = false

    /**
     * constructor
     *
     * @param router
     * @param routeService
     * @param userService
     * @param eventService
     * @param translate
     * @param appEvents
     * @param alertService
     * @param conversationService
     * @param participantService
     */
    constructor(
        public plt: PlatformService,
        public router: Router,
        public userService: UserService,
        public eventService: EventService,
        public translate: TranslateService,
        public overlayService: OverlayService,
        public modalController: ModalController,
        public votingService: VotingService
    ) {
        this.userLang = this.translate.currentLang;
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() {
        this.loading = true;

        const source = interval(15000);

        source.pipe(
            startWith(5000),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(() => {
            if (this.user && this.user.selected_participant_id) {
                this.loadLiveVoting();
            }
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.userLang = event.lang;
        });

        this.userService.getCurrentUser().pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(0)
        ).subscribe(
            (user: User) => {

                this.user = user;

                if (this.user.selected_participant_id) {
                    this.loadLiveVoting();
                } else {
                    this.loading = false;
                }

                // cancel previous subscription
                if (this.subscriptionEvent) {
                    this.subscriptionEvent.unsubscribe();
                }

                this.subscriptionEvent = this.eventService.getCurrentEvent().pipe(
                    takeUntil(this.ngUnsubscribe),
                    debounceTime(0)
                ).subscribe((event) => {
                    this.event = event;
                });

            });
    }

    loadLiveVoting() {
        if (this.votingId) {
            this.votingService.getById(this.votingId, this.user.selected_participant.event_id).subscribe(
                (voting: Voting) => {
                    this.setVoting(voting);
                }, (error) => {
                    const data = error.error;
                    this.overlayService.showError(data.message);
                    this.close();
                });
        } else {
            this.votingService.getLiveVoting(this.user.selected_participant_id).subscribe(
                (voting: Voting) => {
                    this.setVoting(voting);
                }, (error) => {
                    const data = error.error;
                    this.overlayService.showError(data.message);
                    this.close();
                });
        }

    }

    private setVoting(voting) {
        if (voting) {
            // if there is no voting, or voting has changed
            if (!this.voting || this.voting.id != voting.id) {
                this.voting = new Voting(voting);
                // for case, there is an image, then show directly full version
                if (!!this.voting.description && this.voting.description.includes('<img')) {
                    this.showMore = true;
                    this.twoColumnImage = true;
                }
            }
        } else {
            this.voting = null;
        }

        this.loading = false;
    }

    // check if answers require free text option, otherwise
    // save voting option
    selectAnswer(answer) {
        if (answer.free_text) {
            this.selectedAnswer = true;
            answer.selected = true;
        } else {
            this.saveAnswer(answer);
        }
    }

    // unselecte answer option an list again all options
    unselectAnswer(answer) {
        this.selectedAnswer = false;
        answer.selected = false;
    }

    // store selected answer and submit it
    saveAnswer(answer) {
        this.loading = true;

        this.uploadVote({
            'answers': [answer]
        });
    }

    saveMultiAnswer() {
        this.validationErrors = [];
        let selected = this.voting.answers.filter((answer) => answer.selected);
        if (selected.length == 0) {
            this.validationErrors['answers'] = 'LIVE_VOTING_YOU_HAVE_TO_SELECT_AT_LEAST_ONE_OPTION';
        }
        else if (selected.length > this.voting.selectable_count) {
            if (this.voting.selectable_count == 1) {
                this.validationErrors['answers'] = 'LIVE_VOTING_MAXIMUM_SELECTED_ANSWER_REACHED_SINGLE';
            } else {
                this.validationErrors['answers'] = 'LIVE_VOTING_MAXIMUM_SELECTED_ANSWER_REACHED_MULTI';
            }
        } else {
            this.uploadVote({
                'answers': selected
            });
        }
    }

    uploadVote(answers) {
        this.votingService.vote(this.user.selected_participant_id, answers, this.votingId).subscribe(
            success => {
                // delete froala files
                this.overlayService.showSuccess(this.translate.instant('LIVE_VOTING_SUBMITED'));
                this.voting.votes = success.votes;

                // remove header notification from actual user object
                this.user.header_notifications = this.user.header_notifications.filter(notification => {
                    // return notification.action != 'participant/' + this.user.selected_participant_id + '/voting';
                    return notification.data.voting_id != this.voting.id;
                });

                // disconnect user subscription to avoid loading of voting info again
                // loadLiveVoting is called ing getCurrentUser + source interval
                this.ngUnsubscribe.next();
                this.ngUnsubscribe.complete();

                this.userService.setCurrentUser(this.user);

                this.loading = false;

                this.close();
            },
            error => {
                const data = error.error;

                if (data.fields) {
                    for (const field in data.fields) {
                        if (data.fields.hasOwnProperty(field)) {
                            this.validationErrors[field] = data.fields[field].join(' ');
                        }
                    }
                }

                this.overlayService.showError(data.message);

                // voting was paused or closed
                if (!data) {
                    this.voting = null;
                }

                this.loading = false;
            });
    }

    /**
     * get active route
     *
     * @param routename
     * @return {boolean}
     */
    activeRoute(routename: string): boolean {
        return this.router.url.indexOf(routename) > -1;
    }

    /**
     * get active route from array
     *
     * @param routenames
     * @return {boolean}
     */
    activeRoutes(routenames: [string]): boolean {
        return !!routenames.filter(routename => this.router.url.indexOf(routename) > -1).length;
    }

    close() {
        this.modalController.dismiss({ action: 'close' });
    }

    postpone() {
        this.modalController.dismiss({ action: 'postpone' });
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
