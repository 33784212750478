import { AuthConfig } from 'angular-oauth2-oidc';

  export const linkedInAuthConfig: AuthConfig = {

    // https://www.linkedin.com/oauth/.well-known/openid-configuration
    // {
    //     "issuer" : "https://www.linkedin.com",
    //     "authorization_endpoint" : "https://www.linkedin.com/oauth/v2/authorization",
    //     "token_endpoint" : "https://www.linkedin.com/oauth/v2/accessToken",
    //     "userinfo_endpoint" : "https://api.linkedin.com/v2/userinfo",
    //     "jwks_uri" : "https://www.linkedin.com/oauth/openid/jwks",
    //     "response_types_supported" : [ "code" ],
    //     "subject_types_supported" : [ "pairwise" ],
    //     "id_token_signing_alg_values_supported" : [ "RS256" ],
    //     "scopes_supported" : [ "openid", "profile", "email" ],
    //     "claims_supported" : [ "iss", "aud", "iat", "exp", "sub", "name", "given_name", "family_name", "picture", "email", "email_verified", "locale" ]
    //   }

    // Url of the Identity Provider
    issuer: 'https://www.linkedin.com',

    loginUrl: 'https://www.linkedin.com/oauth/v2/authorization',
    tokenEndpoint : 'https://www.linkedin.com/oauth/v2/accessToken',
    userinfoEndpoint: 'https://api.linkedin.com/v2/userinfo',

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/login?oauth=linkedin',

    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
    clientId: '78vsq6ziw3gfkz',

    // Just needed if your auth server demands a secret. In general, this
    // is a sign that the auth server is not configured with SPAs in mind
    // and it might not enforce further best practices vital for security
    // such applications.
    // dummyClientSecret: 'secret',

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: 'openid profile email',

    sessionChecksEnabled: true,

    showDebugInformation: true,

    oidc: true,
    disablePKCE: false
  };


//   linkedin: {
//     name: 'linkedin',
//     url: '/auth/linkedin',
//     authorizationEndpoint: 'https://www.linkedin.com/uas/oauth2/authorization',
//     scope: ['r_emailaddress'],
//     scopeDelimiter: ' ',
//     oauthType: '2.0',
//     popupOptions: { width: 527, height: 582 },
//     state: 'STATE'
// },