import { APP_ID, Inject, Pipe, PipeTransform } from '@angular/core';

import { PlatformService } from "../../services/platform.service";


@Pipe({
    name: 'fullImage'
})
export class FullImagePipe implements PipeTransform {

    constructor(
        public plt: PlatformService
    ) { }

    public transform(value: string, force: boolean = false): string {
        if (value) {

            if (this.plt.sizeSm  || force) {
                return value.replace('_preview.', '.');
            }
        }

        return value;
    }

}
