import { APP_ID, Inject, Pipe, PipeTransform } from '@angular/core';

import { PlatformService } from "../../services/platform.service";
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

    constructor(
        @Inject(APP_ID) private appId: string,
        public plt: PlatformService
    ) { }

    public transform(value: string, target: string = '_system'): string {
        if (value) {

            // open all existing link in new tab
            if (this.plt.is('cordova')) {
                // cordova is not working with third options attribute
                value = value.replace(new RegExp('<a href="([^"]*)" [^>]*>', 'gim'), '<a href="$1" onclick="window.open(this.href, \'_system\');return false">');
            } else {
                value = value.replace(new RegExp('<a href="([^"]*)" [^>]*>', 'gim'), '<a href="$1" onclick="window.open(this.href, \'_system\', \'noreferrer\');return false">');
            }

            // URLs starting with http://, https://, or ftp://, but not as href/src attributes for existing a elelments
            let patternHttp = /(^|[^"])(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/\(\)\{\}%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
            let replaceHttp = '$1<a href="$2" onclick="window.open(this.href, \'' + target + '\', \'noreferrer\');return false">$2</a>';
            if (this.plt.is('cordova')) {
                // cordova is not working with third options attribute
                replaceHttp = '$1<a href="$2" onclick="window.open(this.href, \'' + target + '\');return false">$2</a>';
            }

            value = value.replace(patternHttp, replaceHttp);

            // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
            let patternWww = /(^|[^\/])(www\.[-A-Z0-9+&@#\/\(\)\{\}%?=~_|!:,.;]+(\b|$))/gim;
            let replaceWww = '$1<a href="http://$2" onclick="window.open(this.href, \'' + target + '\', \'noreferrer\');return false">$2</a>';
            if (this.plt.is('cordova')) {
                // cordova is not working with third options attribute
                replaceWww = '$1<a href="http://$2" onclick="window.open(this.href, \'' + target + '\');return false">$2</a>';
            }

            value = value.replace(patternWww, replaceWww);

            // replace internal links also in native mobile app, when app is running on localhost
            // so we will check our common pwa domain for links

            // we need to use full subdomains, in case there would be some content in other subdomain, then it can cause problems
            // best woudl be to add also custom domain for event, but this is now not returned via API

            let customDomains = [
                window.location.host,
                // tb. and tqb. prefix will be probably used mostly by testing boards, so we will conver those in this case also
                ...environment.customDomains
            ];

            customDomains.forEach((domain) => {
                let patternTarget = new RegExp('<a href="(https?:\/\/[a-zA-Z0-9-_]*' + domain.replace('.', '\.') + ')([^<]*)" [^>]*>', 'gi');
                // let replaceTarget = '<a href="' + this.plt.defaultLink + '$2">';
                let replaceTarget = '<a href="' + this.plt.defaultLink + '$2" onclick="event.stopPropagation();new BroadcastChannel(\'open-link\').postMessage({url:\'' + this.plt.defaultLink + '$2\', broadcastSender:\'' + this.appId + '\'});return false">';

                value = value.replace(patternTarget, replaceTarget);
            });


            /*
                this pattern matches:
                - http://www.youtube.com/v/0zM3nApSv11?fs=1&amp;hl=en_US&amp;rel=0
                - http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
                - http://www.youtube.com/embed/0zM3nApSvMg?rel=0
                - http://www.youtube.com/watch?v=0zM3nApSvMg
                - http://youtu.be/0zM3nApSvMg
                - https://vimeo.com/265045525
                - https://vimeo.com/evemt/265045525
            */

            let videoProviderDomains = [
                'youtube.com\/watch',
                'youtube.com\/embed',
                'youtube.com\/live',
                'vimeo.com\/[0-9]{6,}',
                'vimeo.com\/event\/[0-9]{6,}',
                'youtu.be'
            ];

            // open those videos in fullscreen modal and embed video
            videoProviderDomains.forEach((domain) => {
                let patternTarget = new RegExp('<a href="(https?:\/\/[a-zA-Z0-9-_.]*' + domain.replace('.', '\.') + '[^"]*)" [^>]*>', 'gi');
                let replaceTarget = '<a href="?video_link=$1" onclick="event.stopPropagation();new BroadcastChannel(\'open-detail\').postMessage({video:\'$1\', broadcastSender:\'' + this.appId + '\'});return false">';

                value = value.replace(patternTarget, replaceTarget);
            });

        }

        return value;
    }

}
