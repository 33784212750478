export class CustomLabelTranslation {
    id: number;
    locale: string;
    name: string;
    external_link: string;

    /**
     * constructor
     *
     * @param {CustomLabelTranslation} init
     */
    public constructor(init?: CustomLabelTranslation) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
