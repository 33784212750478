import { Keyword } from "./keyword";
import { Event } from "./event";
import { Feed } from "./feed";
import { ChatGroup } from "./chat-group";
import { ParticipantDetail } from "./participant-detail";
import { Blacklist } from "./blacklist";
import { EventSetting } from "./event-setting";
import { Group } from "./group";
import * as moment from "moment/moment";

export class Participant {
    id: number;
    user_id: number;
    event_id: number;
    title: string;
    firstname: string;
    lastname: string;
    profession: string;
    company: string;
    additional_company: string;
    street: string;
    streetnr: string;
    zip: string;
    city: string;
    country: string;
    countryName: string;
    description: string;
    email: string;
    public_email: string;
    phone: string;
    picture: string;
    picture_url: string;
    linkedin_profile: string;
    xing_profile: string;
    not_contactable: false;
    not_contactable_msg: false;
    invited_at: string;
    date_invited_at: Date = new Date();
    registered_at: string;
    date_registered_at: Date = new Date();
    blacklist: Blacklist = new Blacklist();
    group: Group;
    invisible: boolean = false;

    details: ParticipantDetail[] = [];

    groupedDetails = [];
    groupedDetailsDeleted = [];

    detailTypes = [
        {
            name: 'paragraph',
            icon: 'text',
            group: [
                'paragraph'
            ]
        },
        {
            name: 'image',
            icon: 'image',
            group: [
                'image'
            ]
        },
        {
            name: 'cv',
            icon: 'cv',
            group: [
                'from',
                'to',
                'company',
                'position'
            ]
        },
        {
            name: 'link',
            icon: 'link',
            group: [
                'link'
            ]
        }
    ];

    event: Event;
    keywords: Keyword[] = [];
    selected = false;
    matches: Participant[];

    matching_factor = 0;
    matching_keywords = 0;
    total_keywords = 0;
    pivot: any;
    likes: Participant[];
    liked = false;
    liked_by: Participant[];
    bookmarks: Participant[];
    bookmarked = false;
    visited_at: string;
    date_visited_at: Date = new Date();
    set_global = false;
    hidden = false;
    feeds: Feed[];

    full_name: string;

    group_id: number;

    appointments: [];

    likeLoading: boolean = false;
    bookmarkLoading: boolean = false;

    transfer_accepted: boolean;

    keywordsRequired: boolean = false;

    wizard_status: number = 0;

    // available coins for profile
    current_coins: number = 0;

    // eventbrite ticket class
    eventbrite_ticket: string;

    // token for usage in external applications
    external_token: string;

    is_moderator: boolean = false;
    is_owner: boolean = false;

    event_setting: EventSetting = new EventSetting();

    updated_at: string;

    /**
     * constructor
     *
     * @param {Participant} init
     */
    public constructor(init?: Participant, user?, resetDetails: boolean = false) {
        if (!!init) {
            Object.assign(this, init);

            this.date_visited_at = moment.utc(this.visited_at).toDate();

            if (this.event_id) {
                this.event = new Event(this.event, user);
            }

            if (this.group_id) {
                let group = this.group || this.event.groups.find(item => item.id == this.group_id);
                if (group) {
                    this.group = new Group(group);
                }
            }

            if (init.blacklist) {
                this.blacklist = new Blacklist(init.blacklist);
            } else {
                this.blacklist = new Blacklist();
            }

            if (isNaN(parseInt(this.id.toString()))) {
                this.id = parseInt(this.id.toString());
            }

            // feeds
            if (init.feeds) {
                this.feeds = init.feeds.map(feed => new Feed(feed));
            }

            // load keywords
            if (this.keywords) {
                this.keywords.forEach((keyword, keywordIndex) => {
                    this.keywords[keywordIndex] = new Keyword(keyword);
                });
            }

            // load details
            if (this.details && (resetDetails || !this.groupedDetails?.length)) {

                if (resetDetails || !this.groupedDetails) {
                    this.groupedDetails = [];
                }

                let groupedDetails = [];

                this.details.forEach((detail) => {
                    if (!groupedDetails.hasOwnProperty(detail.group_index)) {
                        // create default options object in case, if it is missing
                        if (!detail.options) {
                            detail.options = {};
                        }
                        groupedDetails[detail.group_index] = {
                            deleted: false,
                            visible: detail.options.menu ? false : true,
                            type: detail.group_type,
                            line: detail.options.line ? true : false,
                            // set menu to false for all types except [modal-window, personalized-link], those has to be true
                            menu: (detail.group_type == 'modal-window' || detail.group_type == 'personalized-link') ? true : false,
                            fixed: detail.options.fixed ? true : false,
                            details: []
                        };
                    }

                    groupedDetails[detail.group_index].details.push(new ParticipantDetail(detail));
                });

                // check if there is some content, to eventually disable line when group is empty
                groupedDetails.forEach((group) => {
                    let visible = false;
                    group.details.forEach((detail) => {
                        if (detail.value) {
                            visible = true;
                        }
                    });
                    if (!visible) {
                        // there is nothing to show, so we will disable the line
                        group.visible = false;
                    }
                    // add final grouped detail to final array
                    this.groupedDetails.push(group);
                });
            }

            if (this.group) {
                this.event_setting = new EventSetting(this.group.event_setting);
            }
            else if (this.group_id && this.event && this.event.groups) {
                let group = this.event.groups.find(item => item.id == this.group_id);
                if (group) {
                    this.event_setting = new EventSetting(group.event_setting);
                }
            }
            else if (this.event) {
                this.event_setting = new EventSetting(this.event.setting);
            }

        }
    }

    public getFullName() {
        return this.firstname + ' ' + this.lastname;
    }

    public showWizard(): number {
        let status = 0;

        if (this.event_setting.profile_setting) {
            this.event_setting.profile_setting.forEach((rule) => {
                if (rule.required && status == 0) {
                    switch (rule.type) {
                        case 'picture':
                        case 'public_email':
                        case 'title':
                        case 'profession':
                        case 'company':
                        case 'country':
                        case 'phone':
                            if (!this[rule.type]) {
                                status = 1;
                            }
                            break;
                        case 'city':
                            if (!this.city || !this.zip) {
                                status = 1;
                            }
                            break;
                        case 'street':
                            if (!this.street || !this.streetnr) {
                                status = 1;
                            }
                            break;
                        case 'keywords':
                            // event matching needs to be enabled + there must be some keywords to select
                            if (this.event.matching_status && this.keywords.length == 0 && status == 0) {
                                status = 2;
                                this.keywordsRequired = true;
                            }
                            break;
                    }
                }
            })
        }

        return status;
    }

    /**
     * set the is_moderator property
     * @param {ChatGroup} chatGroup
     */
    public setIsModerator(chatGroup: ChatGroup) {
        if (!chatGroup) {
            return false;
        }

        this.is_moderator = chatGroup.admins.some((item) => {
            return item.participant_id == this.id && item.role == 'moderator';
        });
    }

    /**
     * set the is_owner property
     * @param {ChatGroup} chatGroup
     */
    public setIsOwner(chatGroup: ChatGroup) {
        if (!chatGroup) {
            return false;
        }

        this.is_owner = chatGroup.admins.some((item) => {
            return item.participant_id == this.id && item.role == 'owner';
        });
    }

    /**
     * check customized profile rules for given participant
     *
     * @param participant
     * @param type
     * @param attr
     *
     * @return {boolean}
     */
    public checkProfileRule(participant: Participant, type: string, attr: string): boolean {
        // default is to open all..
        let allowed = true;
        let setting;

        if (participant.event_setting) {
            setting = participant.event_setting;
        }
        else if (participant.group_id) {
            let group = participant.group || this.event.groups.find(item => item.id == participant.group_id);
            if (group) {
                setting = group.event_setting;
            }
        }
        else {
            setting = this.event.setting;
        }

        if (setting) {
            setting.profile_setting.forEach((rule) => {
                if (rule.type == type && rule.hasOwnProperty(attr)) {
                    allowed = !!rule[attr];
                    // required is neutralizing edit for item rule
                    // required items need to be visible for user
                    if (attr != 'required' && !!rule['required']) {
                        allowed = true;
                    }
                }
            });
        }

        return allowed;
    }
}
