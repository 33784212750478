<div *ngIf="!loading"
     class="content-box"
     [ngClass]="{'wizard': wizard}">
    <h1 *ngIf="!wizard">{{ 'PROFILE_EDIT_TITLE' | translate }}</h1>
    <div class="content-box-inner">
        <div class="scroll-section">
            <div class="content-header">
                <ion-row class="ion-no-padding">
                    <ion-col size="12"
                             size-md="7"
                             class="ion-no-padding">
                        <ion-item>
                            <ion-avatar slot="start"
                                        *ngIf="editParticipant">
                                <img img-avatar
                                     [src]="editParticipant.picture_url">
                                <div *ngIf="editParticipant.picture_url"
                                     class="remove"
                                     (click)="deleteImage()"></div>

                                <ion-badge *ngIf="plt.sizeSm && group && group.show_label && group.label.length"
                                           class="group-label">{{ group.label | customTranslate}}
                                </ion-badge>
                            </ion-avatar>
                            <ion-label>
                                <h2>{{ participant.firstname }} {{ participant.lastname }}
                                    <!-- <span>(<a (click)="showAttendeeDetail()"
                               class="pointer">{{ 'PROFILE_EDIT_PREVIEW' | translate }}</a>)
                        </span> -->

                                    <ion-badge *ngIf="!plt.sizeSm && group && group.show_label && group.label.length"
                                               class="group-label">
                                        {{ group.label | customTranslate }}
                                    </ion-badge>

                                </h2>
                                <span>{{ participant.email }}</span>
                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'picture', 'edit')">
                                    <ng-container *ngIf="!plt.is('cordova') && !plt.sizeSm">
                                        <dropzone [message]="''"
                                                  [config]="dropZoneConfig"
                                                  (success)="onUploadSuccess($event)"
                                                  (error)="onUploadError($event)"
                                                  (sending)="onSending($event)">
                                        </dropzone>
                                        <span *ngIf="validate && validationErrors['picture']"
                                              class="error-message ion-text-left">
                                            {{ validationErrors['picture'] }}
                                        </span>
                                    </ng-container>

                                    <ng-container *ngIf="plt.is('cordova') && !plt.sizeSm">
                                        <div class="image-actions">
                                            <button class="pure-btn"
                                                    (click)="openCamera()">
                                                <ion-icon name="camera"
                                                          color="light-blue"></ion-icon>
                                            </button>
                                            <button class="pure-btn"
                                                    (click)="openAlbums()">
                                                <ion-icon name="image"
                                                          color="light-blue"></ion-icon>
                                            </button>
                                        </div>

                                        <div class="uploading-spinner"
                                             *ngIf="uploading">
                                            <ion-spinner></ion-spinner>
                                        </div>
                                        <!-- END :: initial -->
                                        <div class="progress-container"
                                             *ngIf="uploading">
                                            <progress class="progress"
                                                      max="100"
                                                      value="{{ progress }}">
                                            </progress>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ion-label>
                        </ion-item>
                    </ion-col>
                    <ion-col *ngIf="plt.sizeSm"
                             size="12"
                             class="ion-no-padding">
                        <ng-container *ngIf="!plt.is('cordova')">
                            <dropzone [message]="''"
                                      [config]="dropZoneConfig"
                                      (success)="onUploadSuccess($event)"
                                      (error)="onUploadError($event)"
                                      (sending)="onSending($event)">
                            </dropzone>
                        </ng-container>
                        <ng-container *ngIf="plt.is('cordova')">
                            <div class="image-actions">
                                <button class="pure-btn"
                                        (click)="openCamera()">
                                    <ion-icon name="camera"
                                              color="light-blue">
                                    </ion-icon>
                                </button>
                                <button class="pure-btn"
                                        (click)="openAlbums()">
                                    <ion-icon name="image"
                                              color="light-blue">
                                    </ion-icon>
                                </button>
                            </div>

                            <div class="uploading-spinner"
                                 *ngIf="uploading">
                                <ion-spinner></ion-spinner>
                            </div>
                            <!-- END :: initial -->
                            <div class="progress-container"
                                 *ngIf="uploading">
                                <progress class="progress"
                                          max="100"
                                          value="{{ progress }}">
                                </progress>
                            </div>
                        </ng-container>
                        <ion-row *ngIf="validate && validationErrors['picture']">
                            <ion-col>
                                <br />
                                <span class="error-message">
                                    {{ validationErrors['picture'] }}
                                </span>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                    <ion-col *ngIf="!wizard"
                             size="12"
                             size-md="5"
                             class="ion-no-padding ion-text-right">
                        <ion-row class="ion-no-padding">
                            <ion-col size="12"
                                     size-md="8"
                                     class="ion-no-padding ion-text-right">
                                <ion-button *ngIf="user.template"
                                            (click)="useTemplate()"
                                            class="btn-close">
                                    {{ 'PROFILE_USE_TEMPLATE' | translate }}
                                </ion-button>
                            </ion-col>
                            <ion-col size="12"
                                     size-md="4"
                                     class="ion-no-padding ion-text-right">
                                <ion-button (click)="showAttendeeDetail()"
                                            class="btn-close">
                                    {{ 'PROFILE_EDIT_PREVIEW' | translate }}
                                </ion-button>
                            </ion-col>
                            <ion-col size="12"
                                     size-md="7"
                                     class="ion-no-padding ion-text-right">
                                <ion-button *ngIf="!user.selected_participant?.event.is_homepage_content_matching && user.selected_participant?.event.matching_status"
                                            (click)="navigate(plt.defaultLink + '/keywords/manage-keywords')"
                                            class="btn-close btn-keyword-settings">
                                    {{ 'BTN_KEYWORD_SETTINGS' | translate }}
                                </ion-button>
                            </ion-col>
                            <ion-col size="12"
                                     size-md="5"
                                     class="ion-no-padding ion-text-right">
                                <ion-button *ngIf="user.selected_participant?.event.enable_invisible_profile && !editParticipant.invisible"
                                            (click)="confirmHideProfile(true)"
                                            class="btn-close btn-keyword-settings">
                                    {{ 'PROFILE_HIDE_PROFILE' | translate }}
                                </ion-button>
                                <ion-button *ngIf="user.selected_participant?.event.enable_invisible_profile && editParticipant.invisible"
                                            (click)="hideProfile(false)"
                                            class="btn-close btn-keyword-settings">
                                    {{ 'PROFILE_UNHIDE_PROFILE' | translate }}
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </div>
            <div class="content-data"
                 [ngClass]="{'skipping': skip}">
                <form method="get"
                      #f="ngForm"
                      role="form"
                      (ngSubmit)="setParticipant(f.form.valid)"
                      class="form-horizontal">
                    <ion-grid class="ion-no-padding">
                        <ion-row>
                            <ion-col size="12"
                                     size-md="6"
                                     class="ion-no-padding ion-padding-end">
                                <h2>{{ 'PROFILE_EDIT_PERSONAL_INFORMATION' | translate}}</h2>
                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'public_email', 'visible')">
                                    <ion-item [ngClass]="{ 'has-error': (validate && validationErrors['public_email']) || ((public_email.touched || validate) && !public_email.valid) }">
                                        <ion-label *ngIf="plt.sizeMd"
                                                   position="stacked">
                                            {{ 'PUBLIC_EMAIL' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'public_email', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-label *ngIf="!plt.sizeMd"
                                                   position="fixed">
                                            {{ 'PUBLIC_EMAIL' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'public_email', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-input type="email"
                                                   placeholder="{{ 'EMAIL_PLACEHOLDER' | translate }}"
                                                   name="public_email"
                                                   (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center')"
                                                   (ionBlur)="plt.checkResize(false)"
                                                   inputmode="email"
                                                   spellcheck="false"
                                                   autocapitalize="off"
                                                   #public_email="ngModel"
                                                   id="public-email-input"
                                                   [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'public_email', 'edit')"
                                                   [(ngModel)]="editParticipant.public_email"
                                                   pattern='^(([^<>\(\)\[\]\\.,;:\s@"]+(\.[^<>\(\)\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                                                   [required]="user.selected_participant?.checkProfileRule(editParticipant, 'public_email', 'required')">
                                        </ion-input>
                                    </ion-item>
                                    <span *ngIf="(public_email.touched || validate) && !public_email.valid"
                                          class="error-message">
                                        {{ 'VALIDATION_EMAIL' | translate }}
                                    </span>
                                    <span *ngIf="validate && validationErrors['public_email']"
                                          class="error-message">
                                        {{ validationErrors['public_email'] }}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'title', 'visible')">
                                    <ion-item [ngClass]="{ 'has-error': (validate && validationErrors['title']) || ((title.touched || validate) && !title.valid) }">
                                        <ion-label *ngIf="plt.sizeMd"
                                                   position="stacked">
                                            {{ 'TITLE' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'title', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-label *ngIf="!plt.sizeMd"
                                                   position="fixed">
                                            {{ 'TITLE' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'title', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-input type="text"
                                                   placeholder="{{ 'TITLE_PLACEHOLDER' | translate }}"
                                                   name="title"
                                                   (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                   (ionBlur)="plt.checkResize(false)"
                                                   inputmode="text"
                                                   spellcheck="false"
                                                   autocapitalize="off"
                                                   #title="ngModel"
                                                   [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'title', 'edit')"
                                                   [(ngModel)]="editParticipant.title"
                                                   [required]="user.selected_participant?.checkProfileRule(editParticipant, 'title', 'required')">
                                        </ion-input>
                                    </ion-item>
                                    <span *ngIf="(title.touched || validate) && !title.valid"
                                          class="error-message">
                                        {{ 'VALIDATION_TITLE' | translate }}
                                    </span>
                                    <span *ngIf="validate && validationErrors['title']"
                                          class="error-message">
                                        {{ validationErrors['title'] }}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'profession', 'visible')">
                                    <ion-item [ngClass]="{ 'has-error': (validate && validationErrors['profession']) || ((profession.touched || validate) && !profession.valid) }">
                                        <ion-label *ngIf="plt.sizeMd"
                                                   position="stacked">
                                            {{ 'PROFESSION' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'profession', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-label *ngIf="!plt.sizeMd"
                                                   position="fixed">
                                            {{ 'PROFESSION' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'profession', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-input type="text"
                                                   placeholder="{{ 'PROFESSION_PLACEHOLDER' | translate }}"
                                                   name="profession"
                                                   (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                   (ionBlur)="plt.checkResize(false)"
                                                   inputmode="text"
                                                   spellcheck="false"
                                                   autocapitalize="off"
                                                   #profession="ngModel"
                                                   [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'profession', 'edit')"
                                                   [(ngModel)]="editParticipant.profession"
                                                   [required]="user.selected_participant?.checkProfileRule(editParticipant, 'profession', 'required')">
                                        </ion-input>
                                    </ion-item>
                                    <span *ngIf="(profession.touched || validate) && !profession.valid"
                                          class="error-message">
                                        {{ 'VALIDATION_PROFESSION' | translate }}
                                    </span>
                                    <span *ngIf="validate && validationErrors['profession']"
                                          class="error-message">
                                        {{ validationErrors['profession'] }}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'company', 'visible')">
                                    <ion-item [ngClass]="{ 'has-error': (validate && validationErrors['company']) || ((company.touched || validate) && !company.valid) }">
                                        <ion-label *ngIf="plt.sizeMd"
                                                   position="stacked">
                                            {{ 'COMPANY' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'company', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-label *ngIf="!plt.sizeMd"
                                                   position="fixed">
                                            {{ 'COMPANY' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'company', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-input type="text"
                                                   placeholder="{{ 'COMPANY_PLACEHOLDER' | translate }}"
                                                   name="company"
                                                   (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                   (ionBlur)="plt.checkResize(false)"
                                                   inputmode="text"
                                                   spellcheck="false"
                                                   autocapitalize="off"
                                                   #company="ngModel"
                                                   [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'company', 'edit')"
                                                   [(ngModel)]="editParticipant.company"
                                                   [required]="user.selected_participant?.checkProfileRule(editParticipant, 'company', 'required')">
                                        </ion-input>
                                    </ion-item>
                                    <span *ngIf="(company.touched || validate) && !company.valid"
                                          class="error-message">
                                        {{ 'VALIDATION_COMPANY' | translate }}
                                    </span>
                                    <span *ngIf="validate && validationErrors['company']"
                                          class="error-message">
                                        {{ validationErrors['company'] }}
                                    </span>
                                    <!-- <ion-item [ngClass]="{ 'has-error': validate && validationErrors['description'] }">
                                <ion-label *ngIf="plt.sizeMd"
                                           position="stacked">
                                    {{ 'ABOUT_ME' | translate }}
                                </ion-label>
                                <ion-label *ngIf="!plt.sizeMd"
                                           position="fixed">
                                    {{ 'ABOUT_ME' | translate }}
                                </ion-label>
                                <ion-textarea placeholder="{{ 'ABOUT_ME_PLACEHOLDER' | translate }}"
                                              name="description"
                                              id="description-textarea"
                                              inputmode="text"
                                              spellcheck="false"
                                              autocapitalize="off"
                                              #description="ngModel"
                                              [(ngModel)]="editParticipant.description">
                                </ion-textarea>
                            </ion-item>
                            <span *ngIf="validate && validationErrors['description']"
                                  class="error-message">
                                {{ validationErrors['description'] }}
                            </span> -->
                                </ng-container>
                            </ion-col>
                            <!-- <ion-col align-self-center>

                      </ion-col> -->
                            <ion-col size="12"
                                     size-md="6"
                                     class="ion-no-padding ion-padding-start">
                                <h2>{{ 'PROFILE_EDIT_PERSONAL_CONTACT' | translate }}</h2>
                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'street', 'visible')">
                                    <ion-item>
                                        <ion-label *ngIf="plt.sizeMd"
                                                   position="stacked">
                                            {{ 'STREET' | translate }} / {{ 'STREETNR' | translate}}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'street', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-label *ngIf="!plt.sizeMd"
                                                   position="fixed">
                                            {{ 'STREET' | translate }} / {{ 'STREETNR' | translate}}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'street', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-row class="ion-no-padding">
                                            <ion-col size="8"
                                                     [ngClass]="{ 'has-error': (validate && validationErrors['street']) || ((street.touched || validate) && !street.valid) }"
                                                     class="ion-no-padding ion-padding-end">
                                                <ion-input type="text"
                                                           placeholder="{{ 'STREET_PLACEHOLDER' | translate }}"
                                                           name="street"
                                                           (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                           (ionBlur)="plt.checkResize(false)"
                                                           inputmode="text"
                                                           spellcheck="false"
                                                           autocapitalize="off"
                                                           #street="ngModel"
                                                           [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'street', 'edit')"
                                                           [(ngModel)]="editParticipant.street"
                                                           [required]="user.selected_participant?.checkProfileRule(editParticipant, 'street', 'required')">
                                                </ion-input>
                                            </ion-col>
                                            <ion-col size="4"
                                                     [ngClass]="{ 'has-error': (validate && validationErrors['streetnr']) || ((streetnr.touched || validate) && !streetnr.valid) }"
                                                     class="ion-no-padding">
                                                <ion-input type="text"
                                                           placeholder="{{ 'STREETNR_PLACEHOLDER' | translate }}"
                                                           name="streetnr"
                                                           (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                           (ionBlur)="plt.checkResize(false)"
                                                           inputmode="text"
                                                           spellcheck="false"
                                                           autocapitalize="off"
                                                           #streetnr="ngModel"
                                                           [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'street', 'edit')"
                                                           [(ngModel)]="editParticipant.streetnr"
                                                           [required]="user.selected_participant?.checkProfileRule(editParticipant, 'street', 'required')">
                                                </ion-input>
                                            </ion-col>
                                        </ion-row>
                                    </ion-item>
                                    <span *ngIf="((street.touched || validate) && !street.valid) || ((streetnr.touched || validate) && !streetnr.valid)"
                                          class="error-message">
                                        <span *ngIf="(street.touched || validate) && !street.valid">
                                            {{ 'VALIDATION_STREET' | translate }}
                                        </span>
                                        <span *ngIf="(streetnr.touched || validate) && !streetnr.valid">
                                            {{ 'VALIDATION_STREETNR' | translate }}
                                        </span>
                                    </span>
                                    <span *ngIf="validate && (validationErrors['street'] || validationErrors['streetnr'])"
                                          class="error-message">
                                        <span *ngIf="validationErrors['street']">
                                            {{ validationErrors['street'] }}
                                        </span>
                                        <span *ngIf="validationErrors['streetnr']">
                                            {{ validationErrors['streetnr'] }}
                                        </span>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'city', 'visible')">
                                    <ion-item>
                                        <ion-label *ngIf="plt.sizeMd"
                                                   position="stacked">
                                            {{ 'POSTCODE' | translate }} / {{ 'CITY' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'city', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-label *ngIf="!plt.sizeMd"
                                                   position="fixed">
                                            {{ 'POSTCODE' | translate }} / {{ 'CITY' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'city', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-row class="ion-no-padding">
                                            <ion-col size="4"
                                                     [ngClass]="{ 'has-error': (validate && validationErrors['zip']) || ((zip.touched || validate) && !zip.valid) }"
                                                     class="ion-no-padding ion-padding-end">
                                                <ion-input type="text"
                                                           placeholder="{{ 'POSTCODE_PLACEHOLDER' | translate }}"
                                                           name="zip"
                                                           (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                           (ionBlur)="plt.checkResize(false)"
                                                           inputmode="text"
                                                           spellcheck="false"
                                                           autocapitalize="off"
                                                           #zip="ngModel"
                                                           [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'city', 'edit')"
                                                           [(ngModel)]="editParticipant.zip"
                                                           [required]="user.selected_participant?.checkProfileRule(editParticipant, 'city', 'required')">
                                                </ion-input>
                                            </ion-col>
                                            <ion-col size="8"
                                                     [ngClass]="{ 'has-error': (validate && validationErrors['city']) || ((city.touched || validate) && !city.valid) }"
                                                     class="ion-no-padding">
                                                <ion-input type="text"
                                                           placeholder="{{ 'CITY_PLACEHOLDER' | translate }}"
                                                           name="city"
                                                           (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                           (ionBlur)="plt.checkResize(false)"
                                                           inputmode="text"
                                                           spellcheck="false"
                                                           autocapitalize="off"
                                                           #city="ngModel"
                                                           [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'city', 'edit')"
                                                           [(ngModel)]="editParticipant.city"
                                                           [required]="user.selected_participant?.checkProfileRule(editParticipant, 'city', 'required')">
                                                </ion-input>
                                            </ion-col>
                                        </ion-row>
                                    </ion-item>
                                    <span *ngIf="((zip.touched || validate) && !zip.valid) || ((city.touched || validate) && !city.valid)"
                                          class="error-message">
                                        <span *ngIf="(zip.touched || validate) && !zip.valid">
                                            {{ 'VALIDATION_ZIP' | translate }}
                                        </span>
                                        <span *ngIf="(city.touched || validate) && !city.valid">
                                            {{ 'VALIDATION_CITY' | translate }}
                                        </span>
                                    </span>
                                    <span *ngIf="validate && (validationErrors['zip'] || validationErrors['city'])"
                                          class="error-message">
                                        <span *ngIf="validationErrors['zip']">
                                            {{ validationErrors['zip'] }}
                                        </span>
                                        <span *ngIf="validationErrors['city']">
                                            {{ validationErrors['city'] }}
                                        </span>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'country', 'visible')">
                                    <ng-container *ngIf="!countryDropdown">
                                        <ion-item [ngClass]="{'has-error': (country.touched || validate) && (!country.valid || validationErrors['country']) }">
                                            <ion-label *ngIf="plt.sizeMd"
                                                       position="stacked">
                                                {{ 'COUNTRY' | translate }}
                                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'country', 'required')">*</ng-container>
                                            </ion-label>
                                            <ion-label *ngIf="!plt.sizeMd"
                                                       position="fixed">
                                                {{ 'COUNTRY' | translate }}
                                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'country', 'required')">*</ng-container>
                                            </ion-label>

                                            <ion-input type="text"
                                                       placeholder="{{ 'COUNTRY_PLACEHOLDER' | translate }}"
                                                       name="country"
                                                       (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                       (ionBlur)="plt.checkResize(false)"
                                                       inputmode="text"
                                                       spellcheck="false"
                                                       autocapitalize="off"
                                                       #country="ngModel"
                                                       [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'country', 'edit')"
                                                       [(ngModel)]="editParticipant.country"
                                                       [required]="user.selected_participant?.checkProfileRule(editParticipant, 'country', 'required')">
                                            </ion-input>
                                        </ion-item>

                                        <span *ngIf="(country.touched || validate) && !country.valid"
                                              class="error-message">
                                            {{ 'VALIDATION_COUNTRY' | translate }}
                                        </span>
                                        <span *ngIf="validate && validationErrors['country']"
                                              class="error-message">
                                            {{ validationErrors['country'] }}
                                        </span>
                                    </ng-container>

                                    <ion-item *ngIf="countryDropdown"
                                              class="autocomplete"
                                              [ngClass]="{'has-error': (country.touched || validate) && (!country.valid || validationErrors['country']) }">
                                        <ion-label *ngIf="plt.sizeMd"
                                                   position="stacked">
                                            {{ 'COUNTRY' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'country', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-label *ngIf="!plt.sizeMd"
                                                   position="fixed">
                                            {{ 'COUNTRY' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'country', 'required')">*</ng-container>
                                        </ion-label>

                                        <ng-select name="country"
                                                   (focus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                   (blur)="plt.checkResize(false)"
                                                   #country="ngModel"
                                                   dropdownPosition="top"
                                                   bindValue="id"
                                                   [(ngModel)]="editParticipant.country"
                                                   (change)="typedCountry($event)"
                                                   [ngModelOptions]="{standalone: true}"
                                                   [placeholder]="'COUNTRY_PLACEHOLDER' | translate"
                                                   [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'country', 'edit')"
                                                   [required]="user.selected_participant?.checkProfileRule(editParticipant, 'country', 'required')"
                                                   [clearable]="true">
                                            <ng-option *ngFor="let country of countries"
                                                       [value]="country.id">
                                                {{ country[userLang]}}
                                            </ng-option>
                                        </ng-select>

                                        <span *ngIf="(country.touched || validate) && (!country.valid || validationErrors['country'])"
                                              class="error-message autocomplete">
                                            <ng-container *ngIf="(country.touched || validate) && !country.valid && !validationErrors['country']">
                                                {{ 'VALIDATION_COUNTRY' | translate }}
                                            </ng-container>
                                            <ng-container *ngIf="validate && validationErrors['country']">
                                                {{ validationErrors['country'] }}
                                            </ng-container>
                                        </span>
                                    </ion-item>

                                </ng-container>
                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'phone', 'visible')">
                                    <ion-item [ngClass]="{ 'has-error': (validate && validationErrors['phone']) || ((phone.touched || validate) && !phone.valid) }">
                                        <ion-label *ngIf="plt.sizeMd"
                                                   position="stacked">
                                            {{ 'PHONE' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'phone', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-label *ngIf="!plt.sizeMd"
                                                   position="fixed">
                                            {{ 'PHONE' | translate }}
                                            <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'phone', 'required')">*</ng-container>
                                        </ion-label>
                                        <ion-input type="phone"
                                                   placeholder="{{ 'PHONE_PLACEHOLDER' | translate }}"
                                                   name="phone"
                                                   (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                   (ionBlur)="plt.checkResize(false)"
                                                   inputmode="tel"
                                                   spellcheck="false"
                                                   autocapitalize="off"
                                                   #phone="ngModel"
                                                   [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'phone', 'edit')"
                                                   [(ngModel)]="editParticipant.phone"
                                                   [required]="user.selected_participant?.checkProfileRule(editParticipant, 'phone', 'required')">
                                        </ion-input>
                                    </ion-item>
                                    <span *ngIf="(phone.touched || validate) && !phone.valid"
                                          class="error-message">
                                        {{ 'VALIDATION_PHONE' | translate }}
                                    </span>
                                    <span *ngIf="validate && validationErrors['phone']"
                                          class="error-message">
                                        {{ validationErrors['phone'] }}
                                    </span>
                                </ng-container>

                                <!-- <ion-item [ngClass]="{ 'has-error': validate && validationErrors['xing_profile'] }">
                                <ion-label *ngIf="plt.sizeMd"
                                           position="stacked">
                                    {{ 'PROFILE_EDIT_XING' | translate }}
                                </ion-label>
                                <ion-label *ngIf="!plt.sizeMd"
                                           position="fixed">
                                    {{ 'PROFILE_EDIT_XING' | translate }}
                                </ion-label>
                                <ion-input type="url"
                                           placeholder="{{ 'PROFILE_EDIT_XING_PLACEHOLDER' | translate }}"
                                           name="xing_profile"
                                           inputmode="url"
                                           spellcheck="false"
                                           autocapitalize="off"
                                           #xing_profile="ngModel"
                                           [(ngModel)]="editParticipant.xing_profile">
                                </ion-input>
                            </ion-item>
                            <span class="hint"
                                  *ngIf="!validationErrors['xing_profile']">{{ 'PROFILE_EDIT_XING_HINT' | translate }}</span>
                            <span *ngIf="validate && validationErrors['xing_profile']"
                                  class="error-message">
                                {{ validationErrors['xing_profile'] }}
                            </span>

                            <ion-item [ngClass]="{ 'has-error': validate && validationErrors['linkedin_profile'] }">
                                <ion-label *ngIf="plt.sizeMd"
                                           position="stacked">
                                    {{ 'PROFILE_EDIT_CONNECT_LINKEDIN_LABEL' | translate }}
                                </ion-label>
                                <ion-label *ngIf="!plt.sizeMd"
                                           position="fixed">
                                    {{ 'PROFILE_EDIT_CONNECT_LINKEDIN_LABEL' | translate }}
                                </ion-label>
                                <ion-input type="url"
                                           placeholder="{{ 'PROFILE_EDIT_LINKEDIN_PLACEHOLDER' | translate }}"
                                           name="linkedin_profile"
                                           inputmode="url"
                                           spellcheck="false"
                                           autocapitalize="off"
                                           #xing_profile="ngModel"
                                           [(ngModel)]="editParticipant.linkedin_profile">
                                </ion-input>
                            </ion-item>
                            <span class="hint"
                                  *ngIf="!validationErrors['linkedin_profile']">{{ 'PROFILE_EDIT_LINKEDIN_HINT' | translate }}</span>
                            <span *ngIf="validate && validationErrors['linkedin_profile']"
                                  class="error-message">
                                {{ validationErrors['linkedin_profile'] }}
                            </span> -->

                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col>
                                <p><small [innerHtml]="'PROFILE_EDIT_HINT' | translate"></small></p>
                            </ion-col>
                        </ion-row>

                        <ion-row *ngIf="plt.sizeSm && wizard">
                            <ion-item class="ion-no-padding">
                                <ion-checkbox slot="start"
                                              name="set_global"
                                              [(ngModel)]="participant.set_global">
                                </ion-checkbox>
                                <ion-label>{{ 'PROFILE_EDIT_PARTICIPANT_SET_GLOBAL' | translate }}</ion-label>
                            </ion-item>
                        </ion-row>

                    </ion-grid>
                    <ion-grid *ngIf="!wizard"
                              class="ion-no-padding details">
                        <!-- detail list -->
                        <ng-container *ngFor="let group of editParticipant.groupedDetails ; let groupIndex = index">
                            <ng-container *ngIf="!group.menu && user.selected_participant?.checkProfileRule(editParticipant, group.type, 'visible')">
                                <div *ngIf="plt.sizeSm"
                                     class="actions {{group.type}}">
                                    <ion-icon *ngIf="groupIndex > 0"
                                              (click)="moveDetailUp(groupIndex)"
                                              color="dark"
                                              class="pointer"
                                              name="arrow-up"></ion-icon>
                                    <ion-icon *ngIf="groupIndex < (editParticipant.groupedDetails.length - 1)"
                                              (click)="moveDetailDown(groupIndex)"
                                              color="dark"
                                              class="pointer"
                                              name="arrow-down"></ion-icon>
                                    <ion-icon (click)="deleteGroup(groupIndex, group)"
                                              color="dark"
                                              class="pointer close"
                                              name="close"></ion-icon>
                                </div>
                                <ion-row class="ion-no-padding">
                                    <ion-col class="ion-no-padding">
                                        <h3 *ngIf="'PARTICIPANT_DETAIL_HEADER_TYPE_' + group.type | checkTranslate">{{ 'PARTICIPANT_DETAIL_HEADER_TYPE_' + group.type | checkTranslate }}</h3>
                                        <ion-row class="ion-no-padding">
                                            <ng-container *ngFor="let detail of group.details; let detailIndex = index">
                                                <ion-col [class]="detail.type">
                                                    <ion-item [ngClass]="{ 'has-error': validate && validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.value'] }">
                                                        <ion-label *ngIf="'PARTICIPANT_DETAIL_LABEL_TYPE_' + detail.type | checkTranslate"
                                                                   position="stacked">
                                                            {{ 'PARTICIPANT_DETAIL_LABEL_TYPE_' + detail.type | translate }}
                                                        </ion-label>
                                                        <ng-container [ngSwitch]="true">

                                                            <ng-container *ngSwitchCase="detail.type == 'image'">
                                                                <ion-thumbnail slot="start">
                                                                    <ng-container *ngIf="detail.value">
                                                                        <ng-container *ngIf="detail.options?.type == 'pdf'">
                                                                            <a [href]="detail.value"
                                                                               onclick="window.open(this.href, '_system');return false">
                                                                                <svg-icon [applyClass]="true"
                                                                                          [src]="'/assets/icons/profile/pdf.svg'">
                                                                                </svg-icon>
                                                                            </a>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="detail.options?.type != 'pdf'">
                                                                            <img [src]="plt.createSignedLink(detail.value)">
                                                                        </ng-container>
                                                                        <div class="remove"
                                                                             (click)="detail.value = ''"></div>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="!detail.value">
                                                                        <ng-container *ngIf="!plt.is('cordova')">
                                                                            <dropzone [message]="''"
                                                                                      [config]="dropZoneConfigExtended"
                                                                                      (success)="onUploadSuccess($event, detail)"
                                                                                      (error)="onUploadError($event)"
                                                                                      (sending)="onSending($event)">
                                                                            </dropzone>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="plt.is('cordova')">

                                                                            <div *ngIf="!detail.uploading"
                                                                                 class="image-actions">
                                                                                <button type="button"
                                                                                        class="pure-btn"
                                                                                        (click)="openCamera(detail)">
                                                                                    <ion-icon name="camera"
                                                                                              color="light-blue"></ion-icon>
                                                                                </button>
                                                                                <button type="button"
                                                                                        class="pure-btn"
                                                                                        (click)="openAlbums(detail)">
                                                                                    <ion-icon name="image"
                                                                                              color="light-blue"></ion-icon>
                                                                                </button>
                                                                                <ng-container>
                                                                                    <dropzone [message]="''"
                                                                                              [config]="dropZoneConfigExtended"
                                                                                              (success)="onUploadSuccess($event, detail)"
                                                                                              (error)="onUploadError($event)"
                                                                                              (sending)="onSending($event)">
                                                                                    </dropzone>
                                                                                </ng-container>
                                                                            </div>

                                                                            <div *ngIf="detail.uploading"
                                                                                 class="image-upload">
                                                                                <div class="uploading-spinner">
                                                                                    <ion-spinner></ion-spinner>
                                                                                </div>
                                                                                <!-- END :: initial -->
                                                                                <div class="progress-container">
                                                                                    <progress class="progress"
                                                                                              max="100"
                                                                                              value="{{ detail.progress }}">
                                                                                    </progress>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                        <div class="remove"
                                                                             (click)="deleteDetail(detailIndex, detail, groupIndex, group)"></div>
                                                                    </ng-container>
                                                                </ion-thumbnail>
                                                            </ng-container>

                                                            <ng-container *ngSwitchCase="detail.type == 'from' || detail.type == 'to'">
                                                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(editParticipant, 'cv', 'edit')">
                                                                    <ng-container *ngIf="plt.tabMenu">
                                                                        <ion-datetime-button [datetime]="'detail' + groupIndex + '-' + detailIndex"></ion-datetime-button>
                                                                        <ion-modal [keepContentsMounted]="true"
                                                                                   class="datetime-picker">
                                                                            <ng-template>
                                                                                <ion-datetime [id]="'detail' + groupIndex + '-' + detailIndex"
                                                                                              [name]="'detail' + groupIndex + '-' + detailIndex"
                                                                                              mode="ios"
                                                                                              [locale]="userLang"
                                                                                              presentation="month-year"
                                                                                              [preferWheel]="true"
                                                                                              [value]="detail.string_value"
                                                                                              (ionChange)="detail.value = $event.detail.value"
                                                                                              [cancelText]="'BUTTON_CANCEL' |  translate"
                                                                                              [doneText]="'BUTTON_DONE' | translate"
                                                                                              [showDefaultButtons]="true"
                                                                                              color="dark">
                                                                                </ion-datetime>
                                                                            </ng-template>
                                                                        </ion-modal>
                                                                    </ng-container>

                                                                    <ng-container *ngIf="!plt.tabMenu">
                                                                        <p-calendar [name]="'detail' + groupIndex + '-' + detailIndex"
                                                                                    [(ngModel)]="detail.value"
                                                                                    [inputStyleClass]="'form-control appointment-date'"
                                                                                    [readonlyInput]="true"
                                                                                    dateFormat="mm/yy"
                                                                                    [showTime]="false"
                                                                                    yearNavigator="true"
                                                                                    view="month"
                                                                                    [yearRange]="yearRange"
                                                                                    [locale]="datepickerLangs[userLang]"
                                                                                    appendTo="body"
                                                                                    [showIcon]="true">
                                                                        </p-calendar>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container *ngIf="!user.selected_participant?.checkProfileRule(editParticipant, 'cv', 'edit')">
                                                                    {{ detail.value | datex:'MM/YYYY'}}
                                                                </ng-container>
                                                            </ng-container>

                                                            <ng-container *ngSwitchCase="detail.type == 'paragraph'">

                                                                <ion-textarea placeholder="{{ 'PARTICIPANT_DETAIL_DESC_TYPE_' + detail.type | checkTranslate }}"
                                                                              [name]="'detail' + groupIndex + '-' + detailIndex"
                                                                              ieTextareaFix
                                                                              inputmode="text"
                                                                              spellcheck="false"
                                                                              autocapitalize="off"
                                                                              [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'paragraph', 'edit')"
                                                                              [(ngModel)]="detail.value">
                                                                </ion-textarea><br />
                                                            </ng-container>

                                                            <ng-container *ngSwitchCase="detail.type == 'select'">
                                                                <ion-item class="ion-no-lines">
                                                                    <ion-label position="stacked">{{ detail.options.name | translate }}</ion-label>
                                                                    <ion-select [interface]="selectType"
                                                                                [interfaceOptions]="{cssClass: 'not-overflow'}"
                                                                                [cancelText]="'BUTTON_CANCEL' |  translate"
                                                                                [name]="'detail' + groupIndex + '-' + detailIndex"
                                                                                [(ngModel)]="detail.value"
                                                                                required>

                                                                        <ion-select-option *ngFor="let option of detail.options.values"
                                                                                           [value]="option">
                                                                            {{ option }}
                                                                        </ion-select-option>
                                                                    </ion-select>
                                                                </ion-item>
                                                            </ng-container>

                                                            <ng-container *ngSwitchDefault>
                                                                <ion-input type="text"
                                                                           [name]="'detail' + groupIndex + '-' + detailIndex"
                                                                           (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                                           (ionBlur)="plt.checkResize(false)"
                                                                           placeholder="{{ 'PARTICIPANT_DETAIL_DESC_TYPE_' + detail.type | checkTranslate }}"
                                                                           inputmode="text"
                                                                           spellcheck="false"
                                                                           autocapitalize="off"
                                                                           [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, group.type, 'edit')"
                                                                           [(ngModel)]="detail.value">
                                                                </ion-input>
                                                            </ng-container>

                                                        </ng-container>
                                                    </ion-item>
                                                    <span *ngIf="!detail.error"
                                                          class="hint">{{ 'PARTICIPANT_DETAIL_HINT_TYPE_' + detail.type | checkTranslate }}</span>
                                                    <span *ngIf="validate && detail.error"
                                                          class="error-message">
                                                        {{ detail.error }}
                                                    </span>

                                                    <!-- allow to insert some description for files -->
                                                    <ng-container *ngIf="detail.type == 'image'">
                                                        <ion-item
                                                                  [ngClass]="{ 'has-error': validate && validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.options.name'] }">
                                                            <ion-input type="text"
                                                                       [name]="'detail' + groupIndex + '-1-' + detailIndex"
                                                                       (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                                       (ionBlur)="plt.checkResize(false)"
                                                                       placeholder="{{ 'PARTICIPANT_DETAIL_DESC_TYPE_' + detail.type + '_name' | checkTranslate }}"
                                                                       inputmode="text"
                                                                       spellcheck="false"
                                                                       autocapitalize="off"
                                                                       [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, 'image', 'edit')"
                                                                       [(ngModel)]="detail.options.name">
                                                            </ion-input>
                                                        </ion-item>
                                                        <span *ngIf="validate && validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.options.name']"
                                                              class="error-message">
                                                            {{ validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.options.name'] }}
                                                        </span>
                                                    </ng-container>

                                                </ion-col>
                                                <!-- Special option to add name for link -->
                                                <ng-container *ngIf="detail.type == 'link'">
                                                    <ion-col>
                                                        <ion-item
                                                                  [ngClass]="{ 'has-error': validate && validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.options.name'] }">
                                                            <ion-label *ngIf="detail.type!='image' && detail.type!='paragraph'"
                                                                       position="stacked">
                                                                {{ 'PARTICIPANT_DETAIL_TYPE_' + detail.type + '_name' | translate }}
                                                            </ion-label>
                                                            <ion-input type="text"
                                                                       [name]="'detail' + groupIndex + '-1-' + detailIndex"
                                                                       (ionFocus)="plt.checkResize(false, $event, !wizard ? 'nearest' : 'center') "
                                                                       (ionBlur)="plt.checkResize(false)"
                                                                       placeholder="{{ 'PARTICIPANT_DETAIL_DESC_TYPE_' + detail.type + '_name' | checkTranslate }}"
                                                                       inputmode="text"
                                                                       spellcheck="false"
                                                                       autocapitalize="off"
                                                                       [readonly]="!user.selected_participant?.checkProfileRule(editParticipant, detail.type, 'edit')"
                                                                       [(ngModel)]="detail.options.name">
                                                            </ion-input>
                                                        </ion-item>
                                                        <span *ngIf="!validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.options.name']"
                                                              class="hint">{{ 'PARTICIPANT_DETAIL_HINT_TYPE_' + detail.type + '_name' | checkTranslate }}</span>
                                                        <span *ngIf="validate && validationErrors['groupedDetails.' + groupIndex + '.details.' + detailIndex + '.options.name'] && detail.options?.error"
                                                              class="error-message">
                                                            {{ detail.options.error }}
                                                        </span>
                                                    </ion-col>
                                                </ng-container>

                                            </ng-container>
                                            <ng-container *ngIf="(group.type == 'image') && group.details.length < 4 && user.selected_participant?.checkProfileRule(editParticipant, 'image', 'edit')">
                                                <ion-col class="col-btn-add">
                                                    <ion-item>
                                                        <ion-icon (click)="addGroupDetailBefore(group,'image')"
                                                                  color="light"
                                                                  class="pointer"
                                                                  name="add-circle">
                                                        </ion-icon>
                                                    </ion-item>
                                                </ion-col>
                                            </ng-container>
                                        </ion-row>
                                        <!-- buttons to add image before/after paragraph -->
                                        <ng-container *ngIf="(group.type == 'paragraph') && group.details.length < 3 && user.selected_participant?.checkProfileRule(editParticipant, 'image', 'edit')">
                                            <ion-row class="ion-no-padding extend-paragraph">
                                                <ion-col class="ion-no-padding">
                                                    <ion-item (click)="addGroupDetail(group,'image')"
                                                              class="pointer">
                                                        <ion-icon color="light"
                                                                  name="add-circle"></ion-icon>
                                                        {{ 'PARTICIPANT_DETAIL_ADD_IMAGE_BEFORE' | translate }}
                                                    </ion-item>
                                                </ion-col>
                                                <ion-col class="right ion-no-padding">
                                                    <ion-item (click)="addGroupDetailBefore(group,'image')"
                                                              class="pointer">
                                                        {{ 'PARTICIPANT_DETAIL_ADD_IMAGE_AFTER' | translate }}
                                                        <ion-icon color="light"
                                                                  name="add-circle"></ion-icon>
                                                    </ion-item>
                                                </ion-col>
                                            </ion-row>
                                        </ng-container>
                                    </ion-col>

                                    <ion-col *ngIf="!plt.sizeSm"
                                             class="actions">
                                        <ion-icon *ngIf="groupIndex > 0"
                                                  (click)="moveDetailUp(groupIndex)"
                                                  color="dark"
                                                  class="pointer"
                                                  name="arrow-up">
                                        </ion-icon>
                                        <ion-icon *ngIf="groupIndex < (editParticipant.groupedDetails.length - 1)"
                                                  (click)="moveDetailDown(groupIndex)"
                                                  color="dark"
                                                  class="pointer"
                                                  name="arrow-down">
                                        </ion-icon>
                                        <ion-icon *ngIf="user.selected_participant?.checkProfileRule(editParticipant, group.type, 'edit') && !group.fixed"
                                                  (click)="deleteGroup(groupIndex, group)"
                                                  color="dark"
                                                  class="pointer close"
                                                  name="close">
                                        </ion-icon>
                                    </ion-col>
                                </ion-row>
                                <ng-container *ngIf="groupIndex < (editParticipant.groupedDetails.length - 1)">
                                    <hr class="separator {{ group.type }}"
                                        [ngClass]="{'enabled':group.line}" />
                                    <ion-item class="ion-no-padding set-line">
                                        <ion-checkbox slot="start"
                                                      [name]="'group' + groupIndex + '-1-line'"
                                                      [(ngModel)]="group.line">
                                        </ion-checkbox>
                                        <ion-label>{{ 'PARTICIPANT_DETAIL_SHOW_LINE' | translate }}</ion-label>
                                    </ion-item>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <hr />

                        <!-- detail types form -->
                        <ng-container
                                      *ngIf="(user.selected_participant?.checkProfileRule(editParticipant, 'paragraph', 'edit') && user.selected_participant?.checkProfileRule(editParticipant, 'paragraph', 'visible'))
                                        || (user.selected_participant?.checkProfileRule(editParticipant, 'cv', 'edit') && user.selected_participant?.checkProfileRule(editParticipant, 'cv', 'visible'))
                                        || (user.selected_participant?.checkProfileRule(editParticipant, 'link', 'edit') && user.selected_participant?.checkProfileRule(editParticipant, 'link', 'visible'))
                                        || (user.selected_participant?.checkProfileRule(editParticipant, 'image', 'edit') && user.selected_participant?.checkProfileRule(editParticipant, 'image', 'visible'))">
                            <h2 [ngClass]="{'margin-top':editParticipant.groupedDetails.length > 0}">{{ 'PARTICIPANT_DETAIL_ADD_MORE' | translate }}</h2>
                            <div (click)="addDetail({name: 'header',group: ['header']})"
                                 class="detail-type-header">
                                <h3>{{ 'PARTICIPANT_DETAIL_TYPE_header' | translate }}</h3>
                                <ion-icon color="light"
                                          name="add-circle"></ion-icon>
                            </div>
                        </ng-container>
                        <ion-row class="detail-types">
                            <ng-container *ngFor="let type of editParticipant.detailTypes">
                                <ion-col size="6"
                                         size-md="3"
                                         *ngIf="user.selected_participant?.checkProfileRule(editParticipant, type.name, 'edit') && user.selected_participant?.checkProfileRule(editParticipant, type.name, 'visible')">
                                    <div (click)="addDetail(type)"
                                         class="detail-type">
                                        <svg-icon [applyClass]="true"
                                                  *ngIf="type.icon"
                                                  [class]="type.icon"
                                                  [src]="'/assets/icons/profile/' + type.icon + '.svg'">
                                        </svg-icon>
                                        <svg-icon [applyClass]="true"
                                                  *ngIf="type.name == 'image'"
                                                  class="pdf"
                                                  [src]="'/assets/icons/profile/pdf.svg'">
                                        </svg-icon>
                                        <div>
                                            <h3>{{ 'PARTICIPANT_DETAIL_TYPE_' + type.name | translate }}</h3>
                                            <p>{{ 'PARTICIPANT_DETAIL_DESC_TYPE_' + type.name | translate }}</p>
                                        </div>
                                    </div>
                                </ion-col>
                            </ng-container>
                        </ion-row>

                    </ion-grid>
                </form>
            </div>
        </div>
        <div class="content-footer">
            <ion-grid class="ion-no-padding">

                <ion-row *ngIf="plt.sizeSm && wizard"
                         class="ion-no-padding action-buttons two">
                    <ion-col size="6"
                             class="ion-no-padding">
                        <ion-button class="btn-close"
                                    (click)="presentActionSheet()">
                            {{ 'BUTTON_OPTIONS' | translate }}
                        </ion-button>
                    </ion-col>
                    <ion-col size="6"
                             class="ion-no-padding">
                        <ion-button [disabled]="submit"
                                    (click)="setParticipant(f.form.valid)">
                            {{ 'BUTTON_SAVE' | translate }}
                        </ion-button>
                    </ion-col>
                </ion-row>

                <ion-row *ngIf="!plt.sizeSm || !wizard"
                         class="profile-buttons">
                    <ion-col size="12"
                             size-md="6"
                             class="ion-no-padding ion-align-self-start">
                        <ion-button *ngIf="!wizard"
                                    [ngClass]="{'btn-close':plt.sizeSm, 'clear':!plt.sizeSm}"
                                    fill="clear"
                                    color="secondary"
                                    [routerDirection]="'root'"
                                    (click)="navigate(this.plt.defaultLink + '/home/info/matching')">
                            {{ 'BUTTON_CANCEL' | translate }}
                        </ion-button>
                        <ion-button *ngIf="wizard"
                                    [ngClass]="{'btn-close':plt.sizeSm, 'clear':!plt.sizeSm}"
                                    fill="clear"
                                    color="secondary"
                                    [routerDirection]="'root'"
                                    (click)="leaveEvent()">
                            {{ 'PROFILE_EDIT_LEAVE' | translate }}
                        </ion-button>
                        <ion-button (click)="openDestroyProfile()"
                                    [ngClass]="{'btn-close':plt.sizeSm, 'clear':!plt.sizeSm}"
                                    fill="clear"
                                    color="medium">
                            <ng-container *ngIf="!user?.selected_participant?.event.is_community">{{ 'PROFILE_EDIT_DELETE_PROFILE' | translate }}</ng-container>
                            <ng-container *ngIf="user?.selected_participant?.event.is_community">{{ 'PROFILE_EDIT_DELETE_PROFILE_COMMUNITY' | translate }}</ng-container>
                        </ion-button>
                        <ion-button (click)="openDestroyAccount()"
                                    [ngClass]="{'btn-close':plt.sizeSm, 'clear':!plt.sizeSm}"
                                    fill="clear"
                                    color="medium">
                            {{ 'PROFILE_EDIT_DELETE_ACCOUNT' | translate }}
                        </ion-button>
                    </ion-col>

                    <ion-col size="12"
                             size-sm="6"
                             class="ion-no-padding">
                        <ion-row class="ion-no-padding ion-align-items-center">
                            <ion-col *ngIf="wizard"
                                     size="12"
                                     size-md="6"
                                     class="ion-no-padding">
                                <ion-item class="global ion-no-padding">
                                    <ion-checkbox slot="start"
                                                  [(ngModel)]="participant.set_global">
                                    </ion-checkbox>
                                    <ion-label>{{ 'PROFILE_EDIT_PARTICIPANT_SET_GLOBAL' | translate }}</ion-label>
                                </ion-item>
                            </ion-col>
                            <ion-col *ngIf="!wizard"
                                     size="12"
                                     size-md="9"
                                     class="ion-no-padding">
                                <ion-item class="global ion-no-padding">
                                    <ion-checkbox slot="start"
                                                  [(ngModel)]="participant.set_global">
                                    </ion-checkbox>
                                    <ion-label>{{ 'PROFILE_EDIT_PARTICIPANT_SET_GLOBAL' | translate }}</ion-label>
                                </ion-item>
                            </ion-col>
                            <ion-col *ngIf="!wizard"
                                     size="12"
                                     size-md="3"
                                     class="ion-no-padding ion-text-right">
                                <ion-button color="primary"
                                            id="profile-save-button"
                                            [disabled]="submit"
                                            (click)="setParticipant(f.form.valid)">
                                    {{ 'BUTTON_SAVE' | translate }}
                                </ion-button>
                            </ion-col>
                            <ion-col *ngIf="wizard"
                                     class="ion-no-padding ion-text-right">
                                <ion-button *ngIf="skip && !plt.sizeSm && f.form.valid"
                                            class="clear"
                                            fill="clear"
                                            color="medium"
                                            [routerDirection]="'root'"
                                            (click)="skipEditation()">
                                    {{ 'PROFILE_EDIT_SKIP' | translate }}
                                </ion-button>
                                <ion-button color="primary"
                                            id="profile-save-button"
                                            [disabled]="submit || !f.form.valid"
                                            (click)="setParticipant(f.form.valid)">
                                    {{ 'BUTTON_SAVE' | translate }}
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>

            </ion-grid>
        </div>
    </div>
</div>

<ng-container *ngIf="loading">
    <div class="content-centered-column">
        <div class="content-centered-row">
            <ion-spinner></ion-spinner>
        </div>
    </div>
</ng-container>