export class Helper {
    /**
     * Creates a unique guid
     * @returns A guid
     */
    public static guid = (): string => {
        return Helper.s4() + Helper.s4() + '-' + Helper.s4() + '-' + Helper.s4() + '-' +
            Helper.s4() + '-' + Helper.s4() + Helper.s4() + Helper.s4();
    };

    /**
     * Generates a random string
     * @returns A random string
     */
    private static s4(): string {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
}

/**
 * deleteCookie
 *
 * helper to delete cookie with give name and value for domain
 *
 * @param name
 * @param value
 * @param days
 * @param domain
 */
export function setCookie(name: string, value: string, days: number, domain: string = null) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/" +
        ((domain) ? ";domain=" + domain : "");
}

/**
 * deleteCookie
 *
 * helper to delete cookie with give name and value for domain
 *
 * @param name
 * @param path
 * @param domain
 */
export function deleteCookie(name: string, path: string = null, domain: string = null) {
    document.cookie = name + "=" +
        ((path) ? ";path=" + path : "") +
        ((domain) ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
}
/**
 * getCookie
 *
 * helper to return cokie value by name
 *
 * @param name
 */
export function getCookie(name: string) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
    else return '';
}

/**
 * getToken
 *
 * helper to return fake token if session storage is empty
 *
 * @param name
 */
export function getToken(name: string) {
    let token: string;
    if (token = localStorage.getItem(name)) {
        return token;
    } else {
        // return fake token to force app to logout user
        return '';//'eyJhbGciOiJub25lIiwidHlwIjoiSldUIn0.eyJpc3MiOiJodHRwczovL2p3dC1pZHAuZXhhbXBsZS5jb20iLCJzdWIiOiJtYWlsdG86bWlrZUBleGFtcGxlLmNvbSIsIm5iZiI6MTUzMzcwOTI1NiwiZXhwIjoxNTMzNzEyODU2LCJpYXQiOjE1MzM3MDkyNTYsImp0aSI6ImlkMTIzNDU2IiwidHlwIjoiaHR0cHM6Ly9leGFtcGxlLmNvbS9yZWdpc3RlciJ9.';
    }
}

export function isVideoLink(url: string) {

    if (!!url) {
        /*
            this pattern matches:
            - http://www.youtube.com/v/0zM3nApSv11?fs=1&amp;hl=en_US&amp;rel=0
            - http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
            - http://www.youtube.com/embed/0zM3nApSvMg?rel=0
            - http://www.youtube.com/watch?v=0zM3nApSvMg
            - https://www.youtube.com/live/5cCqj-aZrRw
            - http://youtu.be/0zM3nApSvMg
            - https://vimeo.com/265045525
            - https://vimeo.com/event/265045525
        */
        // let pattern = /(?:https?:\/\/)?(?:www\.)?((?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|(vimeo\.com\/(\w*))|(vimeo\.com\/event\/(\w*))|(congreet\.stream\/(\w*)))(?:\S+)?/gim;
        let pattern = /(?:https?:\/\/)?(?:www\.)?((?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|live\/|watch\?v=|watch\?.+&v=))((\w|-){11})|(vimeo\.com\/([0-9]{6,}))|(vimeo\.com\/event\/(\w+))|(congreet\.stream\/(\w*)))(?:\S+)?/gim;

        // if we found something
        if (url.match(pattern)) {
            return true;
        }
    }

    return false
}


export function getCountries() {
    return [
        { id: "AD", de: "Andorra", en: "Andorra", fr: "Andorra", es: "Andorra" },
        { id: "AE", de: "Vereinigte Arabische Emirate", en: "United Arab Emirates", fr: "United Arab Emirates", es: "United Arab Emirates" },
        { id: "AF", de: "Afghanistan", en: "Afghanistan", fr: "Afghanistan", es: "Afghanistan" },
        { id: "AG", de: "Antigua und Barbuda", en: "Antigua and Barbuda", fr: "Antigua and Barbuda", es: "Antigua and Barbuda" },
        { id: "AI", de: "Anguilla", en: "Anguilla", fr: "Anguilla", es: "Anguilla" },
        { id: "AL", de: "Albanien", en: "Albania", fr: "Albania", es: "Albania" },
        { id: "AM", de: "Armenien", en: "Armenia", fr: "Armenia", es: "Armenia" },
        { id: "AN", de: "Antilla", en: "Antilla", fr: "Antilla", es: "Antilla" },
        { id: "AO", de: "Angola", en: "Angola", fr: "Angola", es: "Angola" },
        { id: "AR", de: "Argentinien", en: "Argentina", fr: "Argentina", es: "Argentina" },
        { id: "AS", de: "Amerikanisch-Samoa", en: "American Samoa", fr: "American Samoa", es: "American Samoa" },
        { id: "AT", de: "Österreich", en: "Austria", fr: "Austria", es: "Austria" },
        { id: "AU", de: "Australien", en: "Australia", fr: "Australia", es: "Australia" },
        { id: "AW", de: "Aruba", en: "Aruba", fr: "Aruba", es: "Aruba" },
        { id: "AX", de: "Åland", en: "Åland Islands", fr: "Åland Islands", es: "Åland Islands" },
        { id: "AZ", de: "Aserbaidschan", en: "Azerbaijan", fr: "Azerbaijan", es: "Azerbaijan" },
        { id: "BA", de: "Bosnien und Herzegowina", en: "Bosnia and Herzegovina", fr: "Bosnia and Herzegovina", es: "Bosnia and Herzegovina" },
        { id: "BB", de: "Barbados", en: "Barbados", fr: "Barbados", es: "Barbados" },
        { id: "BD", de: "Bangladesch", en: "Bangladesh", fr: "Bangladesh", es: "Bangladesh" },
        { id: "BE", de: "Belgien", en: "Belgium", fr: "Belgium", es: "Belgium" },
        { id: "BF", de: "Burkina Faso", en: "Burkina Faso", fr: "Burkina Faso", es: "Burkina Faso" },
        { id: "BG", de: "Bulgarien", en: "Bulgaria", fr: "Bulgaria", es: "Bulgaria" },
        { id: "BH", de: "Bahrain", en: "Bahrain", fr: "Bahrain", es: "Bahrain" },
        { id: "BI", de: "Burundi", en: "Burundi", fr: "Burundi", es: "Burundi" },
        { id: "BJ", de: "Benin", en: "Benin", fr: "Benin", es: "Benin" },
        { id: "BM", de: "Bermuda", en: "Bermuda", fr: "Bermuda", es: "Bermuda" },
        { id: "BN", de: "Brunei Darussalam", en: "Brunei Darussalam", fr: "Brunei Darussalam", es: "Brunei Darussalam" },
        { id: "BO", de: "Bolivien", en: "Bolivia", fr: "Bolivia", es: "Bolivia" },
        { id: "BR", de: "Brasilien", en: "Brazil", fr: "Brazil", es: "Brazil" },
        { id: "BS", de: "Bahamas", en: "Bahamas", fr: "Bahamas", es: "Bahamas" },
        { id: "BT", de: "Bhutan", en: "Bhutan", fr: "Bhutan", es: "Bhutan" },
        { id: "BV", de: "Bouvetinsel", en: "Bouvet Island", fr: "Bouvet Island", es: "Bouvet Island" },
        { id: "BW", de: "Botswana", en: "Botswana", fr: "Botswana", es: "Botswana" },
        { id: "BY", de: "Weißrussland", en: "Belarus", fr: "Belarus", es: "Belarus" },
        { id: "BZ", de: "Belize", en: "Belize", fr: "Belize", es: "Belize" },
        { id: "CA", de: "Kanada", en: "Canada", fr: "Canada", es: "Canada" },
        { id: "CC", de: "Kokosinseln", en: "Cocos (Keeling) Islands", fr: "Cocos (Keeling) Islands", es: "Cocos (Keeling) Islands" },
        { id: "CD", de: "Demokratische Rebublik Kongo", en: "Democratic Republic of the Congo", fr: "Democratic Republic of the Congo", es: "Democratic Republic of the Congo" },
        { id: "CF", de: "Zentralafrikanische Republik", en: "Central African Republic", fr: "Central African Republic", es: "Central African Republic" },
        { id: "CG", de: "Kongo", en: "Congo", fr: "Congo", es: "Congo" },
        { id: "CH", de: "Schweiz", en: "Switzerland", fr: "Switzerland", es: "Switzerland" },
        { id: "CI", de: "Elfenbeinküste", en: "Côte d'Ivoire", fr: "Côte d'Ivoire", es: "Côte d'Ivoire" },
        { id: "CK", de: "Cookinseln", en: "Cook Islands", fr: "Cook Islands", es: "Cook Islands" },
        { id: "CL", de: "Chile", en: "Chile", fr: "Chile", es: "Chile" },
        { id: "CM", de: "Kamerun", en: "Cameroon", fr: "Cameroon", es: "Cameroon" },
        { id: "CN", de: "Volksrepublik China", en: "People's Republic of China", fr: "People's Republic of China", es: "People's Republic of China" },
        { id: "CO", de: "Kolumbien", en: "Colombia", fr: "Colombia", es: "Colombia" },
        { id: "CR", de: "Costa Rica", en: "Costa Rica", fr: "Costa Rica", es: "Costa Rica" },
        { id: "CU", de: "Kuba", en: "Cuba", fr: "Cuba", es: "Cuba" },
        { id: "CV", de: "Kap Verde", en: "Cape Verde", fr: "Cape Verde", es: "Cape Verde" },
        { id: "CW", de: "Curacao", en: "Curacao", fr: "Curacao", es: "Curacao" },
        { id: "CX", de: "Weihnachtsinsel", en: "Christmas Island", fr: "Christmas Island", es: "Christmas Island" },
        { id: "CY", de: "Zypern", en: "Cyprus", fr: "Cyprus", es: "Cyprus" },
        { id: "CZ", de: "Tschechische Republik", en: "Czech Republic", fr: "Czech Republic", es: "Czech Republic" },
        { id: "DE", de: "Deutschland", en: "Germany", fr: "Germany", es: "Germany" },
        { id: "DJ", de: "Dschibuti", en: "Djibouti", fr: "Djibouti", es: "Djibouti" },
        { id: "DK", de: "Dänemark", en: "Denmark", fr: "Denmark", es: "Denmark" },
        { id: "DM", de: "Dominica", en: "Commonwealth of Dominica", fr: "Commonwealth of Dominica", es: "Commonwealth of Dominica" },
        { id: "DO", de: "Dominikanische Republik", en: "Dominican Republic", fr: "Dominican Republic", es: "Dominican Republic" },
        { id: "DZ", de: "Algerien", en: "Algeria", fr: "Algeria", es: "Algeria" },
        { id: "EC", de: "Ecuador", en: "Ecuador", fr: "Ecuador", es: "Ecuador" },
        { id: "EE", de: "Estland", en: "Estonia", fr: "Estonia", es: "Estonia" },
        { id: "EG", de: "Ägypten", en: "Egypt", fr: "Egypt", es: "Egypt" },
        { id: "EH", de: "Demokratische Arabische Republik Sahara", en: "Western Sahara", fr: "Western Sahara", es: "Western Sahara" },
        { id: "ER", de: "Eritrea", en: "Eritrea", fr: "Eritrea", es: "Eritrea" },
        { id: "ES", de: "Spanien", en: "Spain", fr: "Spain", es: "Spain" },
        { id: "ET", de: "Äthiopien", en: "Ethiopia", fr: "Ethiopia", es: "Ethiopia" },
        { id: "FI", de: "Finnland", en: "Finland", fr: "Finland", es: "Finland" },
        { id: "FJ", de: "Fidschi", en: "Fiji Islands", fr: "Fiji Islands", es: "Fiji Islands" },
        { id: "FK", de: "Falklandinseln", en: "Falkland Islands (Malvinas)", fr: "Falkland Islands (Malvinas)", es: "Falkland Islands (Malvinas)" },
        { id: "FM", de: "Föderierte Staaten von Mikronesien", en: "Federated States of Micronesia", fr: "Federated States of Micronesia", es: "Federated States of Micronesia" },
        { id: "FO", de: "Färöer", en: "Faroe Islands", fr: "Faroe Islands", es: "Faroe Islands" },
        { id: "FR", de: "Frankreich", en: "France", fr: "France", es: "France" },
        { id: "GA", de: "Gabun", en: "Gabon", fr: "Gabon", es: "Gabon" },
        { id: "GB", de: "Großbritannien", en: "United Kingdom", fr: "United Kingdom", es: "United Kingdom" },
        { id: "GD", de: "Grenada", en: "State of Grenada", fr: "State of Grenada", es: "State of Grenada" },
        { id: "GE", de: "Georgien", en: "Georgia", fr: "Georgia", es: "Georgia" },
        { id: "GF", de: "Französisch Guinea", en: "French Guinea", fr: "French Guinea", es: "French Guinea" },
        { id: "GH", de: "Ghana", en: "Ghana", fr: "Ghana", es: "Ghana" },
        { id: "GI", de: "Gibraltar", en: "Gibraltar", fr: "Gibraltar", es: "Gibraltar" },
        { id: "GL", de: "Grönland", en: "Greenland", fr: "Greenland", es: "Greenland" },
        { id: "GM", de: "Gambia", en: "Republic of The Gambia", fr: "Republic of The Gambia", es: "Republic of The Gambia" },
        { id: "GN", de: "Guinea", en: "Guinea", fr: "Guinea", es: "Guinea" },
        { id: "GP", de: "Guadeloupe", en: "Guadeloupe", fr: "Guadeloupe", es: "Guadeloupe" },
        { id: "GQ", de: "Äquatorialguinea", en: "Equatorial Guinea", fr: "Equatorial Guinea", es: "Equatorial Guinea" },
        { id: "GR", de: "Griechenland", en: "Greece", fr: "Greece", es: "Greece" },
        { id: "GS", de: "Südgeorgien und die Südlichen Sandwichinseln", en: "South Georgia and the South Sandwitch Islands", fr: "South Georgia and the South Sandwitch Islands", es: "South Georgia and the South Sandwitch Islands" },
        { id: "GT", de: "Guatemala", en: "Guatemala", fr: "Guatemala", es: "Guatemala" },
        { id: "GU", de: "Guam", en: "Guam", fr: "Guam", es: "Guam" },
        { id: "GW", de: "Guinea-Bissau", en: "Guinea-Bissau", fr: "Guinea-Bissau", es: "Guinea-Bissau" },
        { id: "GY", de: "Kooperative Republik Guyana", en: "Co-operative Republic of Guyana", fr: "Co-operative Republic of Guyana", es: "Co-operative Republic of Guyana" },
        { id: "HK", de: "Hongkong", en: "Hong Kong S.A.R.", fr: "Hong Kong S.A.R.", es: "Hong Kong S.A.R." },
        { id: "HM", de: "Heard und McDonaldinseln", en: "Heard Islands and McDonald Islands", fr: "Heard Islands and McDonald Islands", es: "Heard Islands and McDonald Islands" },
        { id: "HN", de: "Honduras", en: "Honduras", fr: "Honduras", es: "Honduras" },
        { id: "HR", de: "Kroatien", en: "Croatia", fr: "Croatia", es: "Croatia" },
        { id: "HT", de: "Haiti", en: "Haiti", fr: "Haiti", es: "Haiti" },
        { id: "HU", de: "Ungarn", en: "Hungary", fr: "Hungary", es: "Hungary" },
        { id: "ID", de: "Indonesien", en: "Indonesia", fr: "Indonesia", es: "Indonesia" },
        { id: "IE", de: "Irland", en: "Ireland", fr: "Ireland", es: "Ireland" },
        { id: "IL", de: "Israel", en: "Israel", fr: "Israel", es: "Israel" },
        { id: "IM", de: "Isle of Man", en: "Isle of Man", fr: "Isle of Man", es: "Isle of Man" },
        { id: "IN", de: "Indien", en: "India", fr: "India", es: "India" },
        { id: "IO", de: "Britisches Territorium im Indischen Ozean", en: "British Indian Ocean Territory", fr: "British Indian Ocean Territory", es: "British Indian Ocean Territory" },
        { id: "IQ", de: "Irak", en: "Iraq", fr: "Iraq", es: "Iraq" },
        { id: "IR", de: "Iran", en: "Iran", fr: "Iran", es: "Iran" },
        { id: "IS", de: "Island", en: "Iceland", fr: "Iceland", es: "Iceland" },
        { id: "IT", de: "Italien", en: "Italy", fr: "Italy", es: "Italy" },
        { id: "JM", de: "Jamaika", en: "Jamaica", fr: "Jamaica", es: "Jamaica" },
        { id: "JO", de: "Jordanien", en: "Jordan", fr: "Jordan", es: "Jordan" },
        { id: "JP", de: "Japan", en: "Japan", fr: "Japan", es: "Japan" },
        { id: "KE", de: "Kenia", en: "Kenya", fr: "Kenya", es: "Kenya" },
        { id: "KG", de: "Kirgisistan", en: "Kyrgyzstan", fr: "Kyrgyzstan", es: "Kyrgyzstan" },
        { id: "KH", de: "Kambodscha", en: "Cambodia", fr: "Cambodia", es: "Cambodia" },
        { id: "KI", de: "Republik Kiribati", en: "Republic of Kiribati", fr: "Republic of Kiribati", es: "Republic of Kiribati" },
        { id: "KM", de: "Komoren", en: "Comoros", fr: "Comoros", es: "Comoros" },
        { id: "KN", de: "Föderation St. Kitts und Nevis", en: "Federation of Saint Kitts and Nevis", fr: "Federation of Saint Kitts and Nevis", es: "Federation of Saint Kitts and Nevis" },
        { id: "KP", de: "Demokratische Volksrepublik Korea", en: "Democratic People's Republic of Korea", fr: "Democratic People's Republic of Korea", es: "Democratic People's Republic of Korea" },
        { id: "KR", de: "Republik Korea", en: "Republic of Korea", fr: "Republic of Korea", es: "Republic of Korea" },
        { id: "KW", de: "Kuwait", en: "Kuwait", fr: "Kuwait", es: "Kuwait" },
        { id: "KY", de: "Kaimaninseln", en: "Cayman Islands", fr: "Cayman Islands", es: "Cayman Islands" },
        { id: "KZ", de: "Kasachstan", en: "Kazakhstan", fr: "Kazakhstan", es: "Kazakhstan" },
        { id: "LA", de: "Demokratische Volksrepublik Laos", en: "Lao People's Democratic Republic", fr: "Lao People's Democratic Republic", es: "Lao People's Democratic Republic" },
        { id: "LB", de: "Libanon", en: "Lebanon", fr: "Lebanon", es: "Lebanon" },
        { id: "LC", de: "St. Lucia", en: "Saint Lucia", fr: "Saint Lucia", es: "Saint Lucia" },
        { id: "LI", de: "Liechtenstein", en: "Liechtenstein", fr: "Liechtenstein", es: "Liechtenstein" },
        { id: "LK", de: "Sri Lanka", en: "Sri Lanka", fr: "Sri Lanka", es: "Sri Lanka" },
        { id: "LR", de: "Liberia", en: "Republic of Liberia", fr: "Republic of Liberia", es: "Republic of Liberia" },
        { id: "LS", de: "Königreich Lesotho", en: "Kingdom of Lesotho", fr: "Kingdom of Lesotho", es: "Kingdom of Lesotho" },
        { id: "LT", de: "Litauen", en: "Lithuania", fr: "Lithuania", es: "Lithuania" },
        { id: "LU", de: "Luxemburg", en: "Luxembourg", fr: "Luxembourg", es: "Luxembourg" },
        { id: "LV", de: "Lettland", en: "Latvia", fr: "Latvia", es: "Latvia" },
        { id: "LY", de: "Libyen", en: "Libya", fr: "Libya", es: "Libya" },
        { id: "MA", de: "Marokko", en: "Morocco", fr: "Morocco", es: "Morocco" },
        { id: "MC", de: "Fürstentum Monaco", en: "Principality of Monaco", fr: "Principality of Monaco", es: "Principality of Monaco" },
        { id: "MD", de: "Republik Moldau (Moldawien)", en: "Republic of Moldova", fr: "Republic of Moldova", es: "Republic of Moldova" },
        { id: "ME", de: "Montenegro", en: "Montenegro", fr: "Montenegro", es: "Montenegro" },
        { id: "MG", de: "Madagaskar", en: "Madagascar", fr: "Madagascar", es: "Madagascar" },
        { id: "MH", de: "Republik Marshallinseln", en: "Republic of the Marshall Islands", fr: "Republic of the Marshall Islands", es: "Republic of the Marshall Islands" },
        { id: "MK", de: "Ehemalige jugoslawische Republik Mazedonien", en: "Former Yugoslav Republic of Macedonia", fr: "Former Yugoslav Republic of Macedonia", es: "Former Yugoslav Republic of Macedonia" },
        { id: "ML", de: "Mali", en: "Mali", fr: "Mali", es: "Mali" },
        { id: "MM", de: "Republik der Union von Myanmar", en: "Myanmar", fr: "Myanmar", es: "Myanmar" },
        { id: "MN", de: "Mongolei", en: "Mongolia", fr: "Mongolia", es: "Mongolia" },
        { id: "MO", de: "Macao", en: "Macao", fr: "Macao", es: "Macao" },
        { id: "MP", de: "Das Commonwealth der Nördlichen Marianen", en: "The Commonwealth of the Northern Mariana Islands", fr: "The Commonwealth of the Northern Mariana Islands", es: "The Commonwealth of the Northern Mariana Islands" },
        { id: "MQ", de: "Martinique", en: "Martinique", fr: "Martinique", es: "Martinique" },
        { id: "MR", de: "Islamische Republik Mauretanien", en: "Mauritania", fr: "Mauritania", es: "Mauritania" },
        { id: "MS", de: "Montserrat", en: "Montserrat", fr: "Montserrat", es: "Montserrat" },
        { id: "MT", de: "Malta", en: "Malta", fr: "Malta", es: "Malta" },
        { id: "MU", de: "Mauritius", en: "Mauritius", fr: "Mauritius", es: "Mauritius" },
        { id: "MV", de: "Malediven", en: "Maldives", fr: "Maldives", es: "Maldives" },
        { id: "MW", de: "Malawi", en: "Malawi", fr: "Malawi", es: "Malawi" },
        { id: "MX", de: "Mexiko", en: "Mexico", fr: "Mexico", es: "Mexico" },
        { id: "MY", de: "Malaysia", en: "Malaysia", fr: "Malaysia", es: "Malaysia" },
        { id: "MZ", de: "Mozambik", en: "Mozambique", fr: "Mozambique", es: "Mozambique" },
        { id: "NA", de: "Republik Namibia", en: "Republic of Namibia", fr: "Republic of Namibia", es: "Republic of Namibia" },
        { id: "NC", de: "Neukaledonien", en: "New Caledonia", fr: "New Caledonia", es: "New Caledonia" },
        { id: "NE", de: "Niger", en: "Niger", fr: "Niger", es: "Niger" },
        { id: "NF", de: "Norfolkinsel", en: "Norfolk Island", fr: "Norfolk Island", es: "Norfolk Island" },
        { id: "NG", de: "Nigeria", en: "Nigeria", fr: "Nigeria", es: "Nigeria" },
        { id: "NI", de: "Nicaragua", en: "Nicaragua", fr: "Nicaragua", es: "Nicaragua" },
        { id: "NL", de: "Niederlande", en: "Netherlands", fr: "Netherlands", es: "Netherlands" },
        { id: "NO", de: "Norwegen", en: "Norway", fr: "Norway", es: "Norway" },
        { id: "NP", de: "Nepal", en: "Nepal", fr: "Nepal", es: "Nepal" },
        { id: "NR", de: "Republik Nauru", en: "Republic of Nauru", fr: "Republic of Nauru", es: "Republic of Nauru" },
        { id: "NU", de: "Niue", en: "Niue", fr: "Niue", es: "Niue" },
        { id: "NZ", de: "Neuseeland", en: "New Zealand", fr: "New Zealand", es: "New Zealand" },
        { id: "OM", de: "Oman", en: "Oman", fr: "Oman", es: "Oman" },
        { id: "PA", de: "Panama", en: "Panama", fr: "Panama", es: "Panama" },
        { id: "PE", de: "Peru", en: "Peru", fr: "Peru", es: "Peru" },
        { id: "PF", de: "Französisch-Polynesien", en: "French Polynesia", fr: "French Polynesia", es: "French Polynesia" },
        { id: "PG", de: "Unabhängiger Staat Papua-Neuguinea", en: "Independent State of Papua New Guinea", fr: "Independent State of Papua New Guinea", es: "Independent State of Papua New Guinea" },
        { id: "PH", de: "Republik Philippinen", en: "Republic of the Philippines", fr: "Republic of the Philippines", es: "Republic of the Philippines" },
        { id: "PK", de: "Pakistan", en: "Islamic Republic of Pakistan", fr: "Islamic Republic of Pakistan", es: "Islamic Republic of Pakistan" },
        { id: "PL", de: "Polen", en: "Poland", fr: "Poland", es: "Poland" },
        { id: "PM", de: "Saint-Pierre und Miquelon", en: "Saint Pierre and Miquelon", fr: "Saint Pierre and Miquelon", es: "Saint Pierre and Miquelon" },
        { id: "PN", de: "Pitcairn", en: "Pitcairn", fr: "Pitcairn", es: "Pitcairn" },
        { id: "PR", de: "Puerto Rico", en: "Puerto Rico", fr: "Puerto Rico", es: "Puerto Rico" },
        { id: "PS", de: "Palästinensische Autonomiegebiete", en: "Occupied Palestinian Territory", fr: "Occupied Palestinian Territory", es: "Occupied Palestinian Territory" },
        { id: "PT", de: "Portugal", en: "Portugal", fr: "Portugal", es: "Portugal" },
        { id: "PW", de: "Palau", en: "Palau", fr: "Palau", es: "Palau" },
        { id: "PY", de: "Paraguay", en: "Paraguay", fr: "Paraguay", es: "Paraguay" },
        { id: "QA", de: "Katar", en: "Qatar", fr: "Qatar", es: "Qatar" },
        { id: "RE", de: "Réunion", en: "Réunion", fr: "Réunion", es: "Réunion" },
        { id: "RO", de: "Rumänien", en: "Romania", fr: "Romania", es: "Romania" },
        { id: "RS", de: "Serbien", en: "Serbia", fr: "Serbia", es: "Serbia" },
        { id: "RU", de: "Russische Föderation", en: "Russia", fr: "Russia", es: "Russia" },
        { id: "RW", de: "Ruanda", en: "Rwanda", fr: "Rwanda", es: "Rwanda" },
        { id: "SA", de: "Saudi-Arabien", en: "Saudi Arabia", fr: "Saudi Arabia", es: "Saudi Arabia" },
        { id: "SB", de: "Salomonen", en: "Solomon Islands", fr: "Solomon Islands", es: "Solomon Islands" },
        { id: "SC", de: "Republik Seychellen", en: "Republic of Seychelles", fr: "Republic of Seychelles", es: "Republic of Seychelles" },
        { id: "SD", de: "Sudan", en: "Sudan", fr: "Sudan", es: "Sudan" },
        { id: "SE", de: "Schweden", en: "Sweden", fr: "Sweden", es: "Sweden" },
        { id: "SG", de: "Singapur", en: "Singapore", fr: "Singapore", es: "Singapore" },
        { id: "SH", de: "St. Helena, Ascension und Tristan da Cunha", en: "Saint Helena, Ascension and Tristan Da Cunha", fr: "Saint Helena, Ascension and Tristan Da Cunha", es: "Saint Helena, Ascension and Tristan Da Cunha" },
        { id: "SI", de: "Slowenien", en: "Slovenia", fr: "Slovenia", es: "Slovenia" },
        { id: "SJ", de: "Svalbard und Jan Mayen", en: "Svalbard and Jan Mayen", fr: "Svalbard and Jan Mayen", es: "Svalbard and Jan Mayen" },
        { id: "SK", de: "Slowakei", en: "Slovakia", fr: "Slovakia", es: "Slovakia" },
        { id: "SL", de: "Republik Sierra Leone", en: "Republic of Sierra Leone", fr: "Republic of Sierra Leone", es: "Republic of Sierra Leone" },
        { id: "SM", de: "San Marino", en: "San Marino", fr: "San Marino", es: "San Marino" },
        { id: "SN", de: "Senegal", en: "Senegal", fr: "Senegal", es: "Senegal" },
        { id: "SO", de: "Somalia", en: "Somalia", fr: "Somalia", es: "Somalia" },
        { id: "SP", de: "Spain", en: "Spain", fr: "Spain", es: "Spain" },
        { id: "SR", de: "Surinam", en: "Suriname", fr: "Suriname", es: "Suriname" },
        { id: "ST", de: "Sao Tome und Principe", en: "Sao Tome and Principe", fr: "Sao Tome and Principe", es: "Sao Tome and Principe" },
        { id: "SV", de: "El Salvador", en: "El Salvador", fr: "El Salvador", es: "El Salvador" },
        { id: "SY", de: "Syrien", en: "Syria", fr: "Syria", es: "Syria" },
        { id: "SZ", de: "Königreich Swasiland", en: "Kingdom of Swaziland", fr: "Kingdom of Swaziland", es: "Kingdom of Swaziland" },
        { id: "TC", de: "Turks- und Caicosinseln", en: "Turks and Caicos Islands", fr: "Turks and Caicos Islands", es: "Turks and Caicos Islands" },
        { id: "TD", de: "Tschad", en: "Chad", fr: "Chad", es: "Chad" },
        { id: "TF", de: "Französische Süd- und Antarktisgebiete", en: "French Southern and Antarctic Lands", fr: "French Southern and Antarctic Lands", es: "French Southern and Antarctic Lands" },
        { id: "TG", de: "Togo", en: "Togo", fr: "Togo", es: "Togo" },
        { id: "TH", de: "Thailand", en: "Thailand", fr: "Thailand", es: "Thailand" },
        { id: "TJ", de: "Tadschikistan", en: "Tajikistan", fr: "Tajikistan", es: "Tajikistan" },
        { id: "TK", de: "Tokelau", en: "Tokelau", fr: "Tokelau", es: "Tokelau" },
        { id: "TL", de: "Osttimor", en: "Timor-Leste", fr: "Timor-Leste", es: "Timor-Leste" },
        { id: "TM", de: "Turkmenistan", en: "Turkmenistan", fr: "Turkmenistan", es: "Turkmenistan" },
        { id: "TN", de: "Tunesien", en: "Tunisia", fr: "Tunisia", es: "Tunisia" },
        { id: "TO", de: "Tonga", en: "Tonga", fr: "Tonga", es: "Tonga" },
        { id: "TR", de: "Türkei", en: "Turkey", fr: "Turkey", es: "Turkey" },
        { id: "TT", de: "Trinidad und Tobago", en: "Trinidad and Tobago", fr: "Trinidad and Tobago", es: "Trinidad and Tobago" },
        { id: "TV", de: "Tuvalu", en: "Tuvalu", fr: "Tuvalu", es: "Tuvalu" },
        { id: "TW", de: "Taiwan", en: "Taiwan", fr: "Taiwan", es: "Taiwan" },
        { id: "TZ", de: "Tansania", en: "Tanzania", fr: "Tanzania", es: "Tanzania" },
        { id: "UA", de: "Ukraine", en: "Ukraine", fr: "Ukraine", es: "Ukraine" },
        { id: "UG", de: "Uganda", en: "Uganda", fr: "Uganda", es: "Uganda" },
        { id: "UM", de: "United States minor outlying islands", en: "United States minor outlying islands", fr: "United States minor outlying islands", es: "United States minor outlying islands" },
        { id: "US", de: "Vereinigte Staaten von Amerika", en: "United States of America", fr: "United States of America", es: "United States of America" },
        { id: "UY", de: "Uruguay", en: "Uruguay", fr: "Uruguay", es: "Uruguay" },
        { id: "UZ", de: "Usbekistan", en: "Uzbekistan", fr: "Uzbekistan", es: "Uzbekistan" },
        { id: "VA", de: "Staat Vatikanstadt", en: "Holy See (Vatican City State)", fr: "Holy See (Vatican City State)", es: "Holy See (Vatican City State)" },
        { id: "VC", de: "St. Vincent und die Grenadinen", en: "Saint Vincent and the Grenadines", fr: "Saint Vincent and the Grenadines", es: "Saint Vincent and the Grenadines" },
        { id: "VE", de: "Venezuela", en: "Venezuela", fr: "Venezuela", es: "Venezuela" },
        { id: "VG", de: "Jungferninseln", en: "Virgin Islands", fr: "Virgin Islands", es: "Virgin Islands" },
        { id: "VI", de: "Britische Jungferninseln", en: "British Virgin Islands", fr: "British Virgin Islands", es: "British Virgin Islands" },
        { id: "VN", de: "Vietnam", en: "Vietnam", fr: "Vietnam", es: "Vietnam" },
        { id: "VU", de: "Republik Vanuatu", en: "Republic of Vanuatu", fr: "Republic of Vanuatu", es: "Republic of Vanuatu" },
        { id: "WF", de: "Wallis und Futuna", en: "Wallis and Futuna", fr: "Wallis and Futuna", es: "Wallis and Futuna" },
        { id: "WS", de: "Unabhängiger Staat Samoa", en: "Independent State of Samoa", fr: "Independent State of Samoa", es: "Independent State of Samoa" },
        { id: "YE", de: "Jemen", en: "Yemen", fr: "Yemen", es: "Yemen" },
        { id: "YT", de: "Mayotte", en: "Mayotte", fr: "Mayotte", es: "Mayotte" },
        { id: "ZA", de: "Südafrika", en: "South Africa", fr: "South Africa", es: "South Africa" },
        { id: "ZM", de: "Republik Sambia", en: "Republic of Zambia", fr: "Republic of Zambia", es: "Republic of Zambia" },
        { id: "ZW", de: "Simbabwe", en: "Zimbabwe", fr: "Zimbabwe", es: "Zimbabwe" }
    ];
}

export function isValidUrl(urlString) {
    try {
        return Boolean(new URL(urlString));
    }
    catch (e) {
        return false;
    }
}