export class VotingTranslation {
    id: number;
    locale: string;
    title: string;
    question: string;
    description: string;

    /**
    * constructor
    *
    * @param {VotingTranslation} init
    */
    public constructor(init?: VotingTranslation) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
