<div class="modal-header">
    <ion-grid class="ion-no-padding">
        <ion-row class="ion-no-padding">
            <ion-col size="9"
                     class="ion-no-padding">
                {{ 'LIVE_VOTING_HEADER' | translate }}
            </ion-col>
            <ion-col size="3"
                     class="ion-no-padding ion-text-right">
                <a (click)="showPostpone ? postpone() : close()">
                    <svg-icon [applyClass]="true"
                              src="/assets/icons/ico-close.svg"
                              class="icon fill-dark">
                    </svg-icon>
                </a>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>

<ng-container *ngIf="!loading && event">
    <ion-row *ngIf="plt.sizeLg">
        <ion-col size="12"
                 class="ion-text-right">
            <ion-item class="event-info">
                <img class="logo"
                     width="48"
                     height="48"
                     img-logo
                     [src]="event.logo_url">
                <ion-label>
                    <strong class="event-name">{{ event.translate(userLang).name }}</strong><br />
                    <ng-container *ngIf="!event.is_community">
                        <ng-container *ngIf="event.date_starts_at.toDateString() != event.date_ends_at.toDateString()">
                            {{ event.date_starts_at | datex:'dateFormatShort' }} -
                        </ng-container>
                        {{ event.date_ends_at | datex }}
                    </ng-container>
                </ion-label>
            </ion-item>
        </ion-col>
    </ion-row>
    <div class="ion-padding"
         [ngClass]="{'content-centered-column': true}">
        <div [ngClass]="{'content-centered-row': voting.type != 'freetext'}">
            <div class="voting-form"
                 [ngClass]="{'two-column-image': twoColumnImage}">
                <ng-container *ngIf="voting && voting.votes.length == 0">
                    <h2>{{ voting.question }}</h2>
                    <ng-container *ngIf="twoColumnImage && !plt.sizeSm">
                        <p [innerHTML]="voting.description | striphtml">
                        </p>
                    </ng-container>
                    <ion-row class="ion-no-padding">
                        <ion-col [size]="plt.sizeSm || !twoColumnImage ? 12 : 6"
                                 class="question-detail"
                                 *ngIf="voting.description">
                            <div *ngIf="showMore"
                                 class="description"
                                 [innerHTML]="voting.description | linkify | securedImages | safe:'html'">
                            </div>
                            <div *ngIf="!showMore"
                                 class="description"
                                 [innerHTML]="voting.description | striphtml | cut:250">
                            </div>
                            <a *ngIf="!showMore && voting.description.length > 250"
                               class="pointer"
                               (click)="showMore = true">
                                {{ 'EXTERNAL_HTML_SHOW_MORE' | translate }}
                            </a>
                        </ion-col>
                        <ion-col [size]="plt.sizeSm || !twoColumnImage ? 12 : 6">
                            <ng-container [ngSwitch]="voting.type">
                                <ng-container *ngIf="twoColumnImage && plt.sizeSm">
                                    <p [innerHTML]="voting.description | striphtml">
                                    </p>
                                </ng-container>
                                <ng-container *ngSwitchCase="'answers'">
                                    <ng-container *ngFor="let answer of voting.answers">
                                        <ion-button *ngIf="!selectedAnswer"
                                                    (click)="selectAnswer(answer)">
                                            {{ answer.translate(userLang, event.default_locale).answer }}
                                        </ion-button>
                                        <ng-container *ngIf="answer.selected">
                                            <ion-label class="hint">{{ answer.translate(userLang, event.default_locale).free_text_answer_hint }}</ion-label>
                                            <div class="textarea"
                                                 [ngClass]="{ 'has-error': validationErrors['answers.0.free_text_value'] }">
                                                <ion-textarea [(ngModel)]="answer.free_text_value">
                                                </ion-textarea>

                                                <span class="error-message"
                                                      *ngIf="validationErrors['answers.0.free_text_value']">
                                                    {{ validationErrors['answers.0.free_text_value'] }}
                                                </span>

                                                <ion-row class="ion-no-padding">
                                                    <ion-col>
                                                        <ion-button class="btn-close"
                                                                    (click)="unselectAnswer(answer)">
                                                            {{ 'CANCEL' | translate }}
                                                        </ion-button>
                                                    </ion-col>
                                                    <ion-col>
                                                        <ion-button (click)="saveAnswer(answer)">
                                                            {{ 'SUBMIT' | translate }}
                                                        </ion-button>
                                                    </ion-col>
                                                </ion-row>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="'mood'">
                                    <div class="mood">
                                        <img *ngFor="let answer of voting.answers; let i = index"
                                             [src]="'/assets/icons/smiley-' + (i+1) + '.svg'"
                                             width="60"
                                             alt="happy"
                                             (click)="saveAnswer(answer)">
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'freetext'">
                                    <ion-row class="ion-no-padding">
                                        <ion-col size="12">
                                            <div class="textarea"
                                                 [ngClass]="{ 'has-error': validationErrors['answers.0.free_text_value'] }">
                                                <ion-textarea [(ngModel)]="voting.answers[0].free_text_value"
                                                              required>
                                                </ion-textarea>
                                                <span class="error-message"
                                                      *ngIf="validationErrors['answers.0.free_text_value']">
                                                    {{ validationErrors['answers.0.free_text_value'] | translate: {count: voting.selectable_count} }}
                                                </span>
                                            </div>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row class="ion-no-padding">
                                        <ion-col size="12">
                                            <ion-button class="checkbox-submit"
                                                        (click)="saveAnswer(voting.answers[0])">
                                                {{ 'LIVE_VOTING_SUBMIT_ANSWER' | translate }}
                                            </ion-button>
                                        </ion-col>
                                    </ion-row>
                                </ng-container>
                                <ng-container *ngSwitchCase="'checkboxes'">
                                    <div class="checkboxes"
                                         [ngClass]="{ 'has-error': validationErrors['answers'] }">
                                        <p *ngIf="voting.selectable_count > 1">{{ 'LIVE_VOTING_CHECKBOXES_SELECTABLE_COUNT' | translate:{count:voting.selectable_count} }}</p>
                                        <p *ngIf="voting.selectable_count == 1">{{ 'LIVE_VOTING_CHECKBOXES_SELECTABLE' | translate }}</p>

                                        <ng-container *ngFor="let answer of voting.answers; let i = index">
                                            <label>
                                                <input [(ngModel)]="answer.selected"
                                                       type="checkbox"> {{ answer.translate(userLang, event.default_locale).answer }}
                                            </label>

                                            <ng-container *ngIf="answer.free_text && answer.selected">
                                                <ion-label class="hint">{{ answer.translate(userLang, event.default_locale).free_text_answer_hint }}</ion-label>
                                                <div class="textarea"
                                                     [ngClass]="{ 'has-error': validationErrors['answers.' + i + '.free_text_value'] }">
                                                    <ion-textarea [(ngModel)]="answer.free_text_value">
                                                    </ion-textarea>
                                                    <span class="error-message"
                                                          *ngIf="validationErrors['answers.' + i + '.free_text_value']">
                                                        {{ validationErrors['answers.' + i + '.free_text_value'] }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </ng-container>

                                        <span class="error-message"
                                              *ngIf="validationErrors['answers']">
                                            {{ validationErrors['answers'] | translate: {count: voting.selectable_count} }}
                                        </span>
                                    </div>
                                    <ion-button class="checkbox-submit"
                                                (click)="saveMultiAnswer()">
                                        {{ 'LIVE_VOTING_SUBMIT_ANSWER' | translate }}
                                    </ion-button>
                                </ng-container>
                            </ng-container>
                        </ion-col>
                    </ion-row>

                </ng-container>

                <ng-container *ngIf="voting && voting.votes.length > 0">
                    <h3 [innerHTML]=" 'LIVE_VOTING_SUBMITED' | translate "></h3>
                </ng-container>

                <ng-container *ngIf="!voting?.id">
                    <h3 [innerHTML]=" 'LIVE_VOTING_NO_LIVE' | translate "></h3>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="content-centered-column">
        <div class="content-centered-row">
            <ion-spinner></ion-spinner>
        </div>
    </div>
</ng-container>