import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { MatomoRouterInterceptor, MatomoRouteInterceptorBase, MatomoTracker } from 'ngx-matomo-client';

import { RouterExtService } from "./router-ext.service";
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from './platform.service';
import { environment } from 'src/environments/environment';
import { log } from '../helpers/log';

/** An interceptor only needs to implement `MatomoRouterInterceptor` */
@Injectable({
    providedIn: 'root'
})
export class BeforeTrackingInterceptor implements MatomoRouterInterceptor {
    private readonly tracker = inject(MatomoTracker);
    private readonly routerService = inject(RouterExtService);
    private readonly plt = inject(PlatformService);

    beforePageTrack(event: NavigationEnd): void {

        let url = event.url.replace('/tabs', '');

        log('info', 'Tracked URL', url);

        // get path for loading proper tracking page title
        let path = url.split('?')[0];
        if (this.routerService.trackingPageTitles.hasOwnProperty(path)) {
            log('info', 'Tracked title', this.routerService.trackingPageTitles[path]);
            this.tracker.setDocumentTitle(this.routerService.trackingPageTitles[path]);
        }

        // for cordova app replace ionic and localhost with assets domain
        // that is connected with branded app version...
        if (this.plt.is('cordova')) {
            url = environment.assets + url; //.replace(/ionic\:\/\//g, ''); - url is just path, not origin
        } else {
            url = location.origin + url; this.routerService.getPreviousUrl()
        }

        // remove private data
        url = url.replace(/(&|)(email|token|branch_id|_branch_match_id|_branch_referrer|forceLocale|ngsw-bypass)\=[^&]*/g,'');

        this.tracker.setCustomUrl(url);

        // remove tabs prefix from referrer URL
        if (this.routerService.getPreviousUrl()) {
            this.tracker.setReferrerUrl(this.routerService.getPreviousUrl().replace('/tabs', ''));
        }

        // not working... as this is async..
        // this.tracker.getAttributionReferrerUrl().then((ref) => {
        //     log('info', 'Tracked REF', ref);
        //     if (ref) {
        //         this.tracker.setReferrerUrl(ref.replace('/tabs', ''));
        //     }
        // })
    }
}

/**
 * It is also possible to extend `MatomoRouteInterceptorBase` for easy access
 * to activated route snapshot
 */
@Injectable({
    providedIn: 'root'
})
export class MyAsyncTrackingInterceptor extends MatomoRouteInterceptorBase<string> {
    private readonly tracker = inject(MatomoTracker);
    private readonly translate = inject(TranslateService);

    protected extractRouteData(route: ActivatedRouteSnapshot): string {
        return '';
    }

    // protected async processRouteData(productId: string): Promise<void> {
    //     // const product = await this.loadProductData(productId);

    //     // this.tracker.setEcommerceView(productId, product.name);
    // }

    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    loadTranslations() {
        return new Promise(resolve => {
            this.translate.getTranslation(this.translate.currentLang).subscribe(() => {
                resolve;
            });
        });

    }

    protected async processRouteData(): Promise<void> {
        // await this.timeout(4000);
        // await this.loadTranslations();
    }
}