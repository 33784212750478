import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Events } from '../../helpers/events';

// ionic
import { ModalController } from '@ionic/angular';

import { Helper } from "../../services/helper";

// other libraries
import { TranslateService } from "@ngx-translate/core";

// rxjs
import { Subject } from "rxjs";

// providers
import { MarketplaceService } from "../../services/marketplace.service";
import { OverlayService } from "../../services/overlay.service";
import { PlatformService } from "../../services/platform.service";

// models
import { Marketplace } from "../../models/marketplace";
import { MarketplacePost } from "../../models/marketplace-post";
import { User } from "../../models/user";

@Component({
    selector: 'app-marketplace-purchase',
    templateUrl: './marketplace-purchase.component.html',
    styleUrls: ['./marketplace-purchase.component.scss'],
})
export class MarketplacePurchaseComponent implements OnInit, OnDestroy {

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * current user
     *
     * @type {User}
     */
    @Input() user: User;

    /**
     * show form in modal
     *
     * @type boolean
     */
    @Input() modal: boolean = false;

    /**
     * marketplacePost
     *
     * @type {MarketplacePost}
     */
    @Input() marketplacePost: MarketplacePost = new MarketplacePost;

    /**
     * marketplace
     *
     * @type {Marketplace}
     */
    @Input() marketplace: Marketplace;

    @Output() onMarketplaceSended: EventEmitter<MarketplacePost> = new EventEmitter();
    @Output() onCanceled: EventEmitter<MarketplacePost> = new EventEmitter();

    /**
     * validate state
     *
     * @type {boolean}
     */
    public validate: boolean = false;

    /**
     * Validation errors
     *
     * @type {object}
     */
    public validationErrors: object = {};

    /**
     * validation errors state
     *
     * @type {boolean}
     */
    public hasValidationErrors: boolean = false;

    // handlers for events
    eventChangeHandler;

    /**
     * default locale
     *
     * @type string
     */
    @Input() defaultLocale: string = 'de';

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;

    /**
      * submit state
      *
      * @type {boolean}
      */
    public submit: boolean = false;

    /**
      * amount of items for order
      *
      * @type {boolean}
      */
    public amount: number = 1;

    /**
      * delivery address
      *
      * @type {boolean}
      */
    public address: {
        street: string,
        streetnr: string,
        zip: string,
        city: string,
        country: string,
        firstname: string,
        lastname: string,
        company: string,
        department: string
    } = {
        street: '',
        streetnr: '',
        zip: '',
        city: '',
        country: '',
        firstname: '',
        lastname: '',
        company: '',
        department: ''
    };

    /**
     * constructor
     *
     * @param TranslateService
     * @param Events
     * @param PlatformService
     * @param OverlayService
     * @param MarketplaceService
     * @param ModalController
     */
    constructor(
        public translate: TranslateService,
        public appEvents: Events,
        public plt: PlatformService,
        public overlayService: OverlayService,
        public marketplaceService: MarketplaceService,
        public modalController: ModalController
    ) {
        // get user lang
        this.userLang = this.translate.currentLang;
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() {

        this.eventChangeHandler = () => {
            // NOTE[jg] - cancel?
        }

        this.address.firstname = this.user.selected_participant.firstname;
        this.address.lastname = this.user.selected_participant.lastname;
        this.address.company = this.user.selected_participant.company;

        // attach hooks on event change
        this.appEvents.subscribe('event:changed', this.eventChangeHandler);
    }

    /**
     * send new marketplacePost
     */
    public marketplacePostSave(valid) {

        if (valid) {
            this.submit = true;
            this.validationErrors = {};
            this.validate = true;
            this.marketplacePost.marketplace_id = this.marketplace.id;

            this.marketplaceService.purchase(this.marketplacePost, this.amount, this.address).subscribe(
                (success: { current_coins: number }) => {

                    // update coins for current user
                    this.user.selected_participant.current_coins = success.current_coins;

                    this.handleSuccess(success);
                },
                (error) => {
                    this.handleError(error);
                }
            );
        }
    }

    private handleSuccess(response) {
        this.overlayService.showSuccess(this.translate.instant('MARKETPLACE_POST_COIN_PRICE_BUY_THANK_YOU_TITLE'), true);
        this.marketplacePost = response.post ? new MarketplacePost(response.post, this.userLang) : new MarketplacePost;
        this.onMarketplaceSended.emit(this.marketplacePost);
        this.validate = false;

        if (this.modal) {
            // close modal if used..
            this.closeSaved();
        }
        this.submit = false;
    }

    private handleError(response) {
        const data = response.error;

        if (data.fields) {
            for (const field in data.fields) {
                if (data.fields.hasOwnProperty(field)) {
                    this.hasValidationErrors = true;
                    this.validationErrors[field] = data.fields[field].join(' ');
                }
            }
        }

        this.submit = false;
        this.overlayService.showError(data.message);
    }

    public hideKeyboard($event) {
        // $event.stopPropagation()
        if ($event.preventDefault) {
            $event.preventDefault();
        }

        setTimeout(() => {
            if ($event.srcElement) {
                $event.srcElement.blur();
            }
        }, 100);
    }

    /**
     * close modal window
     *
     * @param boolean modal
     *
     */
    public backClicked(modal = true) {
        if (modal) {
            this.modalController.dismiss({ action: 'close' });
        } else {
            this.onCanceled.emit();
        }
    }

    /**
     * close modal window
     *
     */
    public closeSaved() {
        this.modalController.dismiss({ action: 'close', marketplacePost: this.marketplacePost });
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        if (this.eventChangeHandler) {
            this.appEvents.unsubscribe('event:changed', this.eventChangeHandler);
        }
        this.overlayService.closeModals();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
