export class VotingAnswerTranslation {
    id: number;
    locale: string;
    answer: string;
    free_text_answer_hint: string;

    /**
     * constructor
     *
     * @param {VotingAnswerTranslation} init
     */
    public constructor(init?: VotingAnswerTranslation) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
