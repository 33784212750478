export class MarketplaceKeywordTranslation {
    id: number;
    locale: string;
    name: string;

    /**
     * constructor
     *
     * @param {MarketplaceKeywordTranslation} init
     */
    public constructor(init?: MarketplaceKeywordTranslation) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
