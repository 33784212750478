// custom-translate.pipe.ts
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { getCountries } from "../../services/helper";

/**
 *
 */
@Pipe({ name: 'countryName' })
export class CountryNamePipe implements PipeTransform {

    constructor(
        public translate: TranslateService,
    ) { }

    /**
     *
     * @param value
     * @returns {string}
     */
    transform(value: string): string {

        if (value) {
            let country = getCountries().find(c => c.id == value);
            if (country) {
                return country[this.translate.currentLang];
            }
        }

        return value;
    }
}
