<ion-header *ngIf="!hideHeader"
            class="ion-no-shadow ion-no-border"
            [ngClass]="{'ion-hide-xl-up':!showImage}">
    <ion-toolbar>
        <ng-container *ngIf="showImage">
            <a *ngIf="showImage"
               (click)="showImage = false"
               class="back-link pointer">
                <svg-icon [applyClass]="true"
                          src="/assets/icons/ico-arrow.svg"></svg-icon>

            </a>
            <ion-title class="full">
                <ng-container *ngIf="user.selected_participant?.checkProfileRule(attendee, 'title', 'visible')">{{ attendee.title }}</ng-container> {{ attendee.firstname }} {{ attendee.lastname }}
            </ion-title>
        </ng-container>
        <ng-container *ngIf="!showImage">
            <ion-title *ngIf="detail">
                {{ detail?.options?.name }}
            </ion-title>
            <ion-title *ngIf="(!plt.sizeSm || !appointmentRequest) && !detail"
                       [innerHTML]="'ATTENDEE_DETAIL_HEADER' | trns">
            </ion-title>
            <ion-title *ngIf="plt.sizeSm && appointmentRequest"
                       [innerHTML]="'APPOINTMENT_REQUEST' | trns">
            </ion-title>
            <ion-buttons slot="end">
                <ion-button (click)="backClicked()">
                    <ion-icon slot="icon-only"
                              color="dark"
                              name="close">
                    </ion-icon>
                </ion-button>
            </ion-buttons>
        </ng-container>
    </ion-toolbar>
</ion-header>

<div *ngIf="!loading && attendee"
     class="attendee-card {{class}}"
     [ngClass]="{'preview': hideHeader}">
    <ng-container *ngIf="showImage">
        <div class="content-centered-column">
            <div class="content-centered-row">
                <img class="attendee-picture"
                     [ngClass]="{'small-preview': smallPreview}"
                     [src]="plt.createSignedLink(attendee.picture_url)"
                     width="500"
                     alt="{{ attendee.title }} {{ attendee.firstname }}">
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!showImage">
        <div *ngIf="!(!plt.sizeSm && smallPreview && appointmentRequest) && (!plt.sizeSm || !appointmentRequest) && (!detail || !plt.sizeSm)"
             class="attendee-box">
            <ion-row class="header ion-no-padding">
                <ion-col size="7"
                         class="matching ion-no-padding">
                    <ng-container *ngIf="user.canMatching(attendee) && !isPreview">
                        <ion-badge>{{ attendee.matching_factor | round }}%</ion-badge>
                        {{ 'ATTENDEE_DETAIL_MATCHING' | trns }}
                    </ng-container>
                    <ng-container
                                  *ngIf="(user?.selected_participant?.event?.is_homepage_content_newsfeed && user?.canNewsfeed(attendee)) || user?.selected_participant?.event?.marketplaces.length > 0">
                        <div class="action-buttons-top"
                             *ngIf="!user.canMatching(attendee) && !isPreview && user.selected_participant && user.selected_participant.id != attendee.id">
                            <a class="action-button more no-position"
                               (click)="showActionMenu($event)">
                                <span class="dot"></span>
                                <span class="dot"></span>
                                <span class="dot"></span>
                            </a>
                        </div>
                    </ng-container>
                </ion-col>
                <ion-col *ngIf="user.selected_participant && user.selected_participant.id != attendee.id && user.canSee(attendee)"
                         class="pointer bookmark ion-text-right ion-no-padding"
                         [ngClass]="{'active': attendee.bookmarked}"
                         (click)="toggleBookmark(attendee)">
                    <ng-container *ngIf="!bookmarkLoading">{{ 'ATTENDEE_DETAIL_BOOKMARK' | trns }}</ng-container>
                    <ion-spinner *ngIf="bookmarkLoading"
                                 name="dots"></ion-spinner>
                    <svg-icon [applyClass]="true"
                              src="/assets/icons/ico-bookmark.svg"
                              class="pointer"
                              [svgStyle]="{ 'width.px':32 }"></svg-icon>
                </ion-col>
                <ion-col *ngIf="user.selected_participant && user.selected_participant.id == attendee.id"
                         class="bookmark ion-text-right ion-no-padding">
                    {{ 'ATTENDEE_DETAIL_BOOKMARK' | trns }}
                    <svg-icon [applyClass]="true"
                              src="/assets/icons/ico-bookmark.svg"
                              [svgStyle]="{ 'width.px':32 }">
                    </svg-icon>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="(user?.selected_participant?.event?.is_homepage_content_newsfeed && user?.canNewsfeed(attendee)) || user?.selected_participant?.event?.marketplaces.length > 0">
                <ion-col>
                    <div class="action-buttons-top"
                         *ngIf="user.canMatching(attendee) && !isPreview && user.selected_participant && user.selected_participant.id != attendee.id">
                        <a class="action-button more"
                           (click)="showActionMenu($event)">
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                        </a>
                    </div>
                </ion-col>
            </ion-row>
            <div class="picture">
                <percentage-circle class="percentage-circle"
                                   [radius]="94"
                                   [ngClass]="{'pointer':attendee.picture_url}"
                                   (click)="attendee.picture_url ? showImage = true : showImage = false"
                                   [matching]="user?.canMatching(attendee)"
                                   [picture]="user?.canPicture(attendee)"
                                   [attendee]="attendee">
                </percentage-circle>
            </div>
            <div *ngIf="group && group.show_label && group.label.length"
                 class="group-label-wrapper">
                <ion-badge class="group-label">
                    {{ group.label | customTranslate}}
                </ion-badge>
            </div>
            <h3>
                <ng-container *ngIf="false && user.selected_participant?.checkProfileRule(attendee, 'title', 'visible')">
                    {{ attendee.title }}
                </ng-container>
                {{ attendee.firstname }} {{ attendee.lastname }}
            </h3>
            <p class="details">
                <ng-container *ngIf="attendee.profession && user.selected_participant?.checkProfileRule(attendee, 'profession', 'visible')">{{ attendee.profession | cut: 40:'...':false
                    }}<br /></ng-container>
                <ng-container *ngIf="attendee.company && user.selected_participant?.checkProfileRule(attendee, 'company', 'visible')">{{ attendee.company }}<br /></ng-container>
                <ng-container *ngIf="attendee.street && user.selected_participant?.checkProfileRule(attendee, 'street', 'visible')">{{ attendee.street }} {{ attendee.streetnr }}<br /></ng-container>
                <ng-container *ngIf="attendee.city && user.selected_participant?.checkProfileRule(attendee, 'city', 'visible')">{{ attendee.zip }} {{ attendee.city }}<br /></ng-container>
                <ng-container *ngIf="attendee.country && user.selected_participant?.checkProfileRule(attendee, 'country', 'visible')">{{ attendee.country | countryName }}</ng-container>

                <ng-container *ngIf="env.name == 'staging' && attendee.lat && user.selected_participant?.checkProfileRule(attendee, 'city', 'visible')"><br />GPS: {{ attendee.lat }},{{ attendee.lng
                    }}</ng-container>
            </p>
            <div class="action-buttons">
                <!-- [tooltip]="!canMessage(participant) || user.selected_participant?.not_contactable ? ('EVENT_PARTICIPANT_NOT_CONTACTABLE_SET' | trns) : null"> -->
                <ion-button *ngIf="!!attendee.registered_at && user.canSee(attendee)"
                            (click)="toggleLike(attendee)"
                            color="white"
                            class="full"
                            [disabled]="user.selected_participant && user.selected_participant.id == attendee.id">
                    <svg-icon slot="start"
                              [applyClass]="true"
                              src="/assets/icons/ico-like.svg"
                              [ngClass]="{'active': attendee.liked, 'disabled': !user.canMessage(attendee) || user.selected_participant?.not_contactable || user.selected_participant?.id == attendee.id}"
                              class="like">
                    </svg-icon> {{ 'ATTENDEE_DETAIL_LIKE' | trns }}
                    <ion-spinner *ngIf="likeLoading"
                                 name="dots">
                    </ion-spinner>
                </ion-button>
                <ion-row *ngIf="!smallPreview || event?.can(user.selected_participant.group_id, 'allow_call')"
                         class="ion-no-padding messaging">
                    <ion-coll *ngIf="!smallPreview"
                              class="ion-no-padding">
                        <ion-button (click)="goToMessages()"
                                    color="white"
                                    class="full"
                                    [disabled]="!user.canMessage(attendee) || user.selected_participant?.not_contactable || user.selected_participant?.id == attendee.id">
                            <svg-icon slot="start"
                                      [applyClass]="true"
                                      src="/assets/icons/ico-message.svg">
                            </svg-icon> {{ 'ATTENDEE_DETAIL_MESSAGE' | trns }}
                        </ion-button>
                    </ion-coll>
                    <ion-coll *ngIf="!smallPreview && user?.canVideoCall(attendee)">
                    </ion-coll>
                    <ion-coll *ngIf="user?.canVideoCall(attendee)"
                              class="ion-no-padding">
                        <ion-button (click)="openCall()"
                                    color="white"
                                    class="full"
                                    [disabled]="user.selected_participant?.id == attendee.id">
                            <ion-icon slot="start"
                                      name="videocam-outline"
                                      color="light">
                            </ion-icon>{{ 'ATTENDEE_DETAIL_CALL' | trns }}
                        </ion-button>
                    </ion-coll>
                </ion-row>
                <ion-button *ngIf="!event.disable_appointments"
                            color="white"
                            class="full"
                            (click)="setAppointmentRequest()"
                            [disabled]="!user.canMessage(attendee, true) || user.selected_participant?.not_contactable || user.selected_participant?.id == attendee.id">
                    <svg-icon slot="start"
                              [applyClass]="true"
                              src="/assets/icons/ico-calendar-check.svg">
                    </svg-icon> {{ 'ATTENDEE_DETAIL_SET_APPOINTMENT' | trns }}
                </ion-button>
            </div>
            <div class="contact-buttons">
                <ng-container *ngIf="user.selected_participant?.id != attendee.id && user.selected_participant?.checkProfileRule(attendee, 'public_email', 'visible')">
                    <a *ngIf="(user.canMessage(attendee) || attendee.id == user.selected_participant_id) && attendee.public_email && !user.selected_participant?.not_contactable"
                       [attr.href]="!(user.canMessage(attendee) || attendee.id == user.selected_participant_id) ? null : 'mailto:' + attendee.public_email">
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/ico-mail.svg">
                        </svg-icon>
                    </a>
                </ng-container>
                <ng-container *ngIf="user.selected_participant?.id != attendee.id && user.selected_participant?.checkProfileRule(attendee, 'phone', 'visible')">
                    <a *ngIf="(user.canMessage(attendee) || attendee.id == user.selected_participant_id) && attendee.phone && !user.selected_participant?.not_contactable"
                       [attr.href]="!(user.canMessage(attendee) || attendee.id == user.selected_participant_id) ? null : 'tel:' + attendee.phone">
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/ico-phone.svg">
                        </svg-icon>
                    </a>
                </ng-container>
                <a *ngIf="user.selected_participant?.id != attendee.id && attendee.linkedin_profile"
                   onclick="window.open(this.href, '_system');return false;"
                   [attr.href]="attendee.linkedin_profile">
                    <svg-icon [applyClass]="true"
                              src="/assets/icons/ico-linkedin.svg">
                    </svg-icon>
                </a>
                <a *ngIf="user.selected_participant?.id != attendee.id && attendee.xing_profile"
                   onclick="window.open(this.href, '_system');return false;"
                   [attr.href]="attendee.xing_profile">
                    <svg-icon [applyClass]="true"
                              src="/assets/icons/ico-xing.svg">
                    </svg-icon>
                </a>
            </div>
        </div>

        <div *ngIf="!smallPreview || appointmentRequest"
             class="attendee-detail"
             [ngClass]="{'appointment-request': appointmentRequest, 'small-preview': smallPreview}">

            <ng-container *ngIf="detail">
                <ng-container [ngSwitch]="true">

                    <ng-container *ngSwitchCase="detail.type == 'modal-window'">
                        <div class="gray-box-details">
                            <h3 *ngIf="!plt.sizeSm">{{ detail?.options?.name }}</h3>
                            <br *ngIf="!plt.sizeSm" />
                            <div [ngClass]="{'white-box-details': !plt.sizeSm}"
                                 [innerHTML]="detail.value | nl2br">
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!detail">

                <ng-container *ngIf="appointmentRequest">

                    <appointment-request (onAppointmentRequest)="closeAppointmentRequest(conversation)"
                                         [attendee]="attendee"
                                         [class]="class"
                                         [smallPreview]="smallPreview"></appointment-request>
                </ng-container>
                <ng-container *ngIf="!appointmentRequest">

                    <ion-row class="group header">
                        <ion-col class="header">
                            <h3 *ngIf="(user.canMatching(attendee) && attendee?.keywords?.length > 0) || attendee?.groupedDetails?.length > 0">
                                {{ 'ATTENDEE_DETAIL_ABOUT' | trns }}
                                <ng-container *ngIf="user.selected_participant?.checkProfileRule(attendee, 'title', 'visible')">{{ attendee.title }}</ng-container> {{ attendee.firstname }} {{
                                attendee.lastname }}
                            </h3>
                        </ion-col>
                    </ion-row>
                    <!-- <p *ngIf="attendee.description" [innerHtml]="attendee.description"></p> -->

                    <ng-container *ngFor="let group of attendee.groupedDetails; let groupIndex = index"
                                  class="ion-no-padding">
                        <ng-container *ngIf="group?.visible && !group.menu && user.selected_participant?.checkProfileRule(attendee, group.type, 'visible')">
                            <ion-row [class]="'ion-no-padding group ' + group.type">

                                <ng-container *ngFor="let detail of group.details; let detailIndex = index">
                                    <ion-col class="between ion-text-center"
                                             *ngIf="detail.type == 'to'">
                                        <span>-</span>
                                    </ion-col>
                                    <ion-col *ngIf="detail.value"
                                             [class]="detail.type">
                                        <ng-container [ngSwitch]="true">

                                            <ng-container *ngSwitchCase="detail.type == 'image'">
                                                <ng-container *ngIf="detail.options?.type != 'pdf'">
                                                    <img [src]="plt.createSignedLink(detail.value)">
                                                    <ng-container *ngIf="detail?.options?.name">{{ detail?.options?.name }}</ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="detail.options?.type == 'pdf'">
                                                    <a [href]="plt.createSignedLink(detail.value)"
                                                       onclick="window.open(this.href, '_system');return false;">
                                                        <svg-icon [applyClass]="true"
                                                                  [src]="'/assets/icons/profile/pdf.svg'">
                                                        </svg-icon>
                                                        <ng-container *ngIf="detail?.options?.name">{{ detail?.options?.name }}</ng-container>
                                                    </a>
                                                </ng-container>
                                            </ng-container>

                                            <ng-container *ngSwitchCase="detail.type == 'from' || detail.type == 'to'">
                                                <span>{{ detail.value | datex:'MM/YYYY'}}</span>
                                            </ng-container>

                                            <ng-container *ngSwitchCase="detail.type == 'paragraph'">
                                                <p [innerHtml]="detail.value | linkify | nl2br | safe:'html'"></p>
                                            </ng-container>

                                            <ng-container *ngSwitchCase="detail.type == 'header'">
                                                <h3>{{ detail.value }}</h3>
                                            </ng-container>

                                            <ng-container *ngSwitchCase="detail.type == 'link'">
                                                <ng-container *ngIf="(detail.value != attendee.linkedin_profile && detail.value != attendee.xing_profile) || detail?.options?.name">
                                                    <a (click)="openLink(detail.value, detail.options?.linkInNewTab)">
                                                        <ng-container *ngIf="detail?.options?.name">{{ detail?.options?.name }}</ng-container>
                                                        <ng-container *ngIf="!detail?.options?.name">{{ detail.value }}</ng-container>
                                                    </a>
                                                </ng-container>
                                            </ng-container>

                                            <ng-container *ngSwitchCase="detail.type == 'select'">
                                                {{ detail.options.name | translate }}: <strong>{{ detail.value }}</strong>
                                            </ng-container>

                                            <ng-container *ngSwitchDefault>
                                                <span [innerHTML]="detail.value | nl2br"></span>
                                            </ng-container>

                                        </ng-container>

                                    </ion-col>
                                    <ion-col *ngIf="!detail.value && detail.type == 'to'"
                                             [class]="detail.type">
                                        {{ 'CV_TO_DEFAULT' | trns }}
                                    </ion-col>
                                </ng-container>

                            </ion-row>
                            <ng-container *ngIf="groupIndex < (attendee.groupedDetails.length - 1) && group.line">
                                <hr />
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="(user.canMatching(attendee) || wizard) && attendee.keywords?.length > 0">
                        <div class="white-box-details">
                            <!-- <h3>{{ 'ATTENDEE_DETAIL_MATCHING_LISTS' | trns }}</h3> -->

                            <ng-container *ngFor="let container of containers; let c = index">
                                <h4>{{ container?.translate(userLang, event.default_locale)?.name }}</h4>
                                <ion-row>
                                    <ng-container *ngFor="let column of (container.columns === 2 ? [1,2] : [1])">

                                        <ion-col size="12"
                                                 size-md="6"
                                                 *ngIf="(container.keywords | keywordEnabled: column:attendee.keywords:event.available_locales).length != 0 && container.hasTranslatedKeywordsInLocales(event.available_locales)">

                                            <ng-container *ngIf="container.columns === 2">
                                                <h5>{{ container.translate(userLang, event.default_locale)['column_' + column + '_name'] }}</h5>
                                            </ng-container>

                                            <div class="keyword-list">
                                                <ng-container *ngFor="let keyword of (container.keywords | keywordEnabled: column:attendee.keywords:event.available_locales)">
                                                    <ion-chip *ngIf="keyword.approved_at || !keyword.participant_id"
                                                              class="matching-list pointer"
                                                              [title]="'DASHBOARD_MANAGE_KEYWORDS' | trns"
                                                              (click)="manageKeywords()"
                                                              [ngClass]="{'match': isMatch(keyword, container, column), 'enabled': !isMatch(keyword, container, column)}">
                                                        <img img-avatar
                                                             [ngClass]="{'matched': isMatch(keyword, container, column)}"
                                                             [src]="attendee.picture_url">
                                                        <img *ngIf="isMatch(keyword, container, column)"
                                                             img-avatar
                                                             class="my-match"
                                                             [src]="user.selected_participant.picture_url">
                                                        <ion-label>{{ keyword?.translate(userLang, event.default_locale)?.name | cut: 80 }}</ion-label>
                                                    </ion-chip>

                                                </ng-container>
                                            </div>

                                            <!-- <p class="no-keywords"
                               *ngIf="(container.keywords | keywordEnabled: column:attendee.keywords:event.available_locales).length === 0">
                                {{ 'DASHBOARD_NO_SELECTED_KEYWORDS' | trns }}
                            </p> -->
                                        </ion-col>

                                    </ng-container>
                                </ion-row>
                            </ng-container>
                        </div>
                    </ng-container>


                    <ng-container *ngIf="unprocessedAppointments.length && user.selected_participant && user.selected_participant.id != attendee.id">
                        <br />
                        <h3>{{ 'ATTENDEE_APPOINTMENTS' | trns }}</h3>
                        <ion-grid class="appointments ion-no-padding">
                            <ion-row class="item"
                                     *ngFor="let appointment of unprocessedAppointments"
                                     [ngClass]="{'with-invited': appointment.participants.length >= 3}">
                                <ion-col size="1"
                                         class="ion-text-center">
                                    <svg-icon [applyClass]="true"
                                              src="/assets/icons/ico-calendar.svg"
                                              class="icon fill-light"></svg-icon>
                                </ion-col>
                                <ion-col align-self-start
                                         class="info"
                                         size="11"
                                         size-md="9">

                                    <ng-container *ngIf="appointment.participants.length < 3">
                                        <span *ngFor="let participant of appointment.participants"
                                              [hidden]="participant.id === user.selected_participant_id">
                                            <p>
                                                {{ 'APPOINTMENTS_INVITATION_TEXT' | trns }}

                                                {{ participant.firstname }} {{ participant.lastname }}

                                                <ng-container *ngIf="!appointment.date_ends_at">
                                                    <span [innerHTML]=" 'APPOINTMENTS_INVITATION_TEXT_2_PART' | trns:{
                                                    date: (appointment.date_starts_at | datex: 'dateFormatDefault'),
                                                    time: (appointment.date_starts_at | datex: 'timeFormatDefaultEnd'),
                                                    place: appointment.location} "></span>
                                                </ng-container>
                                                <ng-container *ngIf="appointment.date_ends_at">
                                                    <span [innerHTML]=" 'APPOINTMENTS_INVITATION_TEXT_2_PART_WITH_END' | trns:{
                                                    date: (appointment.date_starts_at | datex: 'dateFormatDefault'),
                                                    time: (appointment.date_starts_at | datex: 'timeFormatDefault'),
                                                    time_end: (appointment.date_ends_at | datex: 'timeFormatDefaultEnd'),
                                                    place: appointment.location} "></span>
                                                </ng-container>
                                            </p>
                                        </span>
                                    </ng-container>

                                    <ng-container *ngIf="appointment.participants.length >= 3">
                                        <p>
                                            {{ 'APPOINTMENTS_INVITATION_TEXT' | trns }}
                                            <span *ngFor="let participant of appointment.participants; let i = index"
                                                  (click)="openAppointmentParticipants($event, appointment)"
                                                  class="pointer">
                                                <ion-avatar *ngIf="i < 3">
                                                    <img class="avatar"
                                                         img-avatar
                                                         [src]="user?.canPicture(participant) ? participant.picture_url : ''"
                                                         title="{{ participant.firstname }} {{ participant.lastname }}"
                                                         alt="">
                                                </ion-avatar>
                                            </span>
                                        </p>

                                        <p>
                                            <ng-container *ngIf="!appointment.date_ends_at">
                                                <span [innerHTML]=" 'APPOINTMENTS_INVITATION_TEXT_2_PART' | trns:{
                                                date: (appointment.date_starts_at | datex: 'dateFormatDefault'),
                                                time: (appointment.date_starts_at | datex: 'timeFormatDefaultEnd'),
                                                place: appointment.location} "></span>
                                            </ng-container>
                                            <ng-container *ngIf="appointment.date_ends_at">
                                                <span [innerHTML]=" 'APPOINTMENTS_INVITATION_TEXT_2_PART_WITH_END' | trns:{
                                                date: (appointment.date_starts_at | datex: 'dateFormatDefault'),
                                                time: (appointment.date_starts_at | datex: 'timeFormatDefault'),
                                                time_end: (appointment.date_ends_at | datex: 'timeFormatDefaultEnd'),
                                                place: appointment.location} "></span>
                                            </ng-container>
                                        </p>
                                    </ng-container>

                                    <p class="description"
                                       *ngIf="appointment.description">
                                        “{{ appointment.description }}” | “
                                        <ng-container *ngIf="appointment.online && (appointment.participants?.length <= 2 || appointment.participantStatus === 1)">
                                            <a class="pointer"
                                               (click)="openCall(appointment)"> {{'ATTENDEE_DETAIL_CALL_START' | trns}}</a>
                                        </ng-container>
                                        <ng-container *ngIf="appointment.online && appointment.participants?.length > 2 && appointment.participantStatus !== 1">{{'ATTENDEE_DETAIL_CALL_START' |
                                            trns}}</ng-container>
                                        <ng-container *ngIf="!appointment.online">{{appointment.location}}</ng-container>
                                        ”
                                    </p>
                                </ion-col>
                                <ion-col size="12"
                                         size-md="2"
                                         class="buttons">
                                    <ion-button (click)="openAnswerAppointment(appointment, 2)"
                                                class="decline">
                                        {{ 'BUTTON_DECLINE' | trns }}
                                    </ion-button>
                                    <ion-button (click)="openAnswerAppointment(appointment, 1)"
                                                color="primary">
                                        {{ 'BUTTON_ACCEPT' | trns }}
                                    </ion-button>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </ng-container>

                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>

<a *ngIf="showImage && smallPreview"
   (click)="showImage = false"
   class="back-link pointer small-preview">
    <svg-icon [applyClass]="true"
              src="/assets/icons/ico-arrow.svg">
    </svg-icon>
    <ng-container *ngIf="user.selected_participant?.checkProfileRule(attendee, 'title', 'visible')">
        {{ attendee.title }}
    </ng-container>
    {{ attendee.firstname }} {{ attendee.lastname }}

</a>


<ng-container *ngIf="loading">
    <div class="content-centered-column"
         [ngClass]="{'small-preview': smallPreview}">
        <div class="content-centered-row">
            <ion-spinner></ion-spinner>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!loading && error">
    <div class="content-centered-column"
         [ngClass]="{'small-preview': smallPreview}">
        <div class="content-centered-row ion-padding">
            {{error}}
        </div>
    </div>
</ng-container>