export class ChatGroupTranslation {
    id: number;
    locale: string;
    name: string;
    description: string;

    /**
     * constructor
     *
     * @param {ChatGroupTranslation} init
     */
    public constructor(init?: ChatGroupTranslation) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
